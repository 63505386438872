import { Col } from 'antd';
import purple_necklace from 'images/purple_necklace.png';
import girl from 'images/46-3.jpg';
import { Img, Paragraph, Button } from 'styled';
import Wrapper from './style';
import { useRouterHistory } from 'hooks/routerHooks';
import { useTranslation } from 'react-i18next';

const MainBanner = () => {
  const history = useRouterHistory();
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Col xs={24}>
        <Img className='necklace-img -img' src={purple_necklace} />
        <div className='text-block'>
          <Paragraph $noMargin className='paragraph'>
            {' '}
            GAFRI <br /> JEWELLERY
          </Paragraph>
          <Button
            type='text'
            className='text-block-button'
            onClick={() => {
              window.localStorage.setItem('tag', JSON.stringify([]));
              history.push('/shop?page=1');
            }}
          >
            {' '}
            {t('goToCatalog').toUpperCase()}
            <span className='arrow'>&#8594;</span>{' '}
          </Button>
        </div>
        <Img className='girl-img' src={girl} />
      </Col>
    </Wrapper>
  );
};

export default MainBanner;
