import InfoSection from '../Homepage/InfoSection';
import NewCollection from '../Homepage/NewCollection';
import Collections from '../Homepage/Collections';
import { useEffect, useState } from 'react';
import SmallSection from '../Homepage/SmallSection';
import Categories from '../Homepage/Categories';
import Bestsellers from '../Homepage/Bestsellers';
import Sale from '../Homepage/Sale';
import MainBanner from './MainBanner';
import HomePageModalImg from 'images/home-page-modal.png';
import { GafriModal, Text, Img, Row, Col } from 'styled';
import { useRouterHistory } from 'hooks/routerHooks';
import { useTranslation } from 'react-i18next';

const HomePage = ({ reFetch }) => {
  const [visible, setVisible] = useState(
    window.sessionStorage.getItem('openModal')
  );

  useEffect(() => {
    reFetch();
  }, [reFetch]);

  const history = useRouterHistory();
  const { t } = useTranslation();
  return (
    <Row>
      <Col span={24}>
        <GafriModal
          className='home-page-modal'
          visible={visible === 'false' ? false : true}
          onOk={() => {
            window.sessionStorage.setItem('openModal', false);
            window.localStorage.setItem('tag', JSON.stringify([]));
            setVisible(window.sessionStorage.getItem('openModal'));
            history.push('/shop?page=1');
          }}
          width={450}
          closable={false}
          onCancel={() => {
            window.sessionStorage.setItem('openModal', false);
            setVisible(window.sessionStorage.getItem('openModal'));
          }}
          cancelText={t('ok').toUpperCase()}
          okText={<span>{t('shopNow').toUpperCase()}</span>}
          icon={<Img src={HomePageModalImg} className='home-page-modal-img' />}
          title={<Text className='home-page-modal-title'>{t('welcome')}</Text>}
          modalText={
            <Text level={1} className='home-page-modal-text'>
              {t('theJewellery')}
            </Text>
          }
        />
        <MainBanner />
        <NewCollection />
      </Col>
      <Col className='mobile-color'>
        <Categories />
        <Collections />
        <Bestsellers />
        <SmallSection />
        <Sale />
        <InfoSection />
      </Col>
    </Row>
  );
};

export default HomePage;
