import { Col, Card } from 'antd';
import { Row, Paragraph, Button, Img } from 'styled';
import styled from 'styled-components';
import { metalGet } from 'services/metal';
import { useGetRequest } from 'hooks/useGetRequest';
import { useRouterHistory } from 'hooks/routerHooks';
import { useTranslation } from 'react-i18next';

const Wrapper = styled(Row)`
  margin-top: 150px;
  object-fil: cover;
  .item-title {
    color: #363538;
  }
  .card {
    text-align: center;
    margin-left: 10px;
  }
  @media screen and (max-width: 480px) {
    margin-top: 0px;
    text-align: center;
    .ant-card-body {
      padding: 0px;
      background-color: unset;
    }
    .paragraph {
      padding-top: 30px;
    }
    .card {
      text-align: center;
      height: 100%;
      margin-left: 0px;
    }
    img {
      object-fit: cover;
      width: 100%;
      height: 250px;
    }
  }
`;
const StyledCard = styled(Card)`
  padding-bottom: 50px;
  .ant-typography {
    font-size: 36px !important;
    text-transform: uppercase;
    margin-top: 50px;
    margin-bottom: 0px;
    -webkit-transition: 0.8s ease-in-out;
    transition: 0.8s ease-in-out;
    transform: translateY(0%);
  }
  .ant-btn {
    display: none;
    margin: 0 auto;
    min-height: 50px;
  }
  .arrow {
    margin-left: 16px;
  }
  .column figure img {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: 0.8s ease-in-out;
    transition: 0.8s ease-in-out;
  }
  @media screen and (min-width: 479px) {
    .column figure:hover .ant-typography {
      -webkit-transition: 0.8s ease-in-out;
      transition: 0.8s ease-in-out;
      transform: translateY(-70%);
      position: relative;
      bottom: 15%;
    }
    .column figure:hover .ant-btn {
      display: block;
      position: absolute;
      bottom: 3%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .Silver {
    margin-left: 10px;
  }
  @media screen and (max-width: 480px) {
    .ant-typography {
      margin-top: 0px;
      margin-bottom: 10px;
      font-size: 24px !important;
    }
    .ant-btn {
      display: block;
    }
  }
`;

const NewCollection = () => {
  const { data } = useGetRequest({
    request: metalGet,
    initialData: {},
  });
  const history = useRouterHistory();
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Col md={{ span: 23, push: 1 }} xs={24}>
        <Paragraph className='paragraph' level='4'>
          {t('newCollection').toUpperCase()}
        </Paragraph>
      </Col>
      {data?.data?.map((item) => (
        <Col
          className='card'
          md={{ span: 11, push: 1 }}
          xs={{ span: 24, push: 0 }}
          key={item.id}
        >
          <StyledCard bordered={false} hoverable>
            <div className='column'>
              <figure>
                <Img
                  width='500px'
                  height='450px'
                  src={item.image}
                  style={{ display: 'inline' }}
                />
                <Paragraph className='item-title'>
                  {item.title === 'ՈՍԿԻ'
                    ? 'ՈՍԿՅԱ'
                    : item.title === 'ԱՐԾԱԹ'
                    ? 'ԱՐԾԱԹՅԱ'
                    : item.title}{' '}
                  {item.title === 'ЗОЛОТО' || item.title === 'СЕРЕБРО'
                    ? null
                    : t('jewellery').toUpperCase()}
                </Paragraph>
                <Button
                  className='figureButton'
                  onClick={() => {
                    window.localStorage.setItem('tag', JSON.stringify([]));
                    history.push(`/shop?metalId=${item.id}&page=1`);
                  }}
                >
                  {t('goToCatalog').toUpperCase()}{' '}
                  <span className='arrow'>&#8594;</span>
                </Button>
              </figure>
            </div>
          </StyledCard>
        </Col>
      ))}
    </Wrapper>
  );
};

export default NewCollection;
