import React from 'react';
import { Text, Paragraph, Img } from 'styled';
import styled from 'styled-components';
import { collectionGet } from 'services/collection';
import { useGetRequest } from 'hooks/useGetRequest';
import { useRouterHistory } from 'hooks/routerHooks';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  margin-top: 200px;
  .collect-names {
    bottom: 5%;
    font-size: 24px !important;
    display: none;
    color: white;
    position: absolute;
    text-align: center;
  }
  .collect-names:hover {
    display: block;
    cursor: pointer;
  }
  .title {
    font-size: 32px !important;
    text-align: center;
  }
  .image-div {
    background: #312000;
    width: 290px;
  }
  .image-div .figure {
    margin: 0px;
    display: flex;
    justify-content: center;
  }
  .image-div .figure img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    cursor: pointer;
    display: block;
    height: 500px;
    width: 598px;
    opacity: 1;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.5s ease-in-out;
  }
  .image-div .figure:hover img {
    opacity: 0.8;
  }
  .image-div .figure:hover .collect-names {
    display: block;
  }
  .slick-initialized .slick-slide {
    text-align: center;
    cursor: pointer;
  }
  .slick-prev {
    left: 4% !important;
    width: 60px;
    height: 60px;
    z-index: 10;
    :before {
      font-size: 60px;
      color: black;
    }
  }
  .slick-next {
    right: 6% !important;
    width: 60px;
    height: 60px;
    z-index: 10;
    :before {
      font-size: 60px;
      color: black;
    }
  }
  @media screen and (max-width: 992px) {
    .slick-next,
    .slick-prev {
      :before {
        display: none;
      }
    }
  }
  @media screen and (max-width: 480px) {
    margin-top: 0px;
    padding-top: 50px;
    padding-bottom: 50px;
    .title {
      padding: 0 30px;
      font-size: 30px !important;
    }
    .image-div {
      background: #f4ede7;
    }
    .image-div .figure {
      height: 290px;
      width: 290px;
    }
    .image-div .figure img {
      height: 280px;
      width: 280px;
    }
    .slick-list {
      padding-left: 20px;
    }
    .collect-names {
      font-size: 20px !important;
      bottom: 12%;
      width: 280px !important;
      display: block;
      color: black;
      position: absolute;
      text-align: center;
      width: auto;
    }
  }
`;
const Collection = () => {
  const { data } = useGetRequest({
    request: collectionGet,
    initialData: { data: [] },
  });
  const history = useRouterHistory();

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: data.data.length,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2.2,
          slidesToScroll: 2.2,
          initialSlide: 2,
          dots: true,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const { t } = useTranslation();

  return (
    <Wrapper>
      <Paragraph className='title'>
        {t('shopByCollection').toUpperCase()}
      </Paragraph>
      <Slider {...settings}>
        {data?.data.map((item) => (
          <div
            className='image-div'
            key={item.id}
            onClick={() => {
              window.localStorage.setItem('tag', JSON.stringify([]));
              history.push(`/shop?collectionId=${item.id}&page=1`);
            }}
          >
            <div className='figure'>
              <Img src={item.image} className='image' />
              <Text className='collect-names'>{item.name}</Text>
            </div>
          </div>
        ))}
      </Slider>
    </Wrapper>
  );
};

export default Collection;
