import { Link } from 'react-router-dom';
import { Divider, Col, Form, Input } from 'antd';
import { Text, Row, Button, Paragraph, Img } from 'styled';
import contact_us_page from 'images/contact_us_ page.png';
import Wrapper from '../BannerWrapper/index';
import styled from 'styled-components';

//language
import { useTranslation } from 'react-i18next';
import useRequest from 'hooks/useRequest';
import { contactSendApi } from 'services/faq';

const FormComponent = styled(Form)`
  position: absolute;
  top: 10%;
  left: 50%;
  width: 100%;
  z-index: 10;

  .ant-form-item-label > label {
    color: #787878;
  }
  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
  }
  .ant-input {
    border: 0;
    height: 60px;
    font-weight: 400;
    font-size: 18px;
  }
  .ant-btn {
    width: 160px;
    min-height: 50px !important;
    border-radius: 5px;
  }
  .ant-form-item-control-input-content > textarea {
    height: 130px;
    max-height: 190px;
  }
  @media screen and (max-width: 1200px) {
    top: 5%;
    left: 30%;
  }
  @media screen and (max-width: 480px) {
    top: 5%;
    left: 0;
    position: relative;
    width: 300px;
    margin: 0 24px;
    .ant-form-item-required {
      font-size: 18px;
    }
    .label {
      font-size: 18px;
    }
  }
`;

const handleSubmit = (form, makeRequest) => {
  form.validateFields().then((values) => {
    makeRequest({ ...values });
  });
};

const Contact = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const { makeRequest } = useRequest({
    request: contactSendApi,
    initialData: {},
  });

  return (
    <Wrapper>
      <Row>
        <Col md={24} className='breadcrumb'>
          <Link to='/'>
            <Text level='1' weight='bold'>
              {t('home').toUpperCase()}
            </Text>
          </Link>
          <Divider type='vertical' />
          <Text className='title'>{t('contactUs.title')}</Text>
        </Col>
        <Col md={{ span: 24, push: 0 }} xs={{ span: 22, push: 1 }}>
          <Row className='form-row'>
            <Col lg={12} xs={24} className='form-wrap'>
              <FormComponent
                form={form}
                onFinish={() => handleSubmit(form, makeRequest)}
                layout='vertical'
              >
                <Paragraph level='5' weight='bold' className='conact-us-title '>
                  {t('contactUs.title')}
                </Paragraph>
                <Form.Item
                  name='name'
                  label={`${t('firstName')}*`}
                  rules={[{ required: true }]}
                >
                  <Input placeholder={t('enterName')} />
                </Form.Item>
                <Form.Item
                  name='email'
                  label={t('email')}
                  rules={[{ required: true }]}
                >
                  <Input placeholder={t('enterEmailAddress')} />
                </Form.Item>

                <Form.Item name='subject' label={t('contactUs.subject')}>
                  <Input className='label' placeholder='Enter subject' />
                </Form.Item>
                <Form.Item
                  name='message'
                  label={t('contactUs.message')}
                  className='label'
                >
                  <Input.TextArea placeholder={t('contactUs.message')} />
                </Form.Item>
                <Button
                  htmlType='submit'
                  style={{ background: '#3f2f1e', color: 'white' }}
                >
                  {t('send').toUpperCase()}
                </Button>
              </FormComponent>
              <Img src={contact_us_page} className='contact-image-1' />
            </Col>
            <Col lg={12} xs={24} className='contact-image-div'>
              <Img src={contact_us_page} className='contact-image-2' />
            </Col>
          </Row>
        </Col>
      </Row>
    </Wrapper>
  );
};

export default Contact;
