import { useState } from 'react';
import { useRouterHistory } from 'hooks/routerHooks';
import { GafriModal, Menu, Row, Col } from 'styled';
import { useTranslation } from 'react-i18next';

const UserMenu = ({ setVisibleMenu }) => {
  const [visible, setVisible] = useState(false);
  const history = useRouterHistory();
  const userInfo = window.localStorage.getItem('userInfo');

  const { t } = useTranslation();

  return (
    <>
      {userInfo && (
        <Row>
          <Col>
            <Menu
              className='gafri-user-menu'
              style={{ width: 'inline' }}
              triggerSubMenuAction='click'
              onClick={() => setVisibleMenu(false)}
            >
              <Menu.Item
                key='profile'
                onClick={() => {
                  history.push('/user/profile');
                }}
              >
                {t('personalInformation')}
              </Menu.Item>
              {/* <Menu.Item
                key='order'
                onClick={() => {
                  history.push('/user/order');
                }}
              >
                Order
              </Menu.Item> */}
              <Menu.Item
                key='addresses'
                onClick={() => {
                  history.push('/user/addresses');
                }}
              >
                {t('address')}
              </Menu.Item>
              {/* <Menu.Item
                key='cards'
                onClick={() => {
                  history.push('/user/cards');
                }}
              >
                Cards
              </Menu.Item> */}
              <Menu.Item
                className='signOut'
                key='signOut'
                onClick={() => {
                  setVisible(true);
                }}
              >
                {t('signOut')}
              </Menu.Item>
            </Menu>
            <GafriModal
              visible={visible}
              onOk={() => {
                window.localStorage.removeItem('userInfo');
                setVisible(false);
                history.push('/');
                window.location.reload();
              }}
              onCancel={() => setVisible(false)}
              title={t('logOut')}
              modalText={t('logOutFromYourAccount')}
            />
          </Col>
        </Row>
      )}
    </>
  );
};
export default UserMenu;
