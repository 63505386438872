import styled from 'styled-components';

const Wrapper = styled.div`
  .text {
    display: block;
    padding-bottom: 20px;
    font-weight: normal;
    font-size: 22px;
    font-family: Roboto-Regular;
    @media screen and (max-width: 992px) {
      font-size: 18px;
      font-family: Futura;
    }
  }
  .title {
    display: block;
    padding: 40px 0;
    font-size: 32px;
    font-weight: bold;
    @media screen and (max-width: 1200px) {
      font-size: 26px;
      padding: 30px 0;
    }
    @media screen and (max-width: 992px) {
      font-size: 24px;
      padding: 20px 0;
      font-family: Futura !important;
    }
    @media screen and (max-width: 480px) {
      padding: 20px 0;
    }
  }
`;

export default Wrapper;
