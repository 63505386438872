import { Link } from 'react-router-dom';
import { Divider, List, Spin } from 'antd';
import { Row, Col, Paragraph, Card, Text, Img, Button } from 'styled';
import Wrapper from './style';

//img
import HeartFull from 'images/heartFull.svg';
import Heart from 'images/heart.svg';

//request;
import useGetRequest from 'hooks/useGetRequest';
import useRequest from 'hooks/useRequest';
import { addRemoveFromFavoritesApi, getFavoritesApi } from 'services/fevorites';
import Price from '../Price';

//language
import { useTranslation } from 'react-i18next';

const Favorites = ({ reFetch }) => {
  const {
    data: response,
    loading,
    reFetch: rowLoade,
  } = useGetRequest({
    request: getFavoritesApi,
    initialData: {},
  });

  const { makeRequest } = useRequest({
    request: addRemoveFromFavoritesApi,
    initialData: {},
    onSuccess: () => {
      rowLoade();
      reFetch();
    },
  });

  const { t } = useTranslation();

  return (
    <Wrapper>
      <Spin spinning={loading}>
        <Row style={{ marginTop: '100px' }}>
          <Col span={24} className='breadcrumb'>
            <Link to='/'>
              <Text level='1' weight='bold'>
                {t('home').toUpperCase()}
              </Text>
            </Link>
            <Divider type='vertical' />
            <Text
              style={{
                color: '#e3a876',
                fontWeight: 'bold',
                fontSize: '16px',
                cursor: 'pointer',
              }}
            >
              {t('favorites').toUpperCase()}
            </Text>
          </Col>
          <Col span={24}>
            <Paragraph level={4} style={{ textAlign: 'center' }}>
              {t('favorites').toUpperCase()}
            </Paragraph>
          </Col>
          <Col span={24}>
            <List
              grid={{
                xs: 2,
                sm: 2,
                md: 3,
                lg: 4,
                xl: 4,
                xxl: 4,
              }}
              pagination={response?.data?.length > 18 ? true : false}
              dataSource={response?.data}
              renderItem={(item) => (
                <List.Item>
                  <Card>
                    <Button
                      type='link'
                      className='no-decoration-button heart-icon'
                      onClick={(e) => {
                        e.stopPropagation();
                        makeRequest({ id: item.id });
                      }}
                    >
                      <Img src={item.isFavorite ? HeartFull : Heart} />
                    </Button>
                    <Link to={`/shop/product-details/${item.id}`}>
                      <Img
                        src={item.defaultImage}
                        onMouseOver={(e) => {
                          e.currentTarget.src = item.secondaryImage;
                        }}
                        onMouseOut={(e) => {
                          e.currentTarget.src = item.defaultImage;
                        }}
                        className='img'
                        style={{
                          display: 'inline-block',
                          position: 'relative',
                        }}
                      />
                      <Paragraph className='prod-name' $noMargin>
                        {item.title}
                      </Paragraph>
                      <Price price={item.price} promotion={item.promotion} />
                    </Link>
                  </Card>
                </List.Item>
              )}
            />
          </Col>
        </Row>
      </Spin>
    </Wrapper>
  );
};

export default Favorites;
