import React, { memo } from 'react';
import { Form, Input, Button, Row, Col, Span, Text } from 'styled';
import { useRouterHistory } from 'hooks/routerHooks';
import useRequest from 'hooks/useRequest';
import { forgotApi } from 'services/users';
import { useTranslation } from 'react-i18next';

const Forgot = () => {
  const [form] = Form.useForm();
  const history = useRouterHistory();
  const handleSubmit = (form, makeRequest) => {
    form.validateFields().then((values) => {
      makeRequest(values);
    });
  };

  const onSuccess = () => {
    const email = form.getFieldValue('email');
    history.push('/forgot/verification', { email });
  };

  const { makeRequest } = useRequest({
    request: forgotApi,
    initialData: {},
    onSuccess,
  });

  const { t } = useTranslation();

  return (
    <Row style={{ height: '100vh', marginTop: '150px' }}>
      <Form
        onFinish={() => handleSubmit(form, makeRequest)}
        form={form}
        className='login-form centered'
      >
        <Col span={24} style={{ textAlign: 'center' }}>
          <Span level={4} className='form-title'>
            {t('forgotPassword')}
          </Span>
        </Col>
        <Col span={24} style={{ textAlign: 'center' }}>
          <Text level={2} className='please-text'>
            Please enter your phone number to reset your password.
          </Text>
        </Col>
        <Form.Item
          name='email'
          validateTrigger='onSubmit'
          rules={[
            {
              required: true,
              message: 'Please input the E-mail',
              whitespace: true,
            },
            {
              type: 'email',
              message: 'Please input a valid E-mail',
            },
          ]}
        >
          <Input placeholder='Enter phone number or email address' />
        </Form.Item>
        <Form.Item>
          <Row type='flex' justify='center'>
            <Button
              type='submit'
              htmlType='submit'
              className='submit-button'
              block
            >
              NEXT
            </Button>
          </Row>
        </Form.Item>
        <Row style={{ textAlign: 'center' }}>
          <Col span={24}>
            <Span className='have-account-text'>{t('haveAccount')}</Span>
            <Span style={{ marginLeft: '10px' }}>
              <Button
                type='link'
                className='no-decoration-button have-account-button'
                onClick={() => {
                  history.push('/signup');
                }}
              >
                {t('signUp').toUpperCase()}
              </Button>
            </Span>
          </Col>
        </Row>
      </Form>
    </Row>
  );
};

export default memo(Forgot);
