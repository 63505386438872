import { Img } from 'styled';
import ShopNow from 'images/shop-now.jpg';
import fishes from 'images/fishes.png';
import purple_stones from 'images/purple_stones.png';
import gold_jewelery from 'images/gold_jewelery.png';
import two_bracelets from 'images/two_bracelets.png';

const banner = (t) => [
  {
    title: 'GAFRI JEWELLERY',
    desc: t('theColors'),
    img: <Img className=' banner-img shop-now-img' src={ShopNow} />,
  },
  {
    key: t('silver').toLowerCase(),
    title: t('silverJewellery').toUpperCase(),
    desc: t('diverseCollection'),
    img: <Img className='banner-img' src={two_bracelets} />,
  },
  {
    key: t('gold').toLowerCase(),
    title: t('goldJewellery').toUpperCase(),
    desc: t('exclusive'),
    img: (
      <Img
        className='banner-img'
        src={gold_jewelery}
        style={{ objectFit: 'cover' }}
      />
    ),
  },
  {
    key: 'new',
    title: t('newIn').toUpperCase(),
    desc: t('newJewelry'),
    img: (
      <Img
        className='banner-img'
        src={purple_stones}
        style={{ objectFit: 'cover' }}
      />
    ),
  },
  {
    key: 'sale',
    title: t('yourLastChance').toUpperCase(),
    desc: t('underSale'),
    img: <Img className='banner-img' src={fishes} />,
  },
];

export default banner;
