import { useEffect, useState } from 'react';
import styled from 'styled-components';
import useGetRequest from 'hooks/useGetRequest';
import useRequest from 'hooks/useRequest';
import { deleteAddressApi, getAllAddressApi } from 'services/profile';
import { Button, Row, Col, Text, Card, GafriModal } from 'styled';
import Diamond from 'images/diamond.svg';
import AddNewAddress from './AddNewAddress';
import EditAddress from './EditAddress';
import { useTranslation } from 'react-i18next';

const Image = styled.img`
  display: block;
  width: 80px;
`;

const CardItem = ({ data, reFetch, setIsEditAddres }) => {
  const { makeRequest } = useRequest({
    request: deleteAddressApi,
    initialData: {},
    onSuccess: () => {
      setVisible(false);
      reFetch();
    },
  });

  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();
  return (
    <>
      {data?.map((e) => (
        <Col xs={24} lg={12} xl={8} className='card-item' key={e.id}>
          <Card bordered={false}>
            <Row>
              <Col span={16}>
                <Row>
                  <Col span={24}>
                    <Text level={2}>{e.title}</Text>
                  </Col>
                  <Col span={24}>
                    <Text type='secondary' level={1}>
                      {e.country}
                    </Text>
                    {',  '}
                    <Text type='secondary' level={1}>
                      {e.city}
                    </Text>
                  </Col>
                  <Col span={24}>
                    <Text type='secondary' level={1}>
                      {e.fullAddress}
                    </Text>
                  </Col>
                </Row>
              </Col>
              <Col span={8}>
                <Row
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'end',
                    alignItems: 'end',
                    paddingTop: '8px',
                  }}
                >
                  <Col span={22} offset={2}>
                    <Image src={Diamond} />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row style={{ paddingTop: '8px' }}>
              <Col span={12}>
                <Button
                  type='link'
                  className='link-button'
                  onClick={() => {
                    setIsEditAddres({
                      visible: true,
                      data: e,
                    });
                  }}
                >
                  {t('edit')}
                </Button>
              </Col>
              <Col
                span={12}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'end',
                  alignItems: 'end',
                }}
              >
                <Button
                  type='link'
                  className='link-button'
                  onClick={() => {
                    setVisible(true);
                  }}
                >
                  {t('delete')}
                </Button>
              </Col>
            </Row>
          </Card>
          <GafriModal
            visible={visible}
            onOk={() => {
              makeRequest({ id: e.id });
            }}
            onCancel={() => setVisible(false)}
            title='Delete'
            modalText={t('deleteYourAddress')}
          />
        </Col>
      ))}
    </>
  );
};

const Addresses = () => {
  const [isAddAddress, setIsAddAddres] = useState(false);
  const [isEditAddress, setIsEditAddres] = useState({
    visible: false,
    data: {},
  });

  const { data: response, reFetch } = useGetRequest({
    request: getAllAddressApi,
    initialData: { data: [] },
  });

  useEffect(() => {
    !isAddAddress && reFetch();
  }, [isAddAddress, reFetch]);

  const { t } = useTranslation();

  return (
    <>
      {isAddAddress ? <AddNewAddress setIsAddAddres={setIsAddAddres} /> : null}
      {isEditAddress.visible ? (
        <EditAddress
          setIsEditAddres={setIsEditAddres}
          data={isEditAddress.data}
          reFetch={reFetch}
        />
      ) : null}
      {!isEditAddress.visible && !isAddAddress && (
        <Card title={t('address')} className='gafri-card'>
          <Row>
            <CardItem
              data={response?.data}
              reFetch={reFetch}
              setIsEditAddres={setIsEditAddres}
            />
            <Col xs={24} lg={12} xl={8} className='card-item'>
              <Card>
                <Row
                  style={{
                    justifyContent: 'centre',
                    textAlign: 'center',
                    margin: '4px 0',
                  }}
                >
                  <Col
                    span={24}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      paddingTop: '8px',
                    }}
                  >
                    <Image src={Diamond} />
                  </Col>
                  <Col span={24} style={{ paddingTop: '8px' }}>
                    <Button
                      type='link'
                      className='link-button'
                      onClick={() => {
                        setIsAddAddres(true);
                      }}
                    >
                      {t('addNewAddress')}
                    </Button>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Card>
      )}
    </>
  );
};

export default Addresses;
