import axios from 'axios';

// import { API_ROOT } from 'configs/env-vars';
const i18nextLng = window.localStorage.getItem('i18nextLng') || 'en';

const ClientServices = (
  headers = {
    Pragma: 'no-cache',
    'Cache-Control': 'no-cache, no-store',
    languageCode: i18nextLng,
    Authorization: `Bearer ${window.localStorage.getItem('userInfo')}`,
  }
) => {
  const service = axios.create({
    baseURL: 'https://api.yerevanjewelry.com/api/', // url of the api
    headers,
    withCredentials: true,
  });
  service.interceptors.response.use(
    (response) => response,
    (error) => {
      const errorResponse = error.response;
      if (
        error.toJSON().message === 'Network Error' &&
        window.location.pathname !== '/not-found'
      ) {
        window.location.href = '/not-found?type=network-error';
      }
      if (process.env.NODE_ENV === 'production') {
        switch (errorResponse.status) {
          case 404:
            if (window.location.pathname !== '/not-found')
              window.location.href = '/not-found?type=404';
            break;
          case 403:
            if (window.location.pathname !== '/not-found')
              window.location.href = '/not-found?type=403';
            break;
          default:
            break;
        }
      }
      if (errorResponse?.status === 401) {
        window.localStorage.removeItem('userInfo');
        // window.location.pathname = '/signin';
      }

      throw error;
    }
  );
  return service;
};

export default ClientServices;
