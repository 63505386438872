import styled from 'styled-components';
import { Card as Component } from 'antd';

const Card = styled(Component)`
  &.gafri-card {
    .ant-card-head {
      background-color: #f4ede7;
    }
    .ant-card-body {
      background-color: #fbfbfb;
      padding: 0 30px;
      .card-item {
        padding: 15px;
        .ant-card {
          .ant-card-body {
            background-color: #ffffff;
            padding: 0 15px;
          }
        }
      }
    }
  }
`;

/** @component */
export default Card;
