import React, { useState } from 'react';
import { Col } from 'antd';
import { Row, Img, Button, Paragraph } from 'styled';
import three_necklaces from 'images/three_necklaces.png';
import styled from 'styled-components';
import MapModalContext from '../../MapModalContext';
import { useTranslation } from 'react-i18next';

const Wrapper = styled(Row)`
  background-color: #f4ede7;
  margin-top: 150px;
  .info {
    justify-content: center;
    text-align: center;
    margin-top: 70px;
    width: 500px;
    .ant-typography {
      font-size: 18px !important;
    }
    .find {
      font-size: 26px !important;
    }
  }
  img {
    object-fit: cover;
  }
  @media screen and (max-width: 480px) {
    margin-top: 0px;
    padding-top: 70px;
    img {
      height: 300px;
    }
    .info {
      margin-top: 40px;
      width: 100%;
      margin-bottom: 100px;
      padding: 0 40px;
      .ant-typography {
        font-size: 18px !important;
      }
    }
  }
`;

const InfoSection = () => {
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Col
        className='info'
        xs={{ span: 24, order: 2 }}
        md={{ span: 10, order: 1 }}
      >
        <Paragraph className='find'>
          {t('contactUs.title').toUpperCase()}
        </Paragraph>
        <Paragraph>{t('address_1')} </Paragraph>
        <Paragraph>{t('address_2')}</Paragraph>
        <Paragraph>info@gafri.am</Paragraph>
        <Paragraph>(+374)55 15 99 99</Paragraph>
        <Button
          style={{ background: 'transparent', minHeight: '52px' }}
          onClick={() => setVisible(true)}
        >
          {t('findOnMap')}
        </Button>
      </Col>
      <MapModalContext visible={visible} setVisible={setVisible} />
      <Col xs={{ span: 24, order: 1 }} md={{ span: 14, order: 2 }}>
        <Img height='500px' width='100%' src={three_necklaces} />
      </Col>
    </Wrapper>
  );
};

export default InfoSection;
