import styled from 'styled-components';
import { Row } from 'styled';

const Wrapper = styled(Row)`
  background-color: #f4ede7;
  justify-content: center;
  height: 30vh;

  .necklace-img {
    position: absolute;
    display: block;
    left: 10%;
    top: 20%;
    z-index: 5;
    object-fit: cover;
    width: 600px;
    @media screen and (max-width: 1992px) {
      top: 25%;
      width: 480px;
      height: 600px;
      left: 10%;
    }
    @media screen and (max-width: 1700px) {
      width: 400px;
      height: 500px;
    }

    @media screen and (max-width: 1500px) {
      width: 320px;
      height: 400px;
    }
    @media screen and (max-width: 1280px) {
      top: 20%;
      left: 5%;
    }
    @media screen and (max-width: 1100px) {
      width: 280px;
      height: 320px;
      top: 30%;
    }
    @media screen and (max-width: 800px) {
      top: 60%;
    }
    @media screen and (max-width: 650px) {
      left: 0;
    }
  }

  .girl-img {
    position: absolute;
    height: 900px;
    right: 0;
    object-fit: cover;
    @media screen and (max-width: 1992px) {
      right: 0;
      height: 110vh;
      width: 850px;
    }
    @media screen and (max-width: 1700px) {
      right: 0;
      height: 110vh;
      width: 750px;
    }
    @media screen and (max-width: 1500px) {
      width: 650px;
    }
    @media screen and (max-width: 1280px) {
      width: 550px;
    }
    @media screen and (max-width: 1100px) {
      top: 120px;
      width: 450px;
      height: 70vh;
    }
    @media screen and (max-width: 992px) {
      width: 400px;
      height: 60vh;
    }
    @media screen and (max-width: 800px) {
      width: 500px;
      height: 70vh;
    }
    @media screen and (max-width: 650px) {
      width: 400px;
      height: 60vh;
    }
    @media screen and (max-width: 480px) {
      width: 380px;
    }
  }

  .text-block {
    letter-spacing: 0.4rem !important;
    position: absolute;
    top: 30%;
    left: 30%;
    z-index: 10;
    color: #363538 !important;
    button {
      margin-left: 20px;
    }
    @media screen and (max-width: 1992px) {
      top: 40%;
    }
    @media screen and (max-width: 1700px) {
      left: 25%;
    }
    @media screen and (max-width: 1500px) {
      top: 35%;
    }
    @media screen and (max-width: 1100px) {
      top: 35%;
      left: 20%;
    }
    @media screen and (max-width: 800px) {
      left: 30%;
      top: 70%;
    }
    @media screen and (max-width: 650px) {
      top: 60%;
    }
    @media screen and (max-width: 480px) {
      top: 65%;
    }
  }

  .paragraph {
    color: #363538 !important;
    padding-bottom: 50px;
    line-height: 1.2;
    font-size: 90px !important;
    font-weight: normal !important;
    @media screen and (max-width: 1700px) {
      font-size: 80px !important;
    }
    @media screen and (max-width: 1500px) {
      font-size: 65px !important;
    }
    @media screen and (max-width: 1280px) {
      font-size: 65px !important;
    }
    @media screen and (max-width: 1100px) {
      font-size: 60px !important;
    }
    @media screen and (max-width: 992px) {
      font-size: 55px !important;
    }
    @media screen and (max-width: 650px) {
      font-size: 40px !important;
    }
  }

  @media screen and (max-width: 5000px) {
    height: 40vh;
  }
  @media screen and (max-width: 3500px) {
    height: 70vh;
  }
  @media screen and (max-width: 2500px) {
    height: 80vh;
  }
  @media screen and (max-width: 1992px) {
    height: 100vh;
  }
  @media screen and (max-width: 1100px) {
    padding-bottom: 50px;
  }
  @media screen and (max-width: 800px) {
    height: 110vh;
  }
`;

export default Wrapper;
