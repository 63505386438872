import banner_for_home_page from 'images/gold-banner.png';
import styled from 'styled-components';
import { Text, Img, Button, Paragraph } from 'styled';
import MapModalContext from 'components/MapModalContext';
import { useState } from 'react';

const Wrapper = styled.div`
  margin-top: 30px;
  position: relative;
  display: flex;
  justify-content: center;
  .text-block {
    position: absolute;
    top: 20%;
    left: 8%;
    .title {
      color: white;
      font-size: 45px !important;
      font-family: Futura !important;
      letter-spacing: 2px !important;
    }
    .text {
      color: white !important;
      width: 80%;
      margin-top: 23px;
      font-size: 22px !important;
      font-family: Futura !important;
    }
    @media only screen and (max-width: 1200px) {
      top: 15%;
      .title {
        font-size: 30px !important;
      }
      .text {
        font-size: 18px !important;
      }
    }
    @media only screen and (max-width: 992px) {
      left: 4%;
      .title {
        display: block;
        width: 80%;
        font-size: 22px !important;
      }
      .text {
        width: 90%;
        font-size: 14px !important;
      }
    }
    // @media only screen and (max-width: 700px) {
    //   left: 4%;
    //   .title {
    //     font-size: 20px !important;
    //   }
    //   .text {
    //     width: 90%;
    //     font-size: 12px !important;
    //   }
    // }
  }
  .ant-btn {
    border: 1px solid white;
    color: white;
    width: 220px;
    min-height: 50px;
    padding: 0px;
    background: transparent !important;
  }
  .ant-btn:hover {
    border: 1px solid white !important;
  }
  .ant-btn :focus {
    border: 1px solid white !important;
  }
  .ant-btn ::after {
    border: 1px solid white !important;
  }

  img {
    width: 100%;
    height: 460px;
    object-fit: cover;
    @media only screen and (max-width: 1500px) {
      height: 430px;
    }
    @media only screen and (max-width: 1500px) {
      height: 380px;
    }
    @media only screen and (max-width: 1200px) {
      height: 350px;
    }
    @media only screen and (max-width: 480px) {
      height: 300px;
    }
  }
  @media only screen and (max-width: 480px) {
    margin-top: 0px;
    padding: 0;
    .title {
      font-size: 20px;
    }
    .text-block {
      top: 6%;
      left: 6%;
    }
    .text {
      width: 225px;
    }
    .ant-btn {
      width: 120px;
    }
  }
`;

const SmallSection = () => {
  const [visible, setVisible] = useState(false);
  return (
    <Wrapper>
      <Img src={banner_for_home_page} />
      <div className='text-block'>
        <Text className='title'>ENJOY OUR WIDER COLLECTION OF GOLD </Text>
        <Paragraph className='text'>
          Unfortunately, our gold collection is very limited for online sales,
          <br />
          but we have a very diverse pieces of jewelry available for offline
          <br />
          sales in our Yerevan store
        </Paragraph>
        <Button
          style={{ background: 'transparent', minHeight: '52px' }}
          onClick={() => setVisible(true)}
        >
          FIND US
        </Button>
        <MapModalContext visible={visible} setVisible={setVisible} />
      </div>
    </Wrapper>
  );
};

export default SmallSection;
