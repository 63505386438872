import { Col, Collapse } from 'antd';
import { Text, Row, Img } from 'styled';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import gafri from 'images/gafri.svg';
import tikTok from 'images/tiktok.svg';

//language
import { useTranslation } from 'react-i18next';

const FooterWrapper = styled(Row)`
  justify-content: space-around;
  margin-top: 100px;
  min-height: 280px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
  a {
    font-size: 20px;
    color: black;
    &:hover {
      color: unset;
    }
  }
  .ant-col {
    margin-top: 45px;
  }

  @media screen and (max-width: 768px) {
    .logo {
      display: contents;
    }
  }
  @media screen and (max-width: 480px) {
    .ant-col {
      margin-top: 0px;
    }
  }
`;

const CollapseComponent = styled(Collapse)`
  background-color: #fff;
  font-weight: bold;
  margin-bottom: 40px;

  .ant-collapse-header {
    font-weight: normal;
    padding: 0px;
    span {
      vertical-align: middle;
    }
  }
  .ant-collapse-item {
    border-bottom: 1px solid white;
    font-size: 20px;
  }
  .ant-collapse-content-box > p {
    font-weight: normal !important;
    font-size: 20px;
  }
  svg {
    font-weight: bold;
    font-size: 22px;
  }
  .block a {
    font-family: Roboto-Medium;
    display: block;
    font-size: 18px;
    font-weight: normal;
    padding-top: 14px;
  }
  .social {
    margin-left: 5px;
  }
`;

const { Panel } = Collapse;

const Footer = () => {
  const { t } = useTranslation();
  if (window.location.pathname === '/not-found') {
    return <></>;
  }
  return (
    <FooterWrapper>
      <Col xs={24} md={{ span: 5, offset: 1 }} className='logo'>
        <Img src={gafri} width={150} height={100} />
      </Col>
      <Col
        xs={0}
        md={6}
        style={{
          paddingLeft: '15px',
          paddingRight: '15px',
        }}
      >
        <Text level='2' weight='bold'>
          {t('getToKnowUs').toUpperCase()}
        </Text>
        <br />
        <br />
        <Link to='/about'>
          <Text $noMargin level='1' style={{ cursor: 'pointer' }}>
            {t('aboutUs.title')}
          </Text>
          <br />
        </Link>
        <Link to='/stores'>
          <Text $noMargin level='1'>
            {t('ourStores')}
          </Text>
          <br />
        </Link>
        <Link to='/contact'>
          <Text $noMargin level='1'>
            {t('contactUs.title')}
          </Text>
          <br />
        </Link>
      </Col>
      <Col
        xs={0}
        md={6}
        style={{
          paddingLeft: '15px',
          paddingRight: '15px',
        }}
      >
        <Text level='2' weight='bold'>
          {t('customerCare').toUpperCase()}
        </Text>
        <br />
        <br />
        {/* <Link to='/payment'>
        <Text $noMargin level='1'>
          Payment method
        </Text>
        <br />
      </Link> */}
        <Link to='/shipping'>
          <Text $noMargin level='1'>
            {t('shippingAndReturn.title')}
          </Text>
          <br />
        </Link>
        <Link to='/questions'>
          <Text $noMargin level='1'>
            {t('faq')}
          </Text>
          <br />
        </Link>
        <Link to='/privacy'>
          <Text $noMargin level='1'>
            {t('privacyPolicy')}
          </Text>
          <br />
        </Link>
      </Col>
      <Col
        xs={0}
        md={6}
        style={{
          paddingLeft: '15px',
          paddingRight: '15px',
        }}
      >
        <Text level='2' weight='bold'>
          {t('socialMedia').toUpperCase()}
        </Text>
        <br />
        <br />
        <Text style={{ marginTop: '16px' }}>
          <a
            rel='noreferrer'
            target='_blank'
            href='https://www.facebook.com/GafriGoldYerevan/?ref=pages_you_manage'
          >
            <i className='fa fa-facebook' aria-hidden='true'></i>
          </a>
          <a
            rel='noreferrer'
            target='_blank'
            href=' https://www.instagram.com/gafri_jewellery_yerevan/'
            style={{ marginLeft: '24px' }}
          >
            <i className='fa fa-instagram' aria-hidden='true'></i>
          </a>
          <a
            rel='noreferrer'
            target='_blank'
            href='https://vm.tiktok.com/ZSefmhCgp'
            style={{ marginLeft: '24px', display: 'inline-block' }}
          >
            <Img src={tikTok} width={15} className='tiktok' />
          </a>
          <a
            rel='noreferrer'
            target='_blank'
            href='https://youtube.com/channel/UCr7fHXcCAmt-FsLTHhKIZjg'
            style={{ marginLeft: '24px', display: 'inline-block' }}
          >
            <i class='fa fa-youtube-play'></i>
          </a>
        </Text>
        <br />
      </Col>

      <Col md={0} xs={24}>
        <CollapseComponent
          defaultActiveKey={['3']}
          bordered={false}
          expandIconPosition='right'
        >
          <Panel
            header={t('getToKnowUs').toUpperCase()}
            key='1'
            className='block'
          >
            <Link to='/about'>
              <Text>{t('aboutUs.title')}</Text>
            </Link>
            <Link to='/stores'>{t('ourStores')}</Link>
            <Link to='/contact'>{t('contactUs.title')}</Link>
          </Panel>
          <Panel
            header={t('customerCare').toUpperCase()}
            key='2'
            className='block'
          >
            {/* <Link to='/payment'>Payment method</Link> */}
            <Link to='/shipping'>{t('shippingAndReturn.title')}</Link>
            <Link to='/questions'>{t('faq')}</Link>
            <Link to='/questions'>{t('privacyPolicy')}</Link>
          </Panel>
          <Panel
            header={t('socialMedia').toUpperCase()}
            key='3'
            showArrow={false}
            className='social'
          >
            <Text style={{ marginTop: '16px' }}>
              <a
                rel='noreferrer'
                target='_blank'
                href='https://www.facebook.com/GafriGoldYerevan/?ref=pages_you_manage'
              >
                <i className='fa fa-facebook' aria-hidden='true'></i>
              </a>
              <a
                rel='noreferrer'
                target='_blank'
                href=' https://www.instagram.com/gafri_jewellery_yerevan/'
                style={{ marginLeft: '24px' }}
              >
                <i className='fa fa-instagram' aria-hidden='true'></i>
              </a>
              <a
                rel='noreferrer'
                target='_blank'
                href='https://vm.tiktok.com/ZSefmhCgp'
                style={{ marginLeft: '24px', display: 'inline-block' }}
              >
                <Img src={tikTok} width={15} className='tiktok' />
              </a>
              <a
                rel='noreferrer'
                target='_blank'
                href='https://youtube.com/channel/UCr7fHXcCAmt-FsLTHhKIZjg'
                style={{ marginLeft: '24px', display: 'inline-block' }}
              >
                <i class='fa fa-youtube-play'></i>
              </a>
            </Text>
            <br />
          </Panel>
        </CollapseComponent>
      </Col>
      <Col md={24} style={{ textAlign: 'center', marginBottom: '20px' }}>
        <Text level='1'> &#169; 2021 {t('allRightsReserved')}</Text>
        <br />
      </Col>
    </FooterWrapper>
  );
};

export default Footer;
