import React from 'react';
import { Form, Input, Button, Row, Col, Text, Span } from 'styled';
import { useRouterHistory } from 'hooks/routerHooks';
import { createAccountApi } from 'services/users';
import { useRequest } from 'hooks/useRequest';
import { useTranslation } from 'react-i18next';

const CreateAccount = () => {
  const [form] = Form.useForm();
  const history = useRouterHistory();
  const id = history.location.state.id;
  const handleSubmit = (form, makeRequest) => {
    form.validateFields().then((values) => {
      makeRequest({ ...values, id });
    });
  };

  const onSuccess = () => {
    history.push('/signin');
  };

  const { makeRequest } = useRequest({
    request: createAccountApi,
    initialData: {},
    onSuccess,
  });

  const { t } = useTranslation();

  return (
    <Row style={{ height: '100vh', marginTop: '150px' }}>
      <Form
        form={form}
        onFinish={() => handleSubmit(form, makeRequest)}
        className='login-form centered'
      >
        <Col span={24} style={{ textAlign: 'center' }}>
          <Span level={4} className='form-title'>
            {t('createAccount')}
          </Span>
        </Col>
        <Col>
          <Text level={2} className='please-text'>
            Please enter your password.
          </Text>
        </Col>
        <Form.Item
          name='password'
          rules={[
            {
              required: true,
              message: 'Please input your password!',
            },
          ]}
        >
          <Input.Password placeholder={t('password')} />
        </Form.Item>
        <Form.Item
          name='repeatPassword'
          dependencies={['newPassword']}
          rules={[
            {
              required: true,
              message: 'Please confirm your password!',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }

                return Promise.reject(
                  new Error('The two passwords that you entered do not match!')
                );
              },
            }),
          ]}
        >
          <Input.Password placeholder='Repeat Password' />
        </Form.Item>
        <Row style={{ textAlign: 'center' }}>
          <Col span={24}>
            <Text level={1}>
              I have read and agree with <b>Privacy policy</b> and{' '}
              <b>Terms & conditions.</b>
            </Text>
          </Col>
        </Row>
        <Form.Item>
          <Button
            type='submit'
            htmlType='submit'
            className='submit-button'
            block
          >
            SIGN UP
          </Button>
        </Form.Item>
      </Form>
    </Row>
  );
};

export default CreateAccount;
