import styled from 'styled-components';
import { Tag as tag } from 'antd';

const Tag = styled(tag)`
  height: 46px;
  font-size: 20px;
  border-radius: 23px;
  background-color: #F4EDE7;
  border-color: #F4EDE7;
  text-align: center;
  padding-top: 10px;
  margin-bottom: 10px;
  .ant-tag-close-icon {
    font-size: 20px;
  }
`;

/** @component */
export default Tag;
