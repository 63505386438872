import { BrowserRouter } from 'react-router-dom';
import AppRouter from './AppRouter';
import ScrollToTop from './Scroll';
import { Header } from './components/Header/index';
import Footer from './components/Footer/index';
import useGetRequest from 'hooks/useGetRequest';
import { getFavoritesApi } from 'services/fevorites';

const App = () => {
  const isSignInUser = !!window.localStorage.getItem('userInfo');

  const { data: response, reFetch } = useGetRequest({
    request: getFavoritesApi,
    initialData: {},
    condition: isSignInUser,
  });

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Header count={response?.data?.length} reFetch={reFetch} />
      <AppRouter count={response?.data?.length} reFetch={reFetch} />
      <Footer />
    </BrowserRouter>
  );
};

export default App;
