import { useState } from 'react';
import { Row, Col, GafriModal, Menu } from 'styled';
import { useRouterHistory, useRouterParams } from 'hooks/routerHooks';
import PersonalInformation from './PersonalInfo/PersonalInformation';
import Addresses from './Addresses';
import Cards from './Card/Cards';
import { Redirect } from 'react-router';
import { useTranslation } from 'react-i18next';

const GetMenu = ({ type }) => {
  const [visible, setVisible] = useState(false);
  const history = useRouterHistory();
  const { t } = useTranslation();
  return (
    <>
      <Menu
        style={{ backgroundColor: '#F4EDE7' }}
        selectedKeys={[type]}
        triggerSubMenuAction='click'
      >
        <Menu.Item
          key='profile'
          onClick={() => {
            history.push('/user/profile');
          }}
        >
          {t('personalInformation')}
        </Menu.Item>
        {/* <Menu.Item
          key='order'
          onClick={() => {
            history.push('/user/order');
          }}
        >
          Order
        </Menu.Item> */}
        <Menu.Item
          key='addresses'
          onClick={() => {
            history.push('/user/addresses');
          }}
        >
          {t('address')}
        </Menu.Item>
        {/* <Menu.Item
          key='cards'
          onClick={() => {
            history.push('/user/cards');
          }}
        >
          Cards
        </Menu.Item>  */}
        <Menu.Item
          key=' '
          onClick={() => {
            window.localStorage.removeItem('userInfo');
            history.push('/');
          }}
        >
          {t('signOut')}
        </Menu.Item>
      </Menu>
      <GafriModal
        visible={visible}
        onOk={() => {
          window.localStorage.removeItem('userInfo');
          setVisible(false);
          history.push('/');
        }}
        onCancel={() => setVisible(false)}
        title={t('logOut')}
        modalText={t('logOutFromYourAccount')}
      />
    </>
  );
};

const GetSection = ({ type }) => {
  switch (type) {
    case 'profile':
      return <PersonalInformation />;
    case 'order':
      return <> </>;
    case 'addresses':
      return <Addresses />;
    case 'cards':
      return <Cards />;
    default:
      return <Redirect to='/' />;
  }
};

const Profile = () => {
  const history = useRouterHistory();
  const { type } = useRouterParams();
  return (
    <Row style={{ marginTop: '150px', height: '100vh' }}>
      <Col xs={0} md={{ span: 4, offset: 1 }}>
        <GetMenu type={type} />
      </Col>
      <Col xs={{ span: 22, offset: 1 }} md={{ span: 17, offset: 1 }}>
        <GetSection type={type} history={history} />
      </Col>
    </Row>
  );
};

export default Profile;
