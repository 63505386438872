import React, { memo, useState, useEffect, useCallback, useMemo } from 'react';
import {
  Text,
  Col,
  Row,
  Input,
  Form,
  Paragraph,
  Card,
  Img,
  Button,
  Span,
} from 'styled';
import { Select } from 'antd';
import { useRouterHistory, useRouterParams } from 'hooks/routerHooks';
import { useRequest } from 'hooks/useRequest';
import { useGetRequest } from 'hooks/useGetRequest';
import { useTranslation } from 'react-i18next';
import CheckOutWrapper from './style';
import { Radio } from 'antd';
import { getDefaultAddressApi, profileApi } from 'services/profile';
import CheckOutModal from './CheckOutModal';
import cash from 'images/cash.svg';
import delivery from 'images/delivery.svg';
import store from 'images/In-store-pick-up.svg';
import { getDetalisApi } from 'services/fevorites';
import { createOrderApi } from 'services/product';
import SuccessfullyDoneModal from './SuccessfullyDoneModal';

const promotionPrice = (
  promotion = null,
  price = null,
  hasAdditionalProduct
) => {
  let promotionPrice = 0;
  if (!promotion) {
    return promotionPrice;
  }
  if (hasAdditionalProduct) {
    if (promotion.type === 1) {
      return (promotionPrice = +price + 8000 - promotion.amount);
    }
    let percent = ((+price + 8000) / 100) * promotion.amount;
    return (promotionPrice = price - percent);
  }

  if (promotion.type === 1) {
    promotionPrice = +price - promotion.amount;
  } else {
    let percent = (+price / 100) * promotion.amount;
    promotionPrice = price - percent;
  }
  return promotionPrice;
};

const Countries = [
  { country: 'Armenia', price: 0 },
  { country: 'United States', price: 32000 },
  { country: 'United Kingdom', price: 31000 },
  { country: 'Russia', price: 19000 },
  { country: 'Ukraine', price: 22000 },
  { country: 'Georgia', price: 22000 },
  { country: 'France', price: 31000 },
  { country: 'Germany', price: 31000 },
  { country: 'Spain', price: 31000 },
  { country: 'Other', price: 40000 },
];

const ChackOut = () => {
  const [form] = Form.useForm();
  const history = useRouterHistory();
  const { id } = useRouterParams();

  const [value, setValue] = useState(1);
  const [inArmenia, setInArmenia] = useState(null);
  const [shippingCountry, setShippingCountry] = useState('Armenia');

  const [openSuccessfullyModal, setOpenSuccessfullyModal] = useState(false);
  const [visible, setVisible] = useState(
    !window.localStorage.getItem('userInfo')
  );

  const hasAdditionalProduct = history.location?.state?.hasAdditionalProduct;
  const attributeId = history.location?.state?.attributeId;

  const { data: response } = useGetRequest({
    request: getDefaultAddressApi,
    initialData: { data: [] },
    condition: !visible,
  });

  const { data: productData } = useGetRequest({
    request: getDetalisApi,
    initialData: { data: { gallery: [] } },
    params: id,
    condition: !visible,
  });

  const onChange = (e) => {
    setValue(e.target.value);
    if (e.target.value === 2) {
      setShippingCountry('Armenia');
      setInArmenia(null);
    }
  };

  const onSelect = (e) => {
    form.setFieldsValue({ city: null });
    setInArmenia(null);
    setShippingCountry(e);
  };

  const handleSubmit = useCallback(
    (form, makeRequest) => {
      form.validateFields().then((values) => {
        makeRequest({
          ...values,
          productId: id,
          deliveryMethod: values.deliveryMethod ? values.deliveryMethod : 1,
          hasAdditionalProduct,
          country: values.otherCountry ? values.otherCountry : values.country,
          city: values.otherCity ? values.otherCity : values.city,
          attributeId,
        });
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id]
  );

  const onSuccess = useCallback(() => {
    setOpenSuccessfullyModal(true);
  }, []);

  const onClick = useCallback(() => {
    setOpenSuccessfullyModal(true);
    history.push(`/shop/product-details/${id}`);
  }, [history, setOpenSuccessfullyModal, id]);

  const { makeRequest, loading } = useRequest({
    request: createOrderApi,
    initialData: {},
    onSuccess,
  });

  const { data } = useGetRequest({
    request: profileApi,
    initialData: {},
    condition: !visible,
  });

  useEffect(() => {
    window.sessionStorage.removeItem('redirectUrl');
  }, []);

  useEffect(() => {
    const fullName = data?.data?.firstName
      ? `${data?.data?.firstName} ${data?.data?.lastName}`
      : null;
    form.setFieldsValue({
      ...response?.data,
      phoneNumber: data?.data?.phoneNumber,
      name: fullName,
      addressTitle: response?.data.title,
    });
  }, [response, form, data]);

  const { t } = useTranslation();

  const productDiscount = productData?.data?.promotion
    ? productData?.data?.price -
      promotionPrice(
        productData?.data?.promotion,
        productData?.data?.price,
        hasAdditionalProduct
      )
    : 0;

  const shippingData = Countries?.find((e) => e.country === shippingCountry);
  const showOtherCountry = shippingCountry === 'Other' && inArmenia !== 'Other';
  const showOtherCity = shippingCountry === 'Armenia' && inArmenia === 'Other';
  const shippingPrice = showOtherCity ? 1000 : shippingData?.price;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const totalPrice = useMemo(() => {
    let price = 0;
    if (
      productData?.data?.price &&
      (shippingData?.price || shippingData?.price === 0)
    ) {
      price = productData?.data?.price - productDiscount + shippingPrice;
    }
    return price;
  });

  const cityInput =
    shippingCountry === 'Armenia' ? (
      <Select
        onChange={(e) => {
          setInArmenia(e);
        }}
        placeholder={t('checkOut.enterCity')}
      >
        <Select.Option value='Yerevan'>Yerevan</Select.Option>
        <Select.Option value='Other'>Other</Select.Option>
      </Select>
    ) : (
      <Input placeholder={t('checkOut.enterCity')} />
    );

  return (
    <CheckOutWrapper>
      <Paragraph
        level={4}
        style={{ textAlign: 'center', MarginBottom: '30px' }}
      >
        {t('checkOut.paragraph').toUpperCase()}
      </Paragraph>
      <Row>
        <Col
          xs={{ span: 22, offset: 1 }}
          lg={{ span: 15, offset: 1 }}
          className='check-out-form'
        >
          <Form form={form}>
            <Row style={{ paddingBottom: '30px' }}>
              <Col span={24}>
                <Text>{t('checkOut.personalInfo').toUpperCase()}</Text>
              </Col>
            </Row>
            <Row>
              <Col xs={24} lg={11}>
                <Text className='label' level={1} type='secondary'>
                  {t('firstName')}*
                </Text>
                <Form.Item
                  name='name'
                  validateFirst={true}
                  validateTrigger='onSubmit'
                  rules={[
                    {
                      required: true,
                      message: 'Please input the Name',
                      whitespace: true,
                    },
                  ]}
                >
                  <Input placeholder={t('enterName')} />
                </Form.Item>
              </Col>
              <Col xs={24} lg={{ span: 11, offset: 2 }}>
                <Text className='label' level={1} type='secondary'>
                  {t('phoneNumber')}*
                </Text>
                <Form.Item
                  name='phoneNumber'
                  validateFirst={true}
                  validateTrigger='onSubmit'
                  rules={[
                    {
                      required: true,
                      message: 'Please input the phone number',
                      whitespace: true,
                    },
                  ]}
                >
                  <Input placeholder={t('enterPhoneNumber')} />
                </Form.Item>
              </Col>
            </Row>
            <Col xs={24} lg={11}>
              <Text className='label' level={1} type='secondary'>
                {t('email')}*
              </Text>
              <Form.Item
                name='email'
                validateFirst={true}
                validateTrigger='onSubmit'
                rules={[
                  {
                    required: true,
                    type: 'email',
                    message: 'The input is not valid E-mail!',
                  },
                ]}
              >
                <Input placeholder={t('enterEmailAddress')} />
              </Form.Item>
            </Col>
            <Row style={{ paddingBottom: '30px' }}>
              <Col span={24}>
                <Text>{t('checkOut.deliveryMethod').toUpperCase()}</Text>
              </Col>
            </Row>
            <Form.Item name='deliveryMethod'>
              <Radio.Group
                onChange={onChange}
                defaultValue={1}
                value={value}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Row>
                  <Col xs={24} lg={11} className='radio-input'>
                    <Radio value={1}>
                      <Text className='radio-button-text'>
                        <Img src={delivery} />
                        {t('checkOut.shipping')}
                      </Text>
                    </Radio>
                  </Col>
                  <Col xs={24} lg={{ span: 11, offset: 2 }}>
                    <Radio value={2}>
                      <Text className='radio-button-text'>
                        <Img src={store} className='in-store-icon' />
                        {t('checkOut.inStore')}
                      </Text>
                    </Radio>
                  </Col>
                </Row>
              </Radio.Group>
            </Form.Item>
            {value === 1 ? (
              <>
                <Col span={24}>
                  <Text className='label' level={1} type='secondary'>
                    {t('checkOut.title')}*
                  </Text>
                  <Form.Item
                    name='addressTitle'
                    validateFirst={true}
                    validateTrigger='onSubmit'
                    rules={[
                      {
                        required: true,
                        message: 'Please input the title',
                        whitespace: true,
                      },
                    ]}
                  >
                    <Input placeholder={t('checkOut.enterAddress')} />
                  </Form.Item>
                </Col>
                <Row>
                  <Col xs={24} lg={11}>
                    <Text className='label' level={1} type='secondary'>
                      {t('checkOut.country')}*
                    </Text>
                    <Form.Item
                      name='country'
                      validateFirst={true}
                      validateTrigger='onSubmit'
                      rules={[
                        {
                          required: true,
                          message: 'Please input the country',
                          whitespace: true,
                        },
                      ]}
                    >
                      <Select
                        onChange={onSelect}
                        placeholder={t('checkOut.enterCountry')}
                      >
                        {Countries.map((e) => {
                          return (
                            <Select.Option key={e.country} value={e.country}>
                              {e.country}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} lg={{ span: 11, offset: 2 }}>
                    <Text className='label' level={1} type='secondary'>
                      {t('checkOut.city')}*
                    </Text>
                    <Form.Item
                      name='city'
                      validateFirst={true}
                      validateTrigger='onSubmit'
                      rules={[
                        {
                          required: true,
                          message: 'Please input the city',
                          whitespace: true,
                        },
                      ]}
                    >
                      {cityInput}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  {showOtherCountry ? (
                    <Col xs={24} lg={11}>
                      <Text className='label' level={1} type='secondary'>
                        {t('checkOut.country')}*
                      </Text>
                      <Form.Item
                        name='otherCountry'
                        validateFirst={true}
                        validateTrigger='onSubmit'
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: 'Please input the country',
                          },
                        ]}
                      >
                        <Input placeholder={t('checkOut.enterCountry')} />
                      </Form.Item>
                    </Col>
                  ) : null}
                  {showOtherCity ? (
                    <>
                      <Col xs={24} lg={11}></Col>
                      <Col xs={24} lg={{ span: 11, offset: 2 }}>
                        <Text className='label' level={1} type='secondary'>
                          {t('checkOut.city')}*
                        </Text>
                        <Form.Item
                          name='otherCity'
                          validateFirst={true}
                          validateTrigger='onSubmit'
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message: 'Please input the country',
                            },
                          ]}
                        >
                          <Input placeholder={t('checkOut.enterCity')} />
                        </Form.Item>
                      </Col>
                    </>
                  ) : null}
                </Row>
                <Row>
                  <Col xs={24} lg={11}>
                    <Text className='label' level={1} type='secondary'>
                      {t('address')}*
                    </Text>

                    <Form.Item
                      name='fullAddress'
                      validateFirst={true}
                      validateTrigger='onSubmit'
                      rules={[
                        {
                          required: true,
                          message: 'Please input the addres',
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input placeholder={t('checkOut.enterAddress')} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} lg={{ span: 11, offset: 2 }}>
                    <Text className='label' level={1} type='secondary'>
                      {t('zipCode')}
                    </Text>
                    <Form.Item
                      name='zipCode'
                      validateFirst={true}
                      validateTrigger='onSubmit'
                      rules={[
                        {
                          required: true,
                          message: 'Please input the zip code',
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input placeholder={t('enterZipCode')} />
                    </Form.Item>
                  </Col>
                  <Form.Item>
                    <Row style={{ paddingBottom: '30px' }}>
                      <Col span={24}>
                        <Text>{t('checkOut.payment').toUpperCase()}</Text>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Radio value={1} checked={true} disabled={true}>
                          <Img src={cash} />
                          <Text>{t('checkOut.cash')}</Text>
                        </Radio>
                      </Col>
                    </Row>
                  </Form.Item>
                </Row>
              </>
            ) : null}
          </Form>
        </Col>
        <Col
          xs={{ span: 22, offset: 1 }}
          lg={{ span: 6, offset: 1 }}
          className='subtotal'
        >
          <Card>
            <Row>
              <Col span={12}>{t('checkOut.subtotal')}:</Col>
              <Col span={12} className='price'>
                <Span>{productData?.data?.price || 0} &#1423;</Span>
              </Col>
            </Row>
            {hasAdditionalProduct ? (
              <Row>
                <Col span={12}>{t('chain')}:</Col>
                <Col span={12} className='price'>
                  <Span>8000 &#1423;</Span>
                </Col>
              </Row>
            ) : null}
            <Row>
              <Col span={12}>{t('checkOut.discount')}:</Col>
              <Col span={12} className='price'>
                <Span>{productDiscount} &#1423;</Span>
              </Col>
            </Row>
            <Row>
              <Col span={12}>{t('checkOut.shipping')}:</Col>
              <Col span={12} className='price'>
                <Span>{shippingPrice}&#1423;</Span>
              </Col>
            </Row>
            <br />
            <Row>
              <Col span={12}>
                <Text level={2}>{t('checkOut.total')}:</Text>
              </Col>
              <Col span={12} className='price' style={{ padding: 0 }}>
                <Text level={2}>{totalPrice}&#1423;</Text>
              </Col>
            </Row>
          </Card>
          <Row>
            <Col span={24}>
              <Button
                type='submit'
                htmlType='submit'
                className='submit-button checkout-button'
                style={{ marginLeft: '16px' }}
                loading={loading}
                onClick={() => {
                  handleSubmit(form, makeRequest);
                }}
                block
              >
                {t('checkOut.completeTheOrder').toUpperCase()}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <CheckOutModal
        visible={visible}
        onOkSignIn={() => {
          window.sessionStorage.setItem(
            'redirectUrl',
            history.location.pathname
          );
          setVisible(false);
          history.push('/signIn', { hasAdditionalProduct, attributeId });
        }}
        onCancel={() => setVisible(false)}
      />
      <SuccessfullyDoneModal
        visible={openSuccessfullyModal}
        onOk={onClick}
        onCancel={onClick}
      />
    </CheckOutWrapper>
  );
};

export default memo(ChackOut);
