import styled from 'styled-components';

const BannerWrapper = styled.div`
  margin-top: 150px;
  padding: 0 80px;
  .breadcrumb {
    padding-bottom: 40px;
  }
  .about-top-img {
    width: 100%;
    height: 480px;
    object-fit: cover;
  }
  .title {
    color: #e3a876;
    font-weight: bold;
    font-size: 16px !important;
    cursor: pointer;
  }
  .conact-us-title {
    text-align: left;
    font: Futura;
    letter-spacing: 3.2px;
    color: #000000;
    text-transform: uppercase;
  }
  .additional-info {
    text-align: center;
    margin: 0;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
    color: #282828;
  }

  .footer-title {
    width: 100%;
    text-align: center;
    letter-spacing: 2px;
    font-size: 32px !important;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .footer-title-faq {
    text-align: center;
    justify-content: center;
    letter-spacing: 2px;
    font-size: 32px !important;
    font-weight: bold;
    margin: 0;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .ant-divider {
    border: 1px solid rgba(0, 0, 0, 0.5);
  }

  .banner {
    object-fit: cover;
    height: 450px;
    width: 100%;
  }

  .contact-image-div {
    background: linear-gradient(120deg, #dbd6d0, #fafafa);
  }
  .form-wrap {
    background: #f5f5f5;
    position: relative;
  }
  .form-row {
    height: 840px;
  }
  .contact-image-2 {
    position: absolute;
    top: 0;
    right: 0;
    height: 840px;
  }
  .faq {
    display: block;
    font-size: 32px !important;
    font-weight: bold;
    margin-top: 40px;
    text-align: center;
  }
  .contact-image-1 {
    display: none;
  }
  .contact-image-2 {
    display: block;
  }

  @media screen and (max-width: 992px) {
    padding: 0 60px;
  }

  @media screen and (max-width: 992px) {
    padding: 0 20px;
    .about-top-img {
      width: 100%;
      height: 340px;
    }
    .footer-title-faq {
      text-align: center;
      width: 450px;
      text-align: center;
      font-size: 22px !important;
      font-weight: normal;
      top: 30%;
    }
    .additional-info {
      text-align: center;
      font-weight: normal;
      width: 450px;
      top: 65%;
    }
  }

  @media screen and (max-width: 480px) {
    margin-top: 100px;
    padding: 0px;
    .about-top-img {
      width: 100%;
      height: 200px;
    }
    .breadcrumb {
      padding-bottom: 20px;
      margin-left: 20px;
      cursor: pointer;
    }
    .banner {
      object-fit: cover;
      height: 250px;
      width: 100%;
    }
    .footer-title {
      font-size: 24px !important;
    }
    .footer-title-faq {
      text-align: center;
      font-size: 22px !important;
      font-weight: normal;
      top: 20%;
    }
    .additional-info {
      text-align: center;
      font-weight: normal;
      width: 350px;
      top: 60%;
    }
    .faq {
      font-size: 24px !important;
    }

    .form-wrap {
      background: linear-gradient(120deg, #dbd6d0, #fafafa);
    }
    .contact-image {
      position: absolute;
      top: -370px;
      height: 400px;
      right: 39px;
      transform: rotate(12deg);
    }
    .contact-image-div {
      background: none;
    }
    .form-row {
      margin-bottom: 400px;
    }
    .contact-image-1 {
      display: block;
    }
    .contact-image-2 {
      display: none;
    }
  }
`;
export default BannerWrapper;
