import { Link } from 'react-router-dom';
import { Col, Divider } from 'antd';
import { Row, Img, Paragraph, Text, Span } from 'styled';
import aboutLeft from 'images/about-left.jpg';
import stonesTop from 'images/stones-top.jpg';
import aboutRight from 'images/about-right.png';
import BannerWrapper from '../BannerWrapper/index';
import AboutWrapper from './styled/AboutWrapper';
import { Fragment } from 'react';

//language
import { useTranslation } from 'react-i18next';

const About = () => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <BannerWrapper>
        <Row>
          <Col span={20} className='breadcrumb'>
            <Link to='/'>
              <Text level='1' weight='bold'>
                {t('home').toUpperCase()}
              </Text>
            </Link>
            <Divider type='vertical' />
            <Text className='title'>{t('aboutUs.title')}</Text>
          </Col>
          <Col span={24}>
            <Img src={stonesTop} className='about-top-img' />
            <Paragraph className='footer-title'>{t('aboutUs.title')}</Paragraph>
          </Col>
        </Row>
      </BannerWrapper>
      <AboutWrapper>
        <Row className='about-section'>
          <Col
            xs={{ span: 22, offset: 1 }}
            xl={{ span: 7, offset: 1 }}
            xxl={{ span: 7, offset: 1 }}
          >
            <Img src={aboutLeft} className='about-left-img center' />
          </Col>
          <Col
            xs={{ span: 21, offset: 1 }}
            xl={{ span: 8, offset: 1 }}
            xxl={{ span: 8, offset: 1 }}
            className='gafri-about-text'
          >
            <Span>
              <Paragraph level='4' className='text-2'>
                GAFRI JEWELLERY
              </Paragraph>
              {t('aboutUs.section_1')}
            </Span>
          </Col>
          <Col
            xs={{ span: 22, offset: 1 }}
            xl={{ span: 4, offset: 1 }}
            xxl={{ span: 6, offset: 1 }}
            className='gafri-about-text'
          >
            <Img src={aboutRight} className='about-right-img center' />
          </Col>
        </Row>
        <Row>
          <Col span={22} offset={1} className='gafri-about-text'>
            <Span>{t('aboutUs.section_2.text')}</Span>
            <br />
            <br />
            <Span>{t('aboutUs.section_2.production')}</Span>
            <br />
            <br />
            <Row>
              <Col offset={1} span={23}>
                <ul>
                  <li>{t('aboutUs.section_2.1')}</li>
                  <li>{t('aboutUs.section_2.2')}</li>
                  <li>{t('aboutUs.section_2.3')}</li>
                  <li>{t('aboutUs.section_2.4')}</li>
                  <li>{t('aboutUs.section_2.5')}</li>
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
      </AboutWrapper>
    </Fragment>
  );
};
export default About;
