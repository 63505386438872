import { Row } from 'styled';
import styled from 'styled-components';

const ProductWrapper = styled(Row)`
  font-family: 'Roboto';
  margin-top: 120px;
  .ant-collapse {
    margin-bottom: 25px;
    .ant-collapse-header {
      padding-left: 0;
    }
  }
  .diamond-slider {
    margin-top: 40px;
    .diamond {
      color: #000000;
      text-align: left;
      margin-bottom: 40px;
    }
    .slider-name {
      color: #363538 !important;
      text-align: left;
      display: inline-block;
      font-family: Roboto-Medium !important;
    }

    @media screen and (max-width: 1200px) {
      margin-top: 30px;
      .diamond {
        margin-bottom: 30px;
      }
    }
    @media screen and (max-width: 992px) {
      margin-top: 20px;
      .diamond {
        margin-bottom: 20px;
      }
    }
  }

  .ant-card {
    border: none;
    margin: 30px;
    @media screen and (max-width: 480px) {
      margin: 20px;
    }
  }
  .ant-card-head {
    display: none;
  }
  .ant-card-body {
    text-align: center;
    padding: 0px;
  }
  .measurements {
    .slick-track {
      width: 100% !important;
    }
  }
  .ant-collapse {
    .ant-collapse-header {
      background: #ffffff;
    }
    .ant-collapse-item {
      border: none;
    }
  }
  .details-price {
    padding-top: 20px;
    padding-bottom: 25px;
  }
  .product-details-price {
    font-size: 30px;
    font-family: Roboto-Medium;
    color: #000000;

    &.line-through {
      color: grey;
    }
  }
  Button {
    &.quick-purchase {
      margin: 0 !important;
    }
    &.heart-icon {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 5px;
      right: 5px;
      z-index: 10;
      @media screen and (max-width: 480px) {
        width: 25px;
        height: 25px;
      }
    }
    &.border {
      border: 1px solid #3f2f1e !important;
    }
  }
  .breadcrumb {
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
  }
  .product-name {
    color: #e3a876;
    opacity: 40%;
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
  }
  .images > img {
    max-width: 120px !important;
    height: 140px;
    margin-top: 30px;
  }
  .product {
    text-align: center;
  }
  .product-code {
    font-size: 18px !important;
    color: #363538 !important;
  }
  .color-section {
    .ant-row {
      margin: 20px 0;
    }
  }
  .desc {
    background: #f4ede7;
    padding: 12px 24px;
    margin-top: 40px;
  }
  .slick-initialized .slick-slide {
    text-align: center;
    cursor: pointer;
  }
  .best-product {
    .slick-prev {
      left: 4% !important;
      width: 60px;
      height: 60px;
      z-index: 10;
      :before {
        font-size: 60px;
        color: black;
      }
    }
    .slick-next {
      right: 6% !important;
      width: 60px;
      height: 60px;
      z-index: 10;
      :before {
        font-size: 60px;
        color: black;
      }
    }
  }
  .noMargin {
    margin-bottom: 10px;
  }
  .product-count {
    border: 1px solid #3f2f1e33;
    display: inline-block;
    button {
      text-align: center;
      width: 50px;
      height: 56px;
      background: transparent;
      border: none;
      @media only screen and (max-width: 600px) {
        width: 30px;
        font-size: 12px;
        height: 40px;
      }
    }
  }
  @media screen and (min-width: 1500px) {
    .images > img {
      width: 140px;
      height: 140px;
      margin-top: 30px;
    }
    .default-image {
      max-width: 450px;
      height: 450px;
      margin: 30px auto;
    }
  }
  @media screen and (max-width: 1500px) {
    .desc {
      padding: 12px 7px;
    }
    .default-image {
      max-width: 400px;
      height: 400px;
      margin: 30px auto;
    }
    .images > img {
      width: 120px;
      height: 140px;
      margin-top: 30px;
    }
    .text {
      font-size: 50px !important;
    }
  }
  @media screen and (max-width: 1300px) {
    .product-info {
      padding-left: 50px;
    }
  }
  @media screen and (max-width: 1199px) {
    .padding {
      padding-bottom: 15px;
    }
    .product-info {
      padding-left: 0;
    }
    .text {
      font-size: 36px !important;
      width: 100%;
      margin-left: 10px;
    }
    .images > img {
      width: 100px;
      height: 100px;
      margin-top: 30px;
    }
    .default-image {
      max-width: 400px;
      height: 400px;
      margin: 35px auto;
    }
    .images {
      display: flex;
    }
  }
  @media screen and (max-width: 992px) {
    .slick-next,
    .slick-prev {
      :before {
        display: none;
      }
    }
    .padding {
      padding-bottom: 30px;
    }
    .product-details-title,
    .product-details-price {
      font-size: 26px !important;
    }

    .product-code {
      font-size: 14px !important;
    }
  }
  @media screen and (max-width: 480px) {
    margin-top: 100px;
    margin-left: 0px;
    .breadcrumb-section {
      margin-left: 20px;
    }
    .breadcrumb {
      font-weight: normal;
    }
    .default-image {
      height: 300px;
      max-width: 100% !important;
      margin-top: 15px;
      object-fit: cover;
    }
    /* .slick-track {
      width: auto !important;
    } */

    img {
      background-color: #fff;
    }
    .text {
      font-size: 24px !important;
      width: 100%;
      margin-left: 10px;
    }
    .product-info {
      margin-top: 50px;
    }
    .desc {
      margin-top: 20px;
    }
    .noMargin {
      margin: 0px;
    }
  }
`;
export default ProductWrapper;
