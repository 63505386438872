import React, { useEffect } from 'react';
import { Form, Input, Button, Row, Col, Text, Card } from 'styled';
// import Paragraph from "styled/Paragraph";
import { Divider, DatePicker } from 'antd';
import ChangPassword from './ChengPassword';
import useGetRequest from 'hooks/useGetRequest';
import useRequest from 'hooks/useRequest';
import { updatProfileApi, profileApi } from 'services/profile';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const PersonalInformation = () => {
  const [form] = Form.useForm();

  const handleSubmit = (form, makeRequest) => {
    form.validateFields().then((values) => {
      makeRequest(values);
    });
  };

  const { makeRequest } = useRequest({
    request: updatProfileApi,
  });

  const { data: response } = useGetRequest({
    request: profileApi,
    initialData: {},
  });

  useEffect(() => {
    if (response) {
      const data = {
        ...response?.data,
        birthDate: response?.data?.birthDate
          ? moment(response?.data?.birthDate)
          : '',
      };
      form.setFieldsValue(data);
    }
  }, [response, form]);

  const { t } = useTranslation();

  return (
    <>
      <Card title={t('personalInformation')} className='gafri-card'>
        <Row>
          <Col span={24} style={{ marginTop: '20px', marginBottom: '30px' }}>
            {t('signInInformation')}
          </Col>
          <Col span={24} style={{ marginBottom: '20px' }}>
            {response?.data?.email}
          </Col>
          <Divider style={{ background: '#F4EDE7', margin: 0 }} />
          <Col span={24} style={{ marginTop: '20px' }}>
            <Text level={2}>{t('personalInformation')}</Text>
            <Form
              layout='inline'
              form={form}
              onFinish={() => handleSubmit(form, makeRequest)}
              className='profile-form '
            >
              <Col xs={24} md={12} className='profile-form-label'>
                <Text level={1} type='secondary'>
                  {t('firstName')}*
                </Text>
                <Form.Item
                  name='firstName'
                  validateFirst={true}
                  validateTrigger='onSubmit'
                  rules={[
                    {
                      required: true,
                      message: 'Please input the first name',
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} className='profile-form-label'>
                <Text level={1} type='secondary'>
                  {t('lastName')}*
                </Text>
                <Form.Item
                  name='lastName'
                  validateFirst={true}
                  validateTrigger='onSubmit'
                  rules={[
                    {
                      required: true,
                      message: 'Please input the last name',
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} className='profile-form-label'>
                <Text level={1} type='secondary'>
                  {t('dateOfbirth')}
                </Text>
                <Form.Item
                  name='birthDate'
                  validateFirst={true}
                  validateTrigger='onSubmit'
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <DatePicker
                    style={{ width: '100%' }}
                    format='DD/MM/YYYY'
                    // placeholder=''
                    disabledAfter
                    allowClear={false}
                    className='date-of-birth-input'
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} className='profile-form-label'>
                <Text level={1} type='secondary'>
                  {t('phoneNumber')}*
                </Text>
                <Form.Item
                  name='phoneNumber'
                  validateFirst={true}
                  validateTrigger='onSubmit'
                  rules={[
                    {
                      required: true,
                      message: 'Please input the Phone number',
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Form.Item>
                <Button
                  type='submit'
                  htmlType='submit'
                  className='submit-button'
                >
                  {t('save').toUpperCase()}
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Card>
      <ChangPassword />
    </>
  );
};

export default PersonalInformation;
