import styled from 'styled-components';
import { Slider as slider } from 'antd';

const Slider = styled(slider)`
.ant-slider-mark-text > .ant-typography{
    font-size: 16px;
    width: 100%;
  }
  .ant-slider-mark-text {
    width: 100%;
  }
  .ant-slider-handle{
    background-color: #eecbad !important;
    border-color: #eecbad !important;
    width: 12px;
    height: 12px;
  }
  .ant-slider-handle:hover {
    background-color: #eecbad !important;
    border-color: #eecbad !important;
  }
  .ant-slider-handle:focus {
    box-shadow: none;
  }
  .ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open){
    background-color: #eecbad !important;
    border-color: #eecbad !important;
  }
  .ant-slider-track {
    background-color: #eecbad !important;
  }
  .ant-slider:hover .ant-slider-track {
    background-color: #eecbad !important;
  }
  .ant-slider-dot{
    width: 12px;
    height: 12px;
    background-color: #eecbad !important;
    border-color: #eecbad !important;
  }`;

/** @component */
export default Slider;
