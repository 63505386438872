import styled from 'styled-components';

const Img = styled.img`
  display: block;
  max-width: 100%;
  &.tiktok {
    svg {
      path {
        fill: #ffffff;
      }
    }
    svg:hover {
      fill: red;
    }
  }
`;

export default Img;
