import styled from 'styled-components';
import useRequest from 'hooks/useRequest';
import BackAroww from 'images/back-arrow.svg';

import { createAddressApi, setAsDefaultApi } from 'services/profile';
import {
  Form,
  Input,
  Col,
  Text,
  Card,
  Row,
  Button,
  Span,
  Checkbox,
} from 'styled';
import { useTranslation } from 'react-i18next';

const AddNewAddress = ({ setIsAddAddres }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const Image = styled.img`
    display: block;
    width: 60px;
    height: 30px;
  `;

  const title = (
    <Span>
      <Button
        className='no-decoration-button'
        type='link'
        onClick={() => {
          setIsAddAddres((item) => !item);
        }}
      >
        <Image src={BackAroww} style={{ display: 'inline-block' }} />
        <Span>{t('addNewAddress')}</Span>
      </Button>
    </Span>
  );

  const handleSubmit = (form, makeRequest) => {
    form.validateFields().then((values) => {
      makeRequest(values);
    });
  };

  const onSuccess = (data) => {
    const isDefault = form.getFieldValue('isDefault');
    setIsAddAddres((item) => !item);
    if (isDefault) setAsDefaultApi({ id: data.data });
  };

  const { makeRequest } = useRequest({
    request: createAddressApi,
    initialData: {},
    onSuccess,
  });

  return (
    <Card title={title} className='gafri-card'>
      <Row>
        <Form
          layout='inline'
          form={form}
          onFinish={() => {
            handleSubmit(form, makeRequest);
          }}
          className='profile-form'
        >
          <Col span={24} className='profile-form-label'>
            <Text level={1} type='secondary'>
              {t('checkOut.title')}
            </Text>
            <Form.Item
              name='title'
              validateFirst={true}
              validateTrigger='onSubmit'
              rules={[
                {
                  required: true,
                  message: 'Please input the Email',
                  whitespace: true,
                },
              ]}
            >
              <Input placeholder={t('checkOut.enterAddress')} />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} className='profile-form-label'>
            <Text level={1} type='secondary'>
              {t('checkOut.country')}*
            </Text>
            <Form.Item
              name='country'
              validateFirst={true}
              validateTrigger='onSubmit'
              rules={[
                {
                  required: true,
                  message: 'Please input the country',
                  whitespace: true,
                },
              ]}
            >
              <Input placeholder={t('checkOut.enterCountry')} />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} className='profile-form-label'>
            <Text level={1} type='secondary'>
              {t('checkOut.city')}*
            </Text>
            <Form.Item
              name='city'
              validateFirst={true}
              validateTrigger='onSubmit'
              rules={[
                {
                  required: true,
                  message: 'Please input the city',
                  whitespace: true,
                },
              ]}
            >
              <Input placeholder={t('checkOut.enterCity')} />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} className='profile-form-label'>
            <Text level={1} type='secondary'>
              {t('address')}*
            </Text>
            <Form.Item
              name='fullAddress'
              validateFirst={true}
              validateTrigger='onSubmit'
              rules={[
                {
                  required: true,
                  message: 'Please input the Email',
                  whitespace: true,
                },
              ]}
            >
              <Input placeholder={t('checkOut.enterAddress')} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} className='profile-form-label'>
            <Text level={1} type='secondary'>
              {t('zipCode')}*
            </Text>
            <Form.Item
              name='zipCode'
              validateFirst={true}
              validateTrigger='onSubmit'
              rules={[
                {
                  required: true,
                  message: 'Please input the zip code',
                  whitespace: true,
                },
              ]}
            >
              <Input placeholder={t('checkOut.enterZipCode')} />
            </Form.Item>
          </Col>
          <Col span={24} className='profile-form-label'>
            <Form.Item name='isDefault' valuePropName='checked'>
              <Checkbox>
                <Row justify='space-between' style={{ width: '85%' }}>
                  <Text>{t('setAsDefaultAddress')}</Text>
                </Row>
              </Checkbox>
            </Form.Item>
          </Col>
          <Button type='submit' htmlType='submit' className='submit-button'>
            {t('save')}
          </Button>
        </Form>
      </Row>
    </Card>
  );
};

export default AddNewAddress;
