import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 25px;
  .ant-card {
    border: none;
    margin: 30px;
    @media screen and (max-width: 480px) {
      margin: 20px;
    }
  }
  button {
    &.heart-icon {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 5px;
      right: 5px;
      z-index: 10;
      @media screen and (max-width: 480px) {
        width: 25px;
        height: 25px;
      }
    }
  }
  .ant-card-head {
    display: none;
  }
  .ant-card-body {
    text-align: center;
    padding: 0px;
  }
  .product-list-item {
    text-align: center;
    justify-content: center;
  }

  .prod-name {
    font-size: 24px;
    margin-top: 40px;
    font-weight: normal;
  }
  .price {
    font-size: 24px;
    font-weight: bold;
  }
  .tag {
    position: absolute;
    top: 5px;
    left: 5px;
    z-index: 10;
    @media screen and (max-width: 480px) {
      font-size: 8px;
    }
  }
  @media screen and (max-width: 480px) {
    margin-top: 0px;
    padding-bottom: 70px;
    .ant-card {
      margin: 15px;
      border: none;
    }
    .prod-name {
      font-size: 20px;
      margin-top: 20px;
    }
    .price {
      font-size: 18px;
      display: block;
    }
  }
`;
export default Wrapper;
