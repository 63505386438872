import { useState, useEffect, memo, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Col, Collapse, Divider, Spin } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { Row, Paragraph, Img, Text, Button, Card, Span } from 'styled';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useRouterHistory, useRouterParams } from 'hooks/routerHooks';
import { useGetRequest } from 'hooks/useGetRequest';
import { productBestSellersGet } from 'services/product';
import { addRemoveFromFavoritesApi, getDetalisApi } from 'services/fevorites';

//img
import HeartFull from 'images/heartFull.svg';
import Heart from 'images/heart.svg';
import Chain from 'images/chain.png';
import Price from 'components/Shop/Price';
import useRequest from 'hooks/useRequest';
import ProductWrapper from './style/ProductWrapper';
import InfoSection from '../Homepage/InfoSection/index';
import { useTranslation } from 'react-i18next';

const ProductDetails = ({ reFetchHeaderCount }) => {
  const { id } = useRouterParams();
  const [gallery, setGallery] = useState([]);
  const history = useRouterHistory();
  const [image, setImage] = useState(null);
  const [isActive, setIsActive] = useState(null);
  const [hasAdditionalProduct, setHasAdditionalProduct] = useState(false);
  const [attributeId, setAttributeId] = useState(null);
  const { t } = useTranslation();

  const { data, reFetch: reFetchProductBest } = useGetRequest({
    request: productBestSellersGet,
    initialData: { data: [] },
  });

  const {
    data: response,
    loading,
    reFetch: reFetchDetalis,
  } = useGetRequest({
    request: getDetalisApi,
    initialData: { data: { gallery: [] } },
    params: id,
  });

  const diamondData = [
    response?.data?.roundDiamond,
    response?.data?.marquiseDiamond,
    response.data.stoneQuantity,
    response.data.totalWeight,
    response.data.chainLength,
  ].filter((e) => e !== '0');

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4.5,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 2,
        },
      },
    ],
  };

  const paramsSettings = {
    ...settings,
    dots: false,
    infinite: true,
    slidesToShow: diamondData.length || 1,
    slidesToScroll: 5,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: diamondData.length > 3.5 ? 3.5 : diamondData.length,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: diamondData.length > 2.5 ? 2.5 : diamondData.length,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: diamondData.length > 2.5 ? 2.5 : diamondData.length,
          slidesToScroll: 2,
        },
      },
    ],
  };

  const attributes = response?.data?.attributes || [];
  const categoryId = response?.data?.category?.id;
  const metalId = response?.data?.metal?.id;
  const colapseHeader = useMemo(() => {
    return (
      <Text>
        {t('additionalChain').toUpperCase()}{' '}
        {isActive ? <MinusOutlined /> : <PlusOutlined />}
      </Text>
    );
  }, [isActive, t]);

  const { makeRequest } = useRequest({
    request: addRemoveFromFavoritesApi,
    initialData: {},
    onSuccess: () => {
      reFetchHeaderCount();
      reFetchProductBest();
      reFetchDetalis();
    },
  });

  useEffect(() => {
    setGallery([
      ...response?.data?.gallery,
      response?.data?.defaultImage,
      response?.data?.secondaryImage,
    ]);
  }, [response]);

  useEffect(() => {
    setImage(response.data.defaultImage);
  }, [response]);

  return (
    <Spin spinning={loading}>
      <Row>
        <Col span={22} offset={1}>
          <ProductWrapper>
            <Col md={24} className='breadcrumb-section'>
              <Link to='/'>
                <Text className='breadcrumb'>{t('home').toUpperCase()}</Text>
              </Link>
              <Divider type='vertical' />
              <Text
                className='breadcrumb'
                onClick={() => {
                  window.localStorage.setItem('tag', JSON.stringify([]));
                  history.push(
                    `/shop?metalId=${
                      response?.data?.metal?.id
                    }&key=${response?.data?.metal?.title.toLowerCase()}&page=1`
                  );
                }}
              >
                {response?.data?.metal?.title}
              </Text>
              <Divider type='vertical' />
              <Text className='product-name'>
                {response.data?.title?.toUpperCase()}
              </Text>
            </Col>
            <Col lg={12} xs={24} className='padding'>
              <Row>
                <Col
                  md={{ span: 4, order: 2 }}
                  xs={{ span: 24, order: 2 }}
                  className='images'
                >
                  {gallery?.map((imageSrc, index) => {
                    return (
                      <Img
                        key={index}
                        src={imageSrc}
                        style={{}}
                        onClick={() => setImage(imageSrc)}
                      />
                    );
                  })}
                </Col>
                <Col
                  xl={{ span: 13, offset: 4, order: 2 }}
                  xs={{ span: 24, order: 1 }}
                >
                  <Img
                    src={image || response.data.defaultImage}
                    className='default-image'
                  />
                </Col>
              </Row>
            </Col>
            <Col lg={12} xs={24} className='product-info'>
              {response.data.isFavorite}
              <Button
                type='link'
                className='no-decoration-button heart-icon'
                style={{ top: 16 }}
                onClick={(e) => {
                  e.stopPropagation();
                  makeRequest({ id: response.data.id });
                }}
              >
                <Img src={response.data.isFavorite ? HeartFull : Heart} />
              </Button>
              <Paragraph
                level='4'
                className='noMargin product-details-title'
                weight='100'
              >
                {response.data.title}
              </Paragraph>
              <Text className='product-code'>
                {t('sku').toUpperCase()}: {response.data.productCode}
              </Text>
              <Row className='details-price'>
                <Col>
                  <Price
                    price={response.data.price}
                    promotion={response.data.promotion}
                    productDetails={true}
                  />
                </Col>
              </Row>
              <Text>{response.data.description}</Text>
              <Row justify='space-between' className='diamond-slider'>
                <Col xs={24}>
                  <Slider {...paramsSettings} className='measurements'>
                    {response.data.roundDiamond &&
                    response.data.roundDiamond !== '0' ? (
                      <Col>
                        <Paragraph level='1' className='slider-name'>
                          {t('sterlingSilver')}:
                        </Paragraph>
                        <Paragraph weight='bold' level='3' className='diamond'>
                          {response.data.roundDiamond}
                        </Paragraph>
                      </Col>
                    ) : null}
                    {response.data.marquiseDiamond &&
                    response.data.marquiseDiamond !== '0' ? (
                      <Col>
                        <Paragraph level='1' className='slider-name'>
                          {t('whiteGold')}:
                        </Paragraph>
                        <Paragraph weight='bold' level='3' className='diamond'>
                          {response.data.marquiseDiamond}K
                        </Paragraph>
                      </Col>
                    ) : null}
                    {response.data.stoneQuantity &&
                    response.data.stoneQuantity !== '0' ? (
                      <Col>
                        <Paragraph level='1' className='slider-name'>
                          {t('yellowGold')}:
                        </Paragraph>
                        <Paragraph weight='bold' level='3' className='diamond'>
                          {response.data.stoneQuantity}
                        </Paragraph>
                      </Col>
                    ) : null}
                    {response.data.totalWeight &&
                    response.data.totalWeight !== '0' ? (
                      <Col>
                        <Paragraph level='1' className='slider-name'>
                          Total <br /> weight:
                        </Paragraph>
                        <Paragraph weight='bold' level='3' className='diamond'>
                          {response.data.totalWeight}
                          {t('g').toUpperCase()}
                        </Paragraph>
                      </Col>
                    ) : null}
                    {response.data.chainLength &&
                    response.data.chainLength !== '0' ? (
                      <Col>
                        <Paragraph level='1' className='slider-name'>
                          Chain <br /> length:
                        </Paragraph>
                        <Paragraph weight='bold' level='3' className='diamond'>
                          {response.data.chainLength}IN
                        </Paragraph>
                      </Col>
                    ) : null}
                  </Slider>
                </Col>
                {/* <Col span={24}>
                <RingSize />
              </Col> */}
              </Row>
              <Row>
                {/* <Col span={24}>
                  <Paragraph weight='bold'>QUANTITY</Paragraph>
                </Col>
                <Col span={24}>
                  <div className='product-count'>
                    <Button
                      type='link'
                      className='no-decoration-button'
                      disabled={count <= 1}
                      onClick={() => {
                        setCount((item) => item - 1);
                      }}
                    >
                      -
                    </Button>
                    <Button type='link' className='no-decoration-button'>
                      {count}
                    </Button>
                    <Button
                      type='link'
                      className='no-decoration-button'
                      onClick={() => {
                        setCount((item) => item + 1);
                      }}
                    >
                      +
                    </Button>
                  </div>
                  <Button
                    type='submit'
                    className='submit-button'
                    style={{ marginLeft: '16px' }}
                  >
                    ADD TO BAG
                  </Button>
                </Col> */}
                {attributes[0] ? (
                  <Col span={24} className='color-section'>
                    <Text>{t('color').toUpperCase()}</Text>
                    <Row>
                      {attributes.map((e) => (
                        <Col
                          key={e.id}
                          onClick={() => {
                            setAttributeId(e.id);
                          }}
                          style={{
                            width: '40px',
                            height: '40px',
                            borderRadius: '50%',
                            backgroundColor: e.colorHex,
                            marginRight: '10px',
                            cursor: 'pointer',
                            border:
                              attributeId === e.id
                                ? '1px solid black'
                                : 'unset',
                          }}
                        ></Col>
                      ))}
                    </Row>
                  </Col>
                ) : null}
                <Col span={24}>
                  {categoryId === 2 && metalId !== 1 ? (
                    <Collapse
                      bordered={false}
                      showArrow={false}
                      expandIcon={({ isActive }) => {
                        setIsActive(isActive);
                      }}
                    >
                      <Collapse.Panel header={colapseHeader}>
                        <Row>
                          <Col>
                            <div
                              style={{
                                border: '2px solid rgb(0,0,0, 0.25)',
                              }}
                            >
                              <Img height={100} width={80} src={Chain} />
                            </div>
                          </Col>
                          <Col offset={1}>
                            <Text level={2}>{t('chain')}</Text>
                            <br />
                            <Text level={1}>8000 &#1423;</Text>
                            <br />
                            <br />
                            <Span
                              style={{
                                borderBottom: '1px solid black',
                                cursor: 'pointer',
                              }}
                              onClick={() =>
                                setHasAdditionalProduct((item) => !item)
                              }
                            >
                              {hasAdditionalProduct
                                ? t('remove').toUpperCase()
                                : t('add').toUpperCase()}
                            </Span>
                          </Col>
                        </Row>
                      </Collapse.Panel>
                    </Collapse>
                  ) : null}
                </Col>
                <Col span={24}>
                  <Button
                    type='submit'
                    className='submit-button quick-purchase'
                    style={{ marginLeft: '16px' }}
                    onClick={() =>
                      history.push(`/checkOut/${id}`, {
                        hasAdditionalProduct,
                        attributeId,
                      })
                    }
                  >
                    {t('quickPurchase').toUpperCase()}
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col md={24} style={{ marginTop: '50px' }} className='best-product'>
              <Paragraph level='4'>
                {t('youMayAlsoLinke').toUpperCase()}
              </Paragraph>
              <Slider {...settings}>
                {data && data.data && data.data.length
                  ? data.data.map((item) => (
                      <Card key={item.id}>
                        <Button
                          type='link'
                          className='no-decoration-button heart-icon'
                          onClick={(e) => {
                            e.stopPropagation();
                            makeRequest({ id: item.id });
                          }}
                        >
                          <Img src={item.isFavorite ? HeartFull : Heart} />
                        </Button>
                        <Link to={`/shop/product-details/${item.id}`}>
                          <Img
                            src={item.defaultImage}
                            onMouseOver={(e) => {
                              e.currentTarget.src = item.secondaryImage;
                            }}
                            onMouseOut={(e) => {
                              e.currentTarget.src = item.defaultImage;
                            }}
                            className='img'
                            style={{
                              display: 'inline-block',
                              position: 'relative',
                            }}
                          />
                          <Paragraph className='prod-name' $noMargin>
                            {item.title}
                          </Paragraph>
                          <Price
                            price={item.price}
                            promotion={item.promotion}
                          />
                        </Link>
                      </Card>
                    ))
                  : null}
              </Slider>
            </Col>
          </ProductWrapper>
          <InfoSection />
        </Col>
      </Row>
    </Spin>
  );
};
export default memo(ProductDetails);
