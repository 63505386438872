import { Menu as menu } from 'antd';
import styled from 'styled-components';

const Menu = styled(menu)`
  li:hover {
    color: unset !important;
  }
  .ant-menu-item-selected {
    color: unset;
    background: #f4ede7 !important;
    font-weight: bold;
  }

  &.gafri-user-menu {
    ul {
      z-index: 9999 !important;
    }
    .ant-menu-item:not(:last-child) {
      margin: 0;
    }
    li {
      margin: 0;
      height: 30px;
      &.ant-menu-item-selected {
        color: unset;
        background: unset !important;
        font-weight: normal;
      }
    }
    .signOut {
      font-weight: bold;
    }
  }
`;
export default Menu;
