import { Link } from 'react-router-dom';
import { Col, Divider, Collapse } from 'antd';
import { Row, Img, Paragraph, Text } from 'styled';
import banner_cropped from 'images/banner_cropped.png';
import BannerWrapper from '../BannerWrapper/index';
import styled from 'styled-components';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { useGetRequest } from 'hooks/useGetRequest';
import { questions } from 'services/faq';

//language
import { useTranslation } from 'react-i18next';

const { Panel } = Collapse;

const CollapseComponent = styled(Collapse)`
  background-color: #fbf8f5;
  font-weight: bold;

  .ant-collapse-header > span {
    vertical-align: middle;
  }
  .ant-collapse-content-box {
    margin-left: 24px;
  }
  .ant-collapse-item {
    border-bottom: 20px solid white;
    font-size: 22px;
  }
  .ant-collapse-content-box > p {
    font-weight: normal !important;
    font-size: 20px;
  }
  svg {
    font-weight: bold;
    font-size: 22px;
  }
  @media screen and (max-width: 480px) {
    margin-bottom: 40px;
  }
`;

const FAQ = () => {
  const { data } = useGetRequest({
    request: questions,
    initialData: { data: [] },
  });
  const { t } = useTranslation();

  return (
    <BannerWrapper>
      <Row>
        <Col md={24} className='breadcrumb'>
          <Link to='/'>
            <Text level='1' weight='bold'>
              {t('home').toUpperCase()}
            </Text>
          </Link>
          <Divider type='vertical' />
          <Text className='title'>{t('faq').toUpperCase()}</Text>
        </Col>
        <Col md={24}>
          <Img src={banner_cropped} className='banner' />
          <Paragraph className='footer-title-faq'>
            {t('weAraHere').toUpperCase()}
          </Paragraph>
          <Paragraph className='additional-info'>
            {t('answer')} info@gafri.am!
          </Paragraph>
        </Col>
      </Row>
      <Row justify='center'>
        <Col md={24}>
          <Text className='faq'>{t('asked')}</Text>
        </Col>
        <Col md={12} xs={20}>
          <CollapseComponent
            bordered={false}
            defaultActiveKey={['0']}
            expandIcon={({ isActive }) =>
              isActive ? <MinusOutlined /> : <PlusOutlined />
            }
          >
            {data?.data?.map((item, index) => (
              <Panel header={item.question} key={index}>
                <p>{item.answer}</p>
              </Panel>
            ))}
          </CollapseComponent>
        </Col>
      </Row>
    </BannerWrapper>
  );
};
export default FAQ;
