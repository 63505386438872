import { useEffect } from 'react';
import styled from 'styled-components';
import useRequest from 'hooks/useRequest';
import { updatAddressApi } from 'services/profile';
import {
  Form,
  Input,
  Col,
  Text,
  Card,
  Row,
  Button,
  Span,
  Checkbox,
} from 'styled';
import BackArrow from 'images/back-arrow.svg';
import { useTranslation } from 'react-i18next';

const EditAddres = ({ setIsEditAddres, data, reFetch }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const Image = styled.img`
    display: block;
    width: 60px;
    height: 30px;
  `;
  const onSuccess = () => {
    setIsEditAddres((item) => {
      return { ...item, visible: false };
    });
    reFetch();
  };
  const title = (
    <>
      <Span>
        <Button
          className='no-decoration-button'
          type='link'
          onClick={onSuccess}
        >
          <Image src={BackArrow} style={{ display: 'inline-block' }} />
          <Span>{t('editAddress')}</Span>
        </Button>
      </Span>
    </>
  );

  const handleSubmit = (form, makeRequest) => {
    form.validateFields().then((values) => {
      makeRequest({ ...data, ...values });
    });
  };

  const { makeRequest } = useRequest({
    request: updatAddressApi,
    initialData: {},
    onSuccess,
  });

  useEffect(() => {
    data && form.setFieldsValue(data);
  }, [data, form]);

  return (
    <Card title={title} className='gafri-card'>
      <Row>
        <Form
          layout='inline'
          form={form}
          onFinish={() => {
            handleSubmit(form, makeRequest);
          }}
          className='profile-form'
        >
          <Col span={24} className='profile-form-label'>
            <Text level={1} type='secondary'>
              {t('checkOut.title')}
            </Text>
            <Form.Item
              name='title'
              validateFirst={true}
              validateTrigger='onSubmit'
              rules={[
                {
                  required: true,
                  message: 'Please input the Email',
                  whitespace: true,
                },
              ]}
            >
              <Input placeholder={t('checkOut.enterAddress')} />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} className='profile-form-label'>
            <Text level={1} type='secondary'>
              {t('checkOut.country')}*
            </Text>
            <Form.Item
              name='country'
              validateFirst={true}
              validateTrigger='onSubmit'
              rules={[
                {
                  required: true,
                  message: 'Please input the country',
                  whitespace: true,
                },
              ]}
            >
              <Input placeholder={t('checkOut.enterCountry')} />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} className='profile-form-label'>
            <Text level={1} type='secondary'>
              {t('checkOut.city')}*
            </Text>
            <Form.Item
              name='city'
              validateFirst={true}
              validateTrigger='onSubmit'
              rules={[
                {
                  required: true,
                  message: 'Please input the city',
                  whitespace: true,
                },
              ]}
            >
              <Input placeholder={t('checkOut.enterCity')} />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} className='profile-form-label'>
            <Text level={1} type='secondary'>
              {t('address')}*
            </Text>
            <Form.Item
              name='fullAddress'
              validateFirst={true}
              validateTrigger='onSubmit'
              rules={[
                {
                  required: true,
                  message: 'Please input the Email',
                  whitespace: true,
                },
              ]}
            >
              <Input placeholder={t('checkOut.enterAddress')} />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} className='profile-form-label'>
            <Text level={1} type='secondary'>
              {t('zipCode')}*
            </Text>
            <Form.Item
              name='zipCode'
              validateFirst={true}
              validateTrigger='onSubmit'
              rules={[
                {
                  required: true,
                  message: 'Please input the zip code',
                  whitespace: true,
                },
              ]}
            >
              <Input placeholder={t('checkOut.enterZipCode')} />
            </Form.Item>
          </Col>
          <Col span={24} className='profile-form-label'>
            <Form.Item name='isDefault' valuePropName='checked'>
              <Checkbox>
                <Row justify='space-between' style={{ width: '85%' }}>
                  <Text>{t('setAsDefaultAddress')}</Text>
                </Row>
              </Checkbox>
            </Form.Item>
          </Col>

          <Button
            type='submit'
            htmlType='submit'
            className='submit-button save-change'
          >
            {t('saveChange').toUpperCase()}
          </Button>
        </Form>
      </Row>
    </Card>
  );
};

export default EditAddres;
