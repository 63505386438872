import { Link } from 'react-router-dom';
import { Col, Divider } from 'antd';
import { Text, Row, Paragraph, Img } from 'styled';
import payment from 'images/payment.svg';
import payment_1 from 'images/payment_1.svg';
import card from 'images/card.svg';
import master from 'images/master.svg';
import visa from 'images/visa.svg';
import banner_cropped from 'images/banner_cropped.png';
import BannerWrapper from '../BannerWrapper/index';
import Wrapper from './style';

//language
import { useTranslation } from 'react-i18next';

const Payment = () => {
  const { t } = useTranslation();

  return (
    <>
      <BannerWrapper>
        <Row>
          <Col md={24} className='breadcrumb'>
            <Link to='/'>
              <Text level='1' weight='bold'>
                {t('home').toUpperCase()}
              </Text>
            </Link>
            <Divider type='vertical' />
            <Text className='title'>PAYMENT METHOD</Text>
          </Col>
          <Col md={24}>
            <Img src={banner_cropped} className='banner' />
            <Paragraph className='footer-title'>PAYMENT METHOD</Paragraph>
          </Col>
        </Row>
      </BannerWrapper>

      <Wrapper>
        <Row>
          <Col span={22} offset={1}>
            <Row className='payment-info'>
              <Col md={{ span: 10 }} xs={{ span: 22, push: 1 }}>
                <Img src={payment} className='payment-img' />
                <Text level='3' className='payment-info-text'>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum
                  dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                  tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                  minim veniam, quis nostrud exercitation ullamco laboris nisi
                  ut aliquip ex ea commodo consequat.
                </Text>
              </Col>
              <Col md={{ span: 10, push: 2 }} xs={{ span: 22, offset: 1 }}>
                <Img src={payment_1} className='payment-img' />
                <Text level='3' className='payment-info-text'>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum
                  dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                  tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                  minim veniam, quis nostrud exercitation ullamco laboris nisi
                  ut aliquip ex ea commodo consequat.
                </Text>
              </Col>
            </Row>

            <Row className='payment-info'>
              <Col md={{ span: 24, push: 0 }} xs={{ span: 22, push: 1 }}>
                <Paragraph weight='bold' level='5'>
                  What payment methods are available?
                </Paragraph>
                <Paragraph level='3' className='payment-info-text'>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum
                  dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                  tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                  minim veniam, quis nostrud exercitation ullamco laboris nisi
                  ut aliquip ex ea commodo consequat.
                </Paragraph>
              </Col>
              <Col
                md={{ span: 24, push: 0 }}
                xs={{ span: 22, push: 1 }}
                className='cards'
              >
                <Img src={master} />
                <Img src={visa} />
                <Img src={card} />
              </Col>
              <Col md={{ span: 24, push: 0 }} xs={{ span: 22, push: 1 }}>
                <Paragraph level='5' weight='bold'>
                  How do I use my promotional code?
                </Paragraph>
                <Text level='3' className='payment-info-text'>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum
                  dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                  tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                  minim veniam, quis nostrud exercitation ullamco laboris nisi
                  ut aliquip ex ea commodo consequat.
                </Text>
              </Col>
            </Row>
          </Col>
        </Row>
      </Wrapper>
    </>
  );
};

export default Payment;
