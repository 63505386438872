import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import HeaderNavWrapper from './style';
import { metalGet } from 'services/metal';
import { productFilterGet } from 'services/product';
import { useGetRequest } from 'hooks/useGetRequest';
import {
  useQueryParams,
  useRouterHistory,
  useRouterLocation,
} from 'hooks/routerHooks';
import { Col, Dropdown } from 'antd';
import { Img, Text, Paragraph, Button, Row } from 'styled';
import {
  // ShoppingOutlined,
  UserOutlined,
} from '@ant-design/icons';

import bracelet from 'images/bracelet.png';
import gafri from 'images/gafri.svg';
import UserMenu from 'components/UserMenu';
import HeartIcon from './HeartIcon';
import { useTranslation } from 'react-i18next';
import ChangeLanguage from './ChangeLanguage';
import MobileHeaderMenu from './MobileHeaderMenu';

import selectedMenuLine from 'images/selected-menu-line.svg';
import selectedMenuLineDisplayNone from 'images/selected-menu-line-display-none.svg';

const DropdownMenu = styled(Row)`
  padding: 50px;
  background: #fcf8f5;
  margin-top: 0;
  @media screen and (max-width: 480px) {
    background: #fff;
    padding: 0px;
    height: 100vh;
    .ant-btn {
      background: #fff;
    }
  }
`;

const MobileMenu = ({ item }) => {
  const { data } = useGetRequest({
    request: productFilterGet,
    initialData: {},
  });

  const history = useRouterHistory();
  const { t } = useTranslation();
  return (
    <DropdownMenu overlayStyle={{ marginTop: '60px' }}>
      <Row>
        <Col
          xs={24}
          md={{ span: 4, offset: 1 }}
          style={{ marginBottom: '-50px' }}
        >
          <Text level='3' weight='bold'>
            {t('shopByCategory').toUpperCase()}
          </Text>
          {data?.data?.categories?.map((a) => (
            <Paragraph
              style={{ cursor: 'pointer' }}
              $noMargin
              level='2'
              onClick={() => {
                window.localStorage.setItem('tag', JSON.stringify([]));
                history.push(
                  `/shop?metalId=${
                    item?.id
                  }&key=${item?.title.toLowerCase()}&categoryId=${a.id}&page=1`
                );
              }}
            >
              {a.title}
            </Paragraph>
          ))}
        </Col>
        <Col
          md={{ span: 4, offset: 2 }}
          xs={24}
          style={{ marginBottom: '-50px' }}
        >
          <Text level='3' weight='bold'>
            {t('shopByCollection').toUpperCase()}
          </Text>
          {data?.data?.collections?.map((a) => (
            <Paragraph
              style={{ cursor: 'pointer' }}
              $noMargin
              level='2'
              onClick={() => {
                window.localStorage.setItem('tag', JSON.stringify([]));
                history.push(
                  `/shop?metalId=${
                    item?.id
                  }&key=${item?.title.toLowerCase()}&collectionId=${
                    a.id
                  }&page=1`
                );
              }}
            >
              {a.title}
            </Paragraph>
          ))}
        </Col>
        <Col md={{ span: 6, offset: 6 }} xs={24}>
          <Row>
            <Col md={24} xs={24}>
              <Img src={bracelet} />
            </Col>
            <Col md={24}>
              <Button
                type='ghost'
                style={{ background: 'transparent' }}
                onClick={() => {
                  window.localStorage.setItem('tag', JSON.stringify([]));
                  history.push('/shop?page=1');
                }}
              >
                {t('shopNow')} &#8594;
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </DropdownMenu>
  );
};

export const Header = ({ count, reFetch }) => {
  const history = useRouterHistory();
  const { pathname } = useRouterLocation();
  const [visibleMenu, setVisibleMenu] = useState(false);
  const [activeMenuKey, setActiveMenuKey] = useState(null);

  const { key, metalId } = useQueryParams();

  const { data } = useGetRequest({
    request: metalGet,
    initialData: {},
    onSuccess: reFetch,
  });

  useEffect(() => {
    if (pathname !== '/shop' || (!key && !metalId)) {
      setActiveMenuKey(null);
      return;
    }
    if (metalId) {
      setActiveMenuKey(+metalId);
      return;
    }
    if (key && !metalId) {
      key === 'new' && setActiveMenuKey(3);
      key === 'sale' && setActiveMenuKey(4);
    }
  }, [pathname, key, metalId]);

  const { t } = useTranslation();

  if (pathname === '/not-found') {
    return <></>;
  }

  return (
    <HeaderNavWrapper>
      <Row align='center'>
        <Col xs={0} lg={22} offset={1}>
          <Row>
            <Col lg={4} xl={3}>
              <Img
                src={gafri}
                width={146}
                height={62}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  history.push('/');
                }}
              />
            </Col>
            <Col
              style={{ paddingTop: '15px' }}
              className='center'
              lg={15}
              xl={17}
            >
              {data?.data?.map((item) => (
                <Dropdown
                  overlay={<MobileMenu item={item} />}
                  overlayStyle={{ width: '100vw' }}
                  key={item?.id}
                >
                  <div
                    className='menu-item'
                    style={{ textTransform: 'uppercase' }}
                    onClick={() => {
                      window.localStorage.setItem('tag', JSON.stringify([]));
                      history.push(
                        `/shop?metalId=${
                          item?.id
                        }&key=${item?.title?.toLowerCase()}&page=1`
                      );
                    }}
                  >
                    <div>
                      <Text>{item?.title}</Text>
                    </div>
                    {activeMenuKey === item.id ? (
                      <Img
                        src={selectedMenuLine}
                        className='selected-menu-line'
                      />
                    ) : (
                      <Img
                        src={selectedMenuLineDisplayNone}
                        className='selected-menu-line'
                      />
                    )}
                  </div>
                </Dropdown>
              ))}
              <div
                className='menu-item'
                onClick={() => {
                  window.localStorage.setItem('tag', JSON.stringify([]));
                  history.push(`/shop?key=new&page=1`);
                }}
              >
                <div>
                  <Text>{t('new').toUpperCase()}</Text>
                </div>
                {activeMenuKey === 3 ? (
                  <Img src={selectedMenuLine} className='selected-menu-line' />
                ) : (
                  <Img
                    src={selectedMenuLineDisplayNone}
                    className='selected-menu-line'
                  />
                )}
              </div>
              <div
                className='menu-item'
                onClick={() => {
                  window.localStorage.setItem('tag', JSON.stringify([]));
                  history.push(`/shop?key=sale&page=1`);
                }}
              >
                <div>
                  <Text>{t('sale').toUpperCase()}</Text>
                </div>

                {activeMenuKey === 4 ? (
                  <Img src={selectedMenuLine} className='selected-menu-line' />
                ) : (
                  <Img
                    src={selectedMenuLineDisplayNone}
                    className='selected-menu-line'
                  />
                )}
              </div>
            </Col>
            <Col lg={5} xl={4} style={{ paddingBottom: '10px' }}>
              <Row justifyContent='end' align='middle'>
                <ChangeLanguage />{' '}
                <Dropdown.Button
                  type='link'
                  overlay={<UserMenu setVisibleMenu={setVisibleMenu} />}
                  visible={visibleMenu}
                  trigger={['click']}
                  onVisibleChange={(visible) => setVisibleMenu(visible)}
                  icon={
                    <UserOutlined
                      className='icons'
                      onClick={() => {
                        window.localStorage.getItem('userInfo')
                          ? setVisibleMenu(true)
                          : history.push('/signin');
                      }}
                      style={{
                        color: 'black',
                        fontSize: '20px',
                        marginTop: '5px',
                      }}
                    />
                  }
                />
                <HeartIcon history={history} count={count} />
              </Row>
              {/* <ShoppingOutlined style={{ fontSize: '20px', marginLeft: '30px' }} /> */}
            </Col>
          </Row>
        </Col>
        <Col span={24} lg={0}>
          <MobileHeaderMenu count={count} reFetch={reFetch} />
        </Col>
      </Row>
    </HeaderNavWrapper>
  );
};
