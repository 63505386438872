import { memo, useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Img, Paragraph } from 'styled';
import { List, Card, Tag } from 'antd';
//img
import HeartFull from 'images/heartFull.svg';
import Heart from 'images/heart.svg';

import { addRemoveFromFavoritesApi } from 'services/fevorites';
import useRequest from 'hooks/useRequest';
import Wrapper from './Favorites/style';
import Price from './Price';
import { useQuery, useQueryParams, useRouterHistory } from 'hooks/routerHooks';
import { useTranslation } from 'react-i18next';
import { productListGet } from 'services/product';

const getKey = (key, item) => {
  if (key === 'new') {
    return 'new';
  }

  if (!item.promotion) {
    return null;
  }

  if (key === 'sale') {
    let promotion = null;

    if (item.promotion.type === 1) {
      promotion = `-${item.promotion.amount}`;

      return promotion;
    }
    if (item.promotion.type === 2) {
      promotion = `-${item.promotion.amount}%`;
      return promotion;
    }
    return promotion;
  }
  return null;
};

const ProductList = ({ reFetch, setFilter, filter, tag }) => {
  const history = useRouterHistory();
  const userInfo = window.localStorage.getItem('userInfo');
  const { key } = useQueryParams();

  const query = useQuery();
  const currentPage = query.get('page');

  const {
    response,
    makeRequest: ListRequest,
    loading,
  } = useRequest({
    request: productListGet,
    initialData: { list: [], pageCount: 1, itemCount: 18 },
  });

  const { makeRequest } = useRequest({
    request: addRemoveFromFavoritesApi,
    initialData: {},
    onSuccess: () => {
      ListRequest(filter);
      reFetch();
    },
  });

  const total = response?.data?.itemCount;

  useEffect(() => {
    const total =
      filter.metalIds.length +
      filter.collectionIds.length +
      filter.categoryIds.length;
    total === tag.length && ListRequest(filter);
  }, [filter, ListRequest]);

  const setCurrent = useCallback(
    (page) => {
      setFilter((filter) => ({
        ...filter,
        paging: { page, count: 18 },
      }));
      query.set('page', page);
      history.push({ search: query.toString() });
    },
    [query, history, setFilter]
  );

  const { t } = useTranslation();

  return (
    <Wrapper>
      <List
        rowKey='list'
        grid={{
          xs: 2,
          sm: 2,
          md: 2,
          lg: 3,
          xl: 3,
          xxl: 3,
        }}
        loading={loading}
        dataSource={response?.data?.list}
        pagination={{
          current: +currentPage,
          total: total ? total : 1,
          pageSize: 18,
          showSizeChanger: false,
          onChange: (page) => {
            setCurrent(page);
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
          },
        }}
        renderItem={(item) => (
          <List.Item>
            <Card>
              {userInfo ? (
                <Button
                  type='link'
                  className='no-decoration-button heart-icon'
                  onClick={(e) => {
                    e.stopPropagation();
                    makeRequest({ id: item.id });
                    reFetch();
                  }}
                >
                  <Img src={item.isFavorite ? HeartFull : Heart} />
                </Button>
              ) : (
                <Button
                  type='link'
                  className='no-decoration-button heart-icon'
                  onClick={() => {
                    history.push('/signin');
                  }}
                >
                  <Img src={Heart} />
                </Button>
              )}
              <Link to={`/shop/product-details/${item.id}`}>
                {item && getKey(key, item) ? (
                  <Tag className='tag'>
                    {getKey(key, item) === 'new' ? t('new') : getKey(key, item)}
                  </Tag>
                ) : null}
                <Img
                  src={item.defaultImage}
                  onMouseOver={(e) => {
                    e.currentTarget.src = item.secondaryImage;
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.src = item.defaultImage;
                  }}
                  className='img'
                  style={{
                    display: 'inline-block',
                    position: 'relative',
                  }}
                />
                <Paragraph className='prod-name' $noMargin>
                  {item.title}
                </Paragraph>
                <Price price={item.price} promotion={item.promotion} />
              </Link>
            </Card>
          </List.Item>
        )}
      />
    </Wrapper>
  );
};

export default memo(ProductList);
