import { useState, useCallback, useEffect, useRef } from 'react';
import { message } from 'antd';

const defaultFunctionValue = () => {};

/*
  !important when passing onSuccess and onError to this hook
  remember to wrap them in useCallback.
*/

export const useRequest = ({
  request = defaultFunctionValue,
  onSuccess = defaultFunctionValue,
  onError = defaultFunctionValue,
  showErrorMessage = true,
  customErrorMessage = '',
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [response, setResponse] = useState(null);
  const isMounted = useRef(true);
  useEffect(
    () => () => {
      isMounted.current = false;
    },
    []
  );

  const makeRequest = useCallback(
    async (params) => {
      try {
        setLoading(true);
        const { data } = await request(params);
        if (isMounted.current) {
          setResponse(data);
          onSuccess(data);
        }
      } catch (e) {
        if (isMounted.current) {
          setError(e);
          showErrorMessage &&
            message.error(customErrorMessage || e?.response?.data?.message);
          onError(e);
        }
      } finally {
        if (isMounted.current) {
          setLoading(false);
        }
      }
    },
    [customErrorMessage, showErrorMessage, onError, onSuccess, request]
  );

  return {
    response,
    loading,
    error,
    makeRequest,
  };
};

export default useRequest;
