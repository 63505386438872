import styled from 'styled-components';

const ShopWrapper = styled.div`
  padding: 0 70px;
  margin-top: 120px;
  .breadcrumb {
    padding-bottom: 20px;
    margin-left: 24px;
  }
  .title {
    color: #e3a876;
    font-weight: bold;
    font-size: 16px !important;
  }
  .page-name {
    color: #e3a876;
    font-weight: 100;
    font-size: 16px !important;
    cursor: pointer;
  }
  .title-div {
    max-width: 555px;
    padding-bottom: 20px;
    position: absolute;
    z-index: 10;
    letter-spacing: 1.5px;
    font-weight: bold;
    margin: 0;
    top: 35%;
    left: 10%;
    @media screen and (max-width: 992px) {
      top: 25%;
    }
    @media screen and (max-width: 450px) {
      width: 100%;
      margin-top: 10px;
      position: relative;
      padding: 0px 12px;
      padding-bottom: 20px;
      font-weight: normal;
      top: 25%;
      left: 0%;
    }
  }
  .text {
    color: #363538;
    opacity: 0.8;
    @media screen and (max-width: 1200px) {
      font-size: 16px !important;
    }
    992px @media screen and (max-width: 1200px) {
      font-size: 16px !important;
    }
    @media screen and (max-width: 992px) {
      font-size: 12px !important;
    }
  }
  .banner-image {
    display: block;
    width: 100vw;
    height: 600px;
    object-fit: cover;
  }
  .shop-now-img {
    object-position: right;
  }
  .img-hover-zoom--slowmo {
    overflow: hidden;
  }
  .img-hover-zoom--slowmo img {
    transform-origin: 50% 50%;
    transition: transform 3s, filter 3s ease-in-out;
  }
  .img-hover-zoom--slowmo:hover img {
    transform: scale(1.1);
  }
  .ant-col > label {
    margin: 0;
    width: 100%;
  }
  .title-product {
    font-size: 32px !important;
  }
  .ant-checkbox-wrapper {
    width: 100%;
    .ant-checkbox {
      padding-bottom: 2px;
    }
  }
  .banner-img {
    height: 500px !important;
    width: 100%;
    object-fit: cover;
  }

  .gray {
    padding-left: 15px;
    color: #666666;
  }

  .black {
    padding-left: 15px;
    color: #000000;
  }

  @media screen and (max-width: 1450px) {
    .banner-image {
      height: 450px;
      width: 100vw;
      object-fit: cover;
    }
  }
  @media screen and (max-width: 1200px) {
    .banner-image {
      height: 400px;
      width: 100vw;
      object-fit: cover;
    }
  }
  @media screen and (max-width: 992px) {
    padding: 0 30px;
  }
  @media screen and (max-width: 1000px) {
    .banner-image {
      height: 350px;
      width: 100vw;
      object-fit: cover;
    }
  }

  @media screen and (max-width: 768px) {
    .filter-sort {
      display: none;
    }
  }

  @media screen and (max-width: 480px) {
    margin-top: 100px;
    padding: 0px;
    div.ant-typography,
    .ant-typography p {
      margin: 0px;
    }
    .title-product {
      font-size: 24px !important;
    }

    .filter-web {
      display: none;
    }
    .mob-invisible {
      display: none;
    }
    .banner-image {
      height: 350px;
      width: auto;
      object-fit: cover;
    }
  }
`;

export default ShopWrapper;
