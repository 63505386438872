import React from 'react';
import styled, { css } from 'styled-components';
import { Typography as typography } from 'antd';

const { Text: Component } = typography;

const text = ({ level, hoverable, ...props }) => <Component {...props} />;

export const fontSizes = {
  1: 16,
  2: 20,
  3: 24,
  4: 36,
  5: 45,
  6: 64,
  7: 80,
};

const Text = styled(text)`
  color: #3e3e3e;
  font-family: Roboto;
  font-size: ${(props) => fontSizes[props.level]}px;
  &.please-text {
    font-family: Roboto-Regular;
    letter-spacing: 0px;
    display: block;
    padding: 20px 0;
    margin-bottom: 20px;
    @media only screen and (max-width: 1200px) {
      font-size: 18px;
      margin-bottom: 0;
      padding: 10px 0;
    }
  }

  ${(props) =>
    props.weight &&
    css`
      font-weight: ${props.weight};
    `}
  ${(props) =>
    props.cursor === 'pointer' &&
    css`
      cursor: pointer;
    `}
  ${(props) =>
    props.hoverable &&
    css`
      :hover {
        outline: solid 1px black;
        transition: outline 0.6s linear;
        margin: 0.5em; /* Increased margin since the outline expands outside the element */
      }
    `}
`;

Text.defaultProps = {
  level: 2,
};

export default Text;
