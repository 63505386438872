import { useQuery, useRouterHistory } from 'hooks/routerHooks';
import { memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Text, Checkbox, Paragraph, Slider, Col } from 'styled';
import Context from '../Context';

const SingleFilter = ({ title, filterValues, category }) => {
  const history = useRouterHistory();
  const { setTag, filter, setFilter } = useContext(Context);
  const query = useQuery();

  return (
    <Row>
      <Text level='3' style={{ margin: '20px 0px 10px 0px' }}>
        {title}
      </Text>
      {filterValues?.map(({ title, id }) => (
        <Col md={24} key={title + id} style={{ width: '100%' }}>
          <Row type='flex' align='middle'>
            <Checkbox
              checked={filter[category]?.includes(id)}
              onChange={(e) => {
                query.set('page', 1);
                history.push({ search: query.toString() });
                if (category === 'metalIds') {
                  window.localStorage.setItem('tag', JSON.stringify([]));
                  setTag([]);
                  e.target.checked
                    ? history.push(
                        `/shop?metalId=${id}&key=${title.toLowerCase()}&page=1`
                      )
                    : history.push(`/shop?page=1`);
                  e.target.checked &&
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                  return;
                }
                setTag((t) => {
                  return e.target.checked
                    ? [
                        ...t,
                        {
                          id,
                          name: title,
                          category,
                        },
                      ]
                    : t.filter((c) => c.name !== title);
                });
                setFilter((f) => ({
                  ...f,
                  [category]: e.target.checked
                    ? [...f[category], id]
                    : f[category].filter((a) => a !== id),
                }));
              }}
            >
              <Text
                level='2'
                className={filter[category]?.includes(id) ? 'black' : 'gray'}
              >
                {title}
              </Text>
            </Checkbox>
          </Row>
        </Col>
      ))}
    </Row>
  );
};

const FiltersComponent = ({ data, mobileFilter = false, setFilter }) => {
  const { t } = useTranslation();
  return data.data ? (
    <Row>
      <Col>
        {mobileFilter === true ? null : (
          <SingleFilter
            title={t('filterByMetal')}
            filterValues={data?.data?.metals}
            category='metalIds'
          />
        )}
        <SingleFilter
          title={t('filterByCategory')}
          filterValues={data?.data?.categories}
          category='categoryIds'
        />
        <SingleFilter
          title={t('filterByCollection')}
          filterValues={data?.data?.collections}
          category='collectionIds'
        />
        <Paragraph level='2' style={{ marginTop: '20px' }}>
          {t('filterByPrice')}
        </Paragraph>
        <Slider
          range={true}
          defaultValue={[data?.data?.minPrice, data?.data?.maxPrice]}
          onChange={(e) => {
            setFilter((filter) => ({
              ...filter,
              minPrice: e[0],
              maxPrice: e[1],
            }));
          }}
          marks={false}
          max={data?.data?.maxPrice}
          min={data?.data?.minPrice}
        />
        <Row justify='space-between'>
          <Col>
            <Text level='1'>{data?.data?.minPrice} &#1423;</Text>
          </Col>
          <Col>
            <Text level='1' style={{ marginLeft: '20px' }}>
              {data?.data?.maxPrice}&#1423;
            </Text>
          </Col>
        </Row>
      </Col>
    </Row>
  ) : null;
};

export default memo(FiltersComponent);
