import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Menu, Row, Col, Button } from 'styled';
import { GafriModal } from 'styled';

const UserMobileMenu = ({ history, setVisible }) => {
  const [isOpen, setIsOpen] = useState(false);
  const userInfo = !!window.localStorage.getItem('userInfo');
  const { t } = useTranslation();
  return (
    <Row style={{ position: 'absolute', bottom: 0, left: 0, width: '100%' }}>
      <Col span={24}>
        {userInfo ? (
          <>
            <Menu
              style={{ backgroundColor: '#F4EDE7' }}
              triggerSubMenuAction='click'
            >
              <Menu.Item
                key='profile'
                onClick={() => {
                  setVisible(false);
                  history.push('/user/profile');
                }}
              >
                {t('personalInformation')}
              </Menu.Item>
              {/* <Menu.Item
                key='order'
                onClick={() => {
                setVisible(false)
                  history.push('/user/order');
                }}
              >
              {t('order')}
                Order
              </Menu.Item> */}
              <Menu.Item
                key='addresses'
                onClick={() => {
                  setVisible(false);
                  history.push('/user/addresses');
                }}
              >
                {t('address')}
              </Menu.Item>
              {/*   <Menu.Item
                key='cards'
                onClick={() => {
                setVisible(false)
                  history.push('/user/cards');
                }}
              >
                Cards
              </Menu.Item> */}
              <Menu.Item
                key=' '
                onClick={() => {
                  setIsOpen(true);
                }}
              >
                {t('signOut')}
              </Menu.Item>
              <GafriModal
                visible={isOpen}
                onOk={() => {
                  window.localStorage.removeItem('userInfo');
                  setVisible(false);
                  setIsOpen(false);
                  history.push('/');
                }}
                onCancel={() => setIsOpen(false)}
                title={t('logOut')}
                modalText={t('logOutFromYourAccount')}
              />
            </Menu>
          </>
        ) : (
          <Button
            type='accent'
            key='profile'
            onClick={() => {
              setVisible(false);
              history.push('/signin');
            }}
          >
            {t('signIn')}
          </Button>
        )}
      </Col>
    </Row>
  );
};

export default UserMobileMenu;
