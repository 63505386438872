import { Modal as modal, Button as button } from 'antd';
import { useTranslation } from 'react-i18next';
import { Row, Col, Paragraph, Text } from 'styled';
import styled from 'styled-components';
const Modal = styled(modal)`
  min-width: 560px;
  .home-page-modal-title {
    display: block;
    padding-bottom: 20px;
  }
  .home-page-modal-text {
    display: block;
    padding-bottom: 20px;
  }
  .home-page-modal-img {
    width: 50px;
    padding-bottom: 15px;
  }
  @media only screen and (max-width: 992px) {
    min-width: 360px;
  }
`;

const Button = styled(button)`
  height: 50px;
  border: 1px solid #3f2f1e;
  color: #363538;
  margin: 15px 0;
  .modal-text {
    padding: 0 60px;
  }
  &:hover {
    color: black;
    border: 1px solid #3f2f1e;
  }
  &:focus {
    color: black;
    box-shadow: none;
    border: 1px solid #3f2f1e;
  }
  .contenu-as-guest {
    background-color: #fbfbfb;
    &:hover {
      color: #363538;
      border: 1px solid #fbfbfb;
    }
    &:focus {
      color: #363538;
      box-shadow: none;
      border: 1px solid #fbfbfb;
    }
  }
  @media only screen and (max-width: 1200px) {
    height: 60px;
  }
  @media only screen and (max-width: 992px) {
    height: 50px;
  }
  &.ok-Button {
    background-color: #3f2f1e;
    color: white;
  }
`;

const CheckOutModal = ({
  onOkSignIn,
  loading,
  visible,
  onCancel,
  closable = true,
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      centered
      closable={closable}
      footer={null}
    >
      <Row>
        <Col span={24}>
          <Paragraph level={5}>{t('signIn')}</Paragraph>
          <Text>{t('alreadyHave')}</Text>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Button
            key='submit'
            className='ok-Button'
            loading={loading}
            onClick={onOkSignIn}
            block
          >
            {t('signIn')}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Text>{t('checkOut.doYouWant')}</Text>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Button
            key='submit'
            className='contenu-as-guest'
            loading={loading}
            onClick={onCancel}
            block
          >
            {t('checkOut.guest').toUpperCase()}
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};
export default CheckOutModal;
