import React from 'react';
import styled, { css } from 'styled-components';
import { Typography as typography } from 'antd';

const { Paragraph: Component } = typography;

export const fontSizes = {
  1: 16,
  2: 18,
  3: 24,
  4: 32,
  5: 36,
  6: 64,
  7: 50,
  default: 20,
};

const paragraph = (props) => <Component {...props} />;

const Paragraph = styled(paragraph)`
  font-family: Futura !important;
  font-size: ${(props) => fontSizes[props.level]}px !important;
  color: black;
  display: block !important;
  ${(props) =>
    props.weight === 'bold' &&
    css`
      font-weight: bold;
    `}
  ${(props) =>
    props.weight !== 'bold' &&
    css`
      font-weight: ${props.weight};
    `}

  ${(props) =>
    props.$hasPadding &&
    css`
      padding: 12px;
    `}
  ${(props) =>
    props.$noMargin &&
    css`
      margin-bottom: 0px !important;
    `}
`;

Paragraph.defaultProps = {
  level: 2,
};
/** @component */

export default Paragraph;
