import { Paragraph, Img, Row, Col } from 'styled';
import styled from 'styled-components';
import { categoryGetHomePage } from 'services/category';
import { useGetRequest } from 'hooks/useGetRequest';
import { useRouterHistory } from 'hooks/routerHooks';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useTranslation } from 'react-i18next';

const CategoriesWrapper = styled.div`
  margin-bottom: 50px;
  align-items: center;
  .item-title {
    color: #363538;
  }
  .slick-list {
    background: #f4ede7;
  }
  .section {
    display: inline-block !important;
    width: auto !important;
    cursor: pointer;
    text-align: center;
    :hover {
      opacity: 0.8;
    }
  }
  img {
    width: 130px !important;
    margin-bottom: 10px;
  }
  .slick-initialized .slick-slide {
    text-align: center;
    cursor: pointer;
    padding-top: 45px;
  }
  .slick-prev {
    left: 4% !important;
    width: 60px;
    height: 60px;
    z-index: 10;
    :before {
      font-size: 60px;
      color: black;
    }
  }
  .slick-next {
    right: 6% !important;
    width: 60px;
    height: 60px;
    z-index: 10;
    :before {
      font-size: 60px;
      color: black;
    }
  }
  .title {
    background: white;
    margin-top: 150px;
    padding-bottom: 50px;
    text-align: center;
    font-size: 32px !important;
  }
  @media screen and (max-width: 992px) {
    .slick-next,
    .slick-prev {
      :before {
        display: none;
      }
    }
    .title {
      background: #f4ede7;
    }
    .slick-list {
      background: #fcf8f5;
    }
  }
  @media screen and (max-width: 480px) {
    margin-top: 0px;
    margin-bottom: 0px;
    .title {
      margin-top: 10px;
      padding-bottom: 60px;
      padding-top: 60px;
      text-align: center;
      font-size: 30px !important;
    }
    .slick-initialized .slick-slide {
      padding-top: 40px;
    }
    img {
      width: 70px !important;
      margin: 10px auto;
    }
    .section > .ant-typography {
      font-size: 20px !important;
    }
  }
`;

const Categories = () => {
  const { data } = useGetRequest({
    request: categoryGetHomePage,
    initialData: { data: [] },
  });

  const history = useRouterHistory();
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: data?.data.length,
    slidesToScroll: 1,
    initialSlide: 1,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 3,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const { t } = useTranslation();

  return (
    <Row>
      <Col span={24}>
        <CategoriesWrapper>
          <Paragraph $noMargin className='title '>
            {t('categories').toUpperCase()}
          </Paragraph>
          <CategoriesWrapper>
            <Slider {...settings}>
              {data.data.map((item) => (
                <div
                  className='section'
                  key={item.id}
                  onClick={() => {
                    window.localStorage.setItem('tag', JSON.stringify([]));
                    history.push(`/shop?categoryId=${item.id}&page=1`);
                  }}
                >
                  <Img src={item.icon} />
                  <Paragraph level='3' className='item-title'>
                    {item.title?.toUpperCase()}
                  </Paragraph>
                </div>
              ))}
            </Slider>
          </CategoriesWrapper>
        </CategoriesWrapper>
      </Col>
    </Row>
  );
};
export default Categories;
