import { Modal as modal, Button as button } from 'antd';
import { useTranslation } from 'react-i18next';
import { Row, Col, Span } from 'styled';
import styled from 'styled-components';
const Modal = styled(modal)`
  .home-page-modal-title {
    display: block;
    padding-bottom: 20px;
  }
  .home-page-modal-text {
    display: block;
    padding-bottom: 20px;
  }
  .home-page-modal-img {
    width: 50px;
    padding-bottom: 15px;
  }
`;

const Button = styled(button)`
  height: 50px;
  min-width: 150px;
  border: 1px solid #3f2f1e;
  .modal-text {
    padding: 0 60px;
  }
  &:hover {
    color: black;
    border: 1px solid #3f2f1e;
  }
  &:focus {
    color: black;
    box-shadow: none;
    border: 1px solid #3f2f1e;
  }

  @media only screen and (max-width: 1200px) {
    height: 60px;
    width: 130px;
  }
  @media only screen and (max-width: 992px) {
    height: 50px;
    width: 100px;
  }
  &.ok-Button {
    width: auto;
    min-width: 150px;
    background-color: #3f2f1e;
    color: white;
  }
`;

const GafriModal = (props) => {
  const { t } = useTranslation();
  const {
    onOk,
    onCancel,
    loading,
    visible,
    cancelText = <Span>{t('cancel').toUpperCase()}</Span>,
    icon = null,
    okText = <Span>{t('ok').toUpperCase()}</Span>,
    title = 'Title',
    modalText = 'Are you sure',
    closable = true,
  } = props;
  return (
    <Modal
      visible={visible}
      width={400}
      onCancel={onCancel}
      centered
      closable={closable}
      footer={[
        <Row
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Col>
            <Button type onClick={onCancel}>
              {cancelText}
            </Button>
            <Button
              key='submit'
              className='ok-Button'
              loading={loading}
              onClick={onOk}
            >
              {okText}
            </Button>
          </Col>
        </Row>,
      ]}
    >
      <Row
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        {icon ? icon : null}
        <Col>{title}</Col>
        <Col>{modalText}</Col>
      </Row>
    </Modal>
  );
};
export default GafriModal;
