import { Route, Redirect, Switch } from 'react-router-dom';
import HomePage from './components/Homepage/index';
import About from './components/FooterComponents/About/index';
import Stores from './components/FooterComponents/Stores/index';
import Shipping from './components/FooterComponents/ShippingAndReturn/index';
import Payment from './components/FooterComponents/Payment/index';
import FAQ from './components/FooterComponents/FAQ/index';
import Contact from './components/FooterComponents/Contact';
import Shop from './components/Shop';
import SignIn from './components/Users/SignIn/index';
import Forgot from './components/Users/Forgot/index';
import EmailVerification from './components/Users/Forgot/EmailVerification';
import NewPasswordForForgot from './components/Users/Forgot/NewPasswordForForgot';
import SignUp from './components/Users/SignUp/index';
import SignUpVerification from './components/Users/SignUp/SignUpVerification';
import CreateAccount from './components/Users/SignUp/CreateAccount';
import ProfilePage from './components/ProfilePage';
import ProductDetails from './components/ProductDetails';
import Favorites from './components/Shop/Favorites';
import AddNewCard from 'components/ProfilePage/Card/AddNewCard';
import PrivacyPolicy from 'components/FooterComponents/PrivacyPolicy';
import CheckOut from 'components/CheckOut';
import NotFound from 'styled/NotFound';

const AppRouter = ({ reFetch }) => {
  const loggedIn = window.localStorage.getItem('userInfo');
  return (
    <Switch>
      <Route exact path='/'>
        <HomePage reFetch={reFetch} />
      </Route>
      <Route exact path='/about' component={About} />
      <Route exact path='/not-found' component={NotFound} />
      <Route exact path='/stores' component={Stores} />
      <Route exact path='/shipping' component={Shipping} />
      <Route exact path='/payment' component={Payment} />
      <Route exact path='/questions' component={FAQ} />
      <Route exact path='/contact' component={Contact} />
      <Route exact path='/privacy' component={PrivacyPolicy} />
      <Route path='/checkOut/:id' component={CheckOut} />

      <Route exact path='/shop'>
        <Shop reFetch={reFetch} />
      </Route>
      <Route exact path='/shop/product-details/:id'>
        <ProductDetails reFetchHeaderCount={reFetch} />
      </Route>
      <Route exact path='/user/:type' component={ProfilePage} />
      <Route exact path='/user/cards/addnewcard' component={AddNewCard} />
      <Route exact path='/favorites'>
        <Favorites reFetch={reFetch} />
      </Route>
      {loggedIn ? (
        <Redirect to='/' />
      ) : (
        <>
          <Route exact path='/signin' component={SignIn} />
          <Route exact path='/signup' component={SignUp} />
          <Route
            exact
            path='/signup/verification'
            component={SignUpVerification}
          />
          <Route
            exact
            path='/signup/verification/createaccount'
            component={CreateAccount}
          />
          <Route exact path='/forgot' component={Forgot} />
          <Route
            exact
            path='/forgot/verification'
            component={EmailVerification}
          />
          <Route
            exact
            path='/forgot/verification/newPassword'
            component={NewPasswordForForgot}
          />
        </>
      )}
      <Route path='*'>
        <Redirect to='/not-found' />
      </Route>
    </Switch>
  );
};
export default AppRouter;
