import { Fragment } from 'react';
import { Col } from 'antd';
import { Row, Img, Paragraph, Badge } from 'styled';
import { productPromotionGet } from 'services/product';
import { useGetRequest } from 'hooks/useGetRequest';
import { useRouterHistory } from 'hooks/routerHooks';
import styled from 'styled-components';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Price from 'components/Shop/Price';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  margin-top: 200px;
  .ant-badge-count {
    border-radius: 0px;
    background-color: #f4ede7;
    font-size: 20px;
    padding: 32px 6px 25px;
    position: absolute;
    right: 25px;
  }
  .title {
    font-size: 32px;
  }
  .slick-initialized .slick-slide {
    text-align: center;
    cursor: pointer;
  }
  .slick-prev {
    left: 4% !important;
    width: 60px;
    height: 60px;
    z-index: 10;
    :before {
      font-size: 60px;
      color: black;
    }
  }
  .slick-next {
    right: 6% !important;
    width: 60px;
    height: 60px;
    z-index: 10;
    :before {
      font-size: 60px;
      color: black;
    }
  }
  .line-through {
    color: grey;
    text-decoration: line-through;
    margin-right: 16px;
  }
  @media screen and (max-width: 992px) {
    .slick-next,
    .slick-prev {
      :before {
        display: none;
      }
    }
  }
  @media screen and (max-width: 480px) {
    background-color: #f4ede7;
    margin-top: 0px;
    padding-top: 40px;
    img {
      background-color: #fff;
      width: 220px;
    }
    .title {
      font-size: 30px !important;
    }
  }
`;

const Bestsellers = () => {
  const { data } = useGetRequest({
    request: productPromotionGet,
    initialData: { data: [] },
  });

  const history = useRouterHistory();
  const settings = {
    infinite: data.data.length >= 5 ? true : false,
    speed: 500,
    slidesToShow: 4.5,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  const { t } = useTranslation();

  return (
    <Fragment>
      {data.data.length ? (
        <Wrapper>
          <Row justify='space-between'>
            <Col push={1}>
              <Paragraph level='4' style={{ marginBottom: '24px' }}>
                {t('sale').toUpperCase()}
              </Paragraph>
            </Col>
          </Row>
          <Slider {...settings}>
            {data?.data?.map((item) => (
              <div
                key={item.id}
                onClick={() => history.push(`/shop/product-details/${item.id}`)}
              >
                <Badge count={`-${item?.promotion.amount}%`}>
                  <Img
                    src={item.defaultImage}
                    onMouseOver={(e) => {
                      e.currentTarget.src = item.secondaryImage;
                    }}
                    onMouseOut={(e) => {
                      e.currentTarget.src = item.defaultImage;
                    }}
                    className='img'
                    style={{
                      display: 'inline-block',
                      position: 'relative',
                    }}
                  />
                </Badge>
                <Paragraph level='3' $noMargin>
                  {item?.title}
                </Paragraph>
                <Price price={item?.price} promotion={item?.promotion} />
              </div>
            ))}
          </Slider>
        </Wrapper>
      ) : null}
    </Fragment>
  );
};

export default Bestsellers;
