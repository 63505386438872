import React from 'react';
import { Form, Input, Button, Row, Col, Text, Card } from 'styled';
import { useRouterHistory } from 'hooks/routerHooks';
import useRequest from 'hooks/useRequest';
import { updatPasswordApi } from 'services/profile';
import { useTranslation } from 'react-i18next';

const ChangPassword = () => {
  const [form] = Form.useForm();
  const handleSubmit = (form, makeRequest) => {
    form.validateFields().then((values) => {
      makeRequest(values);
    });
  };

  const history = useRouterHistory();
  const onSuccess = () => {
    history.push('/');
  };

  const { makeRequest } = useRequest({
    request: updatPasswordApi,
    initialData: {},
    onSuccess,
  });

  const { t } = useTranslation();

  return (
    <Card
      title={t('changePassword')}
      className='gafri-card'
      style={{
        marginTop: '40px',
      }}
    >
      <Row>
        <Col span={24}>
          <Text level={2}></Text>
          <Form
            form={form}
            onFinish={() => handleSubmit(form, makeRequest)}
            className='profile-form '
          >
            <Col xs={24} md={12} className='profile-form-label'>
              <Text level={1} type='secondary'>
                {t('currentPassword')}*
              </Text>
              <Form.Item
                name='oldPassword'
                validateFirst={true}
                validateTrigger='onSubmit'
                rules={[
                  {
                    required: true,
                    message: 'Please input the Email',
                    whitespace: true,
                  },
                ]}
              >
                <Input.Password placeholder={t('currentPassword')} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} className='profile-form-label'>
              <Text level={1} type='secondary'>
                {t('newPassword')}*
              </Text>
              <Form.Item
                name='newPassword'
                rules={[
                  {
                    required: true,
                    message: 'Please input your password!',
                  },
                ]}
              >
                <Input.Password placeholder={t('newPassword')} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} className='profile-form-label'>
              <Text level={1} type='secondary'>
                {t('confirmNewPassword')}*
              </Text>
              <Form.Item
                name='confirm'
                dependencies={['newPassword']}
                rules={[
                  {
                    required: true,
                    message: 'Please confirm your password!',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('newPassword') === value) {
                        return Promise.resolve();
                      }

                      return Promise.reject(
                        new Error(
                          'The two passwords that you entered do not match!'
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password placeholder={t('confirmNewPassword')} />
              </Form.Item>
            </Col>
            <Form.Item>
              <Button type='submit' htmlType='submit' className='submit-button'>
                {t('save').toUpperCase()}
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Card>
  );
};

export default ChangPassword;
