import styled from 'styled-components';

const Span = styled.span`
  margin: 0;
  &.have-account-text {
    font-family: Roboto-Regular;
    letter-spacing: 0px;
    color: #000000;
  }
`;

/** @component */
export default Span;
