import React from 'react';
import styled, { css } from 'styled-components';
import { Button as button } from 'antd';

const Component = styled(button)`
  background: white;
  border-width: 1px;
  border-style: solid;
  padding: 8px 24px;
  &.submit-button {
    background: #3f2f1e;
    color: #ffffff;
    max-width: 560px;
    height: 56px;
    &.checkout-button {
      max-width: 1200px;
      margin: 0;
    }
    @media only screen and (max-width: 600px) {
      font-size: 12px;
      height: 40px;
    }
  }
  ${(props) =>
    !props.type &&
    css`
      background: transparent;
      width: 200px;
      min-height: 80px;
      margin-top: 20px;
      border: 1px solid black;
      &:hover {
        color: black;
        border-color: black !important;
      }
      &:focus {
        color: black;
        box-shadow: none;
        border-color: black !important;
      }
    `}
  ${(props) =>
    props.type === 'ghost' &&
    css`
      padding-left: 0;
      box-shadow: none !important;
      border: none;
      color: black !important;
      &:hover {
        color: black !important;
        background: none !important;
        border: none !important;
      }
      &:focus {
        background: none !important;
        border: none !important;
      }
      &:after {
        display: none !important;
      }
    `}
  ${(props) =>
    props.type === 'link' &&
    css`
      border-bottom: 1px solid black;
      padding: 0;
      color: black !important;
      background: none !important;
      &.link-button {
        height: 20px;
        font-size: 12px;
      }
      &.no-decoration-button {
        border-bottom: none;
        letter-spacing: 0px;
        color: #000000;
        &:hover {
          border-bottom: none;
        }
        &:focus {
          border-bottom: none;
        }
        &.forgot-text {
          letter-spacing: 0px;
          font-family: Roboto-Regular;
        }
      }
      &:hover {
        background: none !important;
        border-bottom: 1px solid black;
      }
      &:focus {
        background: none !important;
        border-bottom: 1px solid black;
      }
      .have-account-button {
        font-family: Roboto;
        font-size: 18px;
      }
    `}

  ${(props) =>
    props.type === 'text' &&
    css`
      font-size: 18px;
      width: 200px;
      min-height: 50px;
      padding: 0;
      color: black !important;
      background: none !important;
      .arrow {
        margin-left: 10px;
        font-size: 20px;
      }
      &:hover {
        .arrow {
          transition: 0.3s ease-in-out;
          -webkit-transition: 0.1s ease-in-out;
          transform: translateX(50%);
        }
        background: none !important;
        border: 1px solid black;
        transition: 0.3s ease-in-out;
        -webkit-transition: 0.1s ease-in-out;
      }
    `}
    ${(props) =>
    props.type === 'accent' &&
    css`
      border-width: 0;
      font-size: 18px;
      width: 100%;
      height: 50px;
      color: black !important;
      background: #f4ede7 !important;
      text-align: unset;
    `}
`;

const Button = ({ ...props }) => <Component {...props} />;

/** @component */
export default Button;
