import styled from 'styled-components';

const Wrapper = styled.div`
  .cards {
    display: flex;
    padding-top: 16px;
    padding-bottom: 82px;
    img {
      display: block;
      max-width: 100%;
      // margin-right: 60px;
      @media screen and (max-width: 480px) {
        max-width: 100px;
        height: 100px;
      }
    }
  }
  .payment-info-text {
    font-family: Roboto-Regular;
  }

  .payment-info {
    margin-top: 60px;
    margin-bottom: 80px;

    img {
      &.payment-img {
        width: 100%;
        object-fit: fill;
        margin: 0px;
      }
      padding-bottom: 20px;
      object-fit: fill;
    }
  }

  @media screen and (max-width: 1200px) {
    .cards {
      padding-bottom: 30px;
    }
    .payment-info {
      margin-top: 40px;
      margin-bottom: 60px;
      .payment-info-text {
        margin-bottom: 20px;
      }
      img {
        padding: 20px 0;
        object-fit: fill;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .cards {
      padding-bottom: 30px;
    }
    .payment-info {
      margin-top: 20px;
      margin-bottom: 30px;
      img {
        padding-bottom: 20px;
        object-fit: fill;
      }
    }
  }
`;

export default Wrapper;
