import { Link } from 'react-router-dom';
import { Col, Divider } from 'antd';
import { Text, Row, Img, Paragraph } from 'styled';

import banner_cropped from 'images/banner_cropped.png';
import BannerWrapper from '../BannerWrapper';
import Wrapper from '../ShippingAndReturn/style';

//language
import { useTranslation } from 'react-i18next';

const PrivacyPolicy = () => {
  const { t } = useTranslation();
  return (
    <>
      <BannerWrapper>
        <Row>
          <Col md={24} className='breadcrumb'>
            <Link to='/'>
              <Text level='1' weight='500'>
                {t('home').toUpperCase()}
              </Text>
            </Link>
            <Divider type='vertical' />
            <Text className='title'>PRIVACY POLICY</Text>
          </Col>
          <Col md={24}>
            <Img src={banner_cropped} className='banner' />
            <Paragraph className='footer-title'>PRIVACY POLICY</Paragraph>
          </Col>
        </Row>
      </BannerWrapper>
      <Wrapper>
        <Row>
          <Col md={{ span: 22, offset: 1 }} xs={{ span: 22, offset: 1 }}>
            <Text level='3' className='text'>
              <br />
              <br />
              The Privacy Policy section presents the collection, use and access
              to your personal data when making a purchase from{' '}
              <a href='https://www.gafri.am/'>www.gafri.am.</a>
              <br />
              <br />
              This Privacy Policy describes how we collect and use personal
              information (name, address, mobile phone, email) about you when
              you visit the site, call us on the phone, send an email or visit
              our stores.
              <br />
              <br />
              In this Policy, “we” and “our” mean FELIJAN LLC, and “you” means
              any person who visits the Site, makes an order online, calls us on
              the phone, sends a mail or an email or visits our Stores.
              <br />
              <br />
              We collect information from you only when you create an account or
              make an order as a guest. Data collected for orders made as a
              guest that includes name, address, phone number and email will be
              used for fulfilling the order. If you create an account with us,
              your details (including name, email, phone number, addresses, past
              orders and favorites) will be saved to be used for future orders.
              <br />
              <br />
              Whether you make an order as a guest or create an account with us,
              we might use your information to contact you for checking
              information about the order or for other promotional activity. If
              you wish to unsubscribe from our promotional content, you’ll be
              able to do so by contacting us on the email{' '}
              <a href='mailto:info@gafri.am'>info@gafri.am</a>
              <br />
              <br />
              Currently, we are not using cookies to track your activity.
              <br />
              <br />
              We reserve the right to change our privacy policy at any point and
              we’ll notify you via email if an event as such happens.
            </Text>
          </Col>
        </Row>
      </Wrapper>
    </>
  );
};

export default PrivacyPolicy;
