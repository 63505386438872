import styled from 'styled-components';

const AboutWrapper = styled.div`
  .about-left-img {
    height: 760px;
    width: 100%;
    object-fit: contain;
  }
  .center {
    @media only screen and (max-width: 1200px) {
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
    }
  }
  .about-right-img {
    max-width: 380px;
    max-height: 440px;
    object-fit: contain;
  }
  .gafri-about-text {
    font-family: Roboto-Regular;
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 1.2px;
    padding-top: 100px;
  }

  .about-section {
    margin-top: 100px;
  }

  @media screen and (max-width: 1200px) {
    .about-left-img {
      width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
    .about-right-img {
      max-width: 360px;
      max-height: 560px;
    }
    .gafri-about-text {
      font-size: 14px;
      letter-spacing: 1.2px;
    }
  }
  @media screen and (max-width: 992px) {
    .about-left-img {
      width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
    .golden-rings {
      max-width: 600px;
      height: 1000px;
    }
    .gafri-about-text {
      font-size: 14px;
      letter-spacing: 1.2px;
    }
  }

  @media screen and (max-width: 480px) {
    .text-1 {
      display: block;
    }
    .text-2 {
      display: none;
    }
    .golden-rings {
      width: 335px;
      height: 406px;
      margin: 40px 0;
    }
    .about-left-img {
      width: 342px;
      height: 380px;
    }
    .banner {
      height: 210px;
    }
    .about-section {
      margin-top: 30px;
    }
  }
`;
export default AboutWrapper;
