import React, { useContext, memo } from 'react';
import { Drawer, Col } from 'antd';
import { Row, Button } from 'styled';
import FiltersComponent from './FiltersComponent';
import { LeftOutlined } from '@ant-design/icons';
import { useRouterHistory } from 'hooks/routerHooks';
import { useTranslation } from 'react-i18next';
import Context from '../Context';

const FilterModal = ({ visible, setVisible, filter, data, setFilter }) => {
  const history = useRouterHistory();
  const { t } = useTranslation();
  const { setTag } = useContext(Context);
  return (
    <Drawer
      width='100%'
      closable={false}
      drawerStyle={{ height: 'auto' }}
      placement='left'
      onClose={() => setVisible(false)}
      visible={visible}
    >
      <Row justify='space-between'>
        <Col>
          <LeftOutlined onClick={() => setVisible(false)} />
        </Col>
        <Col>
          <Button
            type='link'
            onClick={() => {
              setTag([]);
              window.localStorage.setItem('tag', JSON.stringify([]));
              setVisible(false);
              history.push('/shop?page=1');
            }}
          >
            {t('reset')}
          </Button>
        </Col>
        <FiltersComponent
          data={data}
          mobileFilter={true}
          setFilter={setFilter}
        />
      </Row>
    </Drawer>
  );
};

export default memo(FilterModal);
