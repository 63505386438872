import Carousel from '@brainhubeu/react-carousel';
import styled from 'styled-components';

const CarouselComponent = styled(Carousel)`
.BrainhubCarouselItem {
  width: 398px !important;
  max-width: 398px !important;
  min-width: 398px !important;
}
.line-through {
  color: grey;
  text-decoration: line-through;
  margin-right: 16px;
  font-size: 24px;
}
img {
  width: 350px; 
  height:400px;
}
`
export default CarouselComponent