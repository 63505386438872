import React, { memo, useCallback } from 'react';
import { Span, Text, Col, Row, Button, Input, Form } from 'styled';
import { useRouterHistory } from 'hooks/routerHooks';
import { signInApi } from 'services/users';
import { useRequest } from 'hooks/useRequest';
import { useTranslation } from 'react-i18next';

const handleSubmit = (form, makeRequest) => {
  form.validateFields().then((values) => {
    makeRequest(values);
  });
};

const SignIn = () => {
  const [form] = Form.useForm();
  const history = useRouterHistory();
  const onSuccess = useCallback(
    (response) => {
      window.localStorage.setItem('userInfo', response.data);
      if (!!window.sessionStorage.getItem('redirectUrl')) {
        const hasAdditionalProduct =
          history.location?.state?.hasAdditionalProduct;
        const attributeId = history.location?.state?.attributeId;
        history.push(window.sessionStorage.getItem('redirectUrl'), {
          hasAdditionalProduct,
          attributeId,
        });
        return;
      }
      history.push('/');
    },
    [history]
  );

  const { makeRequest } = useRequest({
    request: signInApi,
    initialData: {},
    onSuccess,
  });

  const { t } = useTranslation();

  return (
    <Row style={{ height: '100vh' }}>
      <Form
        form={form}
        onFinish={() => {
          handleSubmit(form, makeRequest);
        }}
        className='login-form  centered'
      >
        <Col span={24} style={{ textAlign: 'center' }}>
          <Text level={4} className='form-title'>
            {t('signIn')}
          </Text>
        </Col>
        <Text level={2} className='please-text'>
          {t('pleaseEnter')}
        </Text>
        <Form.Item
          name='email'
          validateFirst={true}
          validateTrigger='onSubmit'
          rules={[
            {
              required: true,
              message: 'Please input the Email',
              whitespace: true,
            },
            {
              type: 'email',
              message: 'Please input a valid Email',
            },
          ]}
        >
          <Input placeholder={t('email')} />
        </Form.Item>
        <Form.Item
          name='password'
          validateTrigger='onSubmit'
          rules={[
            {
              required: true,
              message: 'Please input the Password',
              whitespace: true,
            },
          ]}
        >
          <Input.Password
            type='password'
            placeholder={t('password')}
            autoComplete='current-password'
          />
        </Form.Item>
        <Row style={{ justifyContent: 'end' }}>
          <Col>
            <Button
              type='link'
              className='no-decoration-button forgot-text'
              onClick={() => history.push('/forgot')}
            >
              {t('forgotPassword')}
            </Button>
          </Col>
        </Row>
        <Form.Item>
          <Button
            type='submit'
            htmlType='submit'
            className='submit-button'
            block
          >
            {t('signIn')}
          </Button>
        </Form.Item>
        <Row style={{ textAlign: 'center' }}>
          <Col span={24}>
            <Span className='have-account-text'>{t('haveAccount')}</Span>
            <Span style={{ marginLeft: '10px' }}>
              <Button
                type='link'
                htmlType='submit'
                className='no-decoration-button have-account-button'
                onClick={() => {
                  history.push('/signup');
                }}
              >
                {t('signUp')}
              </Button>
            </Span>
          </Col>
        </Row>
      </Form>
    </Row>
  );
};

export default memo(SignIn);
