import { Modal as modal, Button as button } from 'antd';
import { useTranslation } from 'react-i18next';
import { Row, Col, Text } from 'styled';
import styled from 'styled-components';
const Modal = styled(modal)`
  .ant-modal-body {
    padding-top: 70px;
  }
  .ant-col {
    justify-content: center;
    text-align: center;
    .phargraph {
      font-size: 22px;
    }
  }
  .home-page-modal-title {
    display: block;
    padding-bottom: 20px;
  }
  .home-page-modal-text {
    display: block;
    padding-bottom: 20px;
  }
  .home-page-modal-img {
    width: 50px;
    padding-bottom: 15px;
  }
  @media only screen and (max-width: 992px) {
    min-width: 360px;
  }
`;

const Button = styled(button)`
  height: 50px;
  width: 160px;
  border: 1px solid #3f2f1e;
  color: #363538;
  margin-top: 40px;

  &:hover {
    color: black;
    border: 1px solid #3f2f1e;
  }
  &:focus {
    color: black;
    box-shadow: none;
    border: 1px solid #3f2f1e;
  }

  @media only screen and (max-width: 1200px) {
    height: 60px;
  }
  @media only screen and (max-width: 992px) {
    height: 50px;
  }
  &.ok-Button {
    background-color: #3f2f1e;
    color: white;
  }
`;

const SuccessfullyDoneModal = ({
  visible,
  onOk,
  closable = true,
  onCancel,
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      visible={visible}
      centered
      closable={closable}
      footer={null}
      onCancel={onCancel}
    >
      <Row>
        <Col span={24}>
          <Text level={3}>{t('thankYou')}</Text>
          <br />
          <br />
          <Text level={1}>{t('orderSuccessful')}</Text>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Button key='submit' className='ok-Button' onClick={onOk}>
            {t('ok')}
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};
export default SuccessfullyDoneModal;
