import { Badge } from 'antd';
import { HeartOutlined } from '@ant-design/icons';

const HeartIcon = ({ history, count }) => {
  const condition = window.localStorage.getItem('userInfo');
  return condition ? (
        <Badge
          id='heart'
          count={count ? count : 0}
          showZero={true}
          style={{
            backgroundColor: '#ffffff',
            color: '#000000',
            fontSize: '12px',
            marginTop: '5px',
            fontWeight: 100,
          }}
          size='small'
        >
          <HeartOutlined
            className='heart'
            style={{ fontSize: '20px', paddingTop: '8px', marginLeft: '30px' }}
            onClick={() => {
              history.push('/favorites');
            }}
          />
        </Badge>
      ) : (
        <HeartOutlined
          style={{ fontSize: '20px', paddingTop: '8px', marginLeft: '30px' }}
          onClick={() => history.push('/signin')}
        />
      )
};
export default HeartIcon;
