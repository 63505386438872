import { useRouterHistory } from 'hooks/routerHooks';
import { memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Tag, Button } from 'styled';
import Context from './Context';

const removeTag = (name, category, tag, setTag, history) => {
  if (category === 'metalIds') {
    setTag([]);
    history.push('/shop?page=1');
    return;
  }
  setTag((t) => t.filter((a) => a.name !== name));
  if (tag.length === 1) {
    history.push('/shop?page=1');
  }
};
const TagSaction = () => {
  const history = useRouterHistory();
  const { setTag, tag } = useContext(Context);
  const { t } = useTranslation();
  return (
    <>
      {tag?.map(({ name, id, category }) => {
        return (
          <Tag
            key={name + id}
            closable
            onClose={() => {
              removeTag(name, category, tag, setTag, history);
            }}
          >
            {name}
          </Tag>
        );
      })}
      {tag?.length ? (
        <Button
          type='link'
          onClick={() => {
            setTag([]);
            history.push('/shop?page=1');
          }}
        >
          {t('reset')}
        </Button>
      ) : null}
    </>
  );
};

export default memo(TagSaction);
