import styled from 'styled-components';
import { Form as form } from 'antd';

const Form = styled(form)`
  &.profile-form {
    padding-top: '20px';
    .ant-typography {
      margin-left: 4px;
    }
    .profile-form-label {
      margin-top: 20px;
    }
    .ant-input,
    .date-of-birth-input {
      margin-top: 10px;
    }
    button {
      width: 180px;
      height: 56px;
      margin: 40px 0;
      &.save-change {
        width: auto;
      }
    }
  }
  &.login-form {
    width: 560px;
    input:-webkit-autofill,
    input:-webkit-autofill:focus {
      transition: background-color 600000s 0s, color 600000s 0s;
    }
    .ant-form-item {
      margin-top: 10px;
      margin-bottom: 0;
    }
    .ant-input {
      font-size: 20px;
      height: 60px;
    }
    .form-title {
      display: block;
      font-size: 30px;
      font-weight: bold;
      letter-spacing: 0px;
      color: #000000;
      font-family: Roboto-Medium;
    }
    @media only screen and (max-width: 1200px) {
      width: 450px;
      .ant-input {
        font-size: 16px;
      }
      .form-title {
        font-size: 28px !important;
        margin-bottom: 0.5em;
      }
    }
    @media only screen and (max-width: 600px) {
      width: 375px;
      .ant-input {
        font-size: 16px;
        height: 40px;
      }
      .form-title {
        font-size: 20px !important;
      }
    }
    .ant-input-password-icon {
      svg {
        width: 24px;
        height: 24px;
      }
    }
    &.centered {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    @media only screen and (max-width: 480px) {
      width: 335px;
    }
  }
`;

/** @component */
export default Form;
