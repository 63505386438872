import { Link } from 'react-router-dom';
import { Col, Divider } from 'antd';
import { Text, Row, Img, Paragraph } from 'styled';

import banner_cropped from 'images/banner_cropped.png';
import BannerWrapper from '../BannerWrapper';
import Wrapper from './style';

//language
import { useTranslation } from 'react-i18next';

const Shipping = () => {
  const { t } = useTranslation();
  return (
    <>
      <BannerWrapper>
        <Row>
          <Col md={24} className='breadcrumb'>
            <Link to='/'>
              <Text level='1' weight='500'>
                {t('home').toUpperCase()}
              </Text>
            </Link>
            <Divider type='vertical' />
            <Text className='title'>
              {t('shippingAndReturn.title').toUpperCase()}
            </Text>
          </Col>
          <Col md={24}>
            <Img src={banner_cropped} className='banner' />
            <Paragraph className='footer-title'>
              {t('shippingAndReturn.title').toUpperCase()}
            </Paragraph>
          </Col>
        </Row>
      </BannerWrapper>
      <Wrapper>
        <Row>
          <Col md={{ span: 22, offset: 1 }} xs={{ span: 22, offset: 1 }}>
            <Text weight='500' level='4' className='title'>
              {t('shippingAndReturn.shipping.title').toUpperCase()}
            </Text>
            <Text className='text'>
              {t('shippingAndReturn.shipping.text_1')}
              <br />
              <br />
              {t('shippingAndReturn.shipping.text_2')}
              <br />
              <br />
              {t('shippingAndReturn.shipping.text_3')}
              <br />
            </Text>
            <Text weight='500' level='4' className='title'>
              {t('shippingAndReturn.fess.title').toUpperCase()}
            </Text>
            <Text className='text'>
              <Row>
                <Col span={22} offset={1}>
                  <ul>
                    <li>{t('shippingAndReturn.fess.1')}</li>
                    <li>{t('shippingAndReturn.fess.2')}</li>
                    <li>{t('shippingAndReturn.fess.3')}</li>
                    <li>{t('shippingAndReturn.fess.4')}</li>
                    <li>{t('shippingAndReturn.fess.5')}</li>
                    <li>{t('shippingAndReturn.fess.6')}</li>
                    <li>{t('shippingAndReturn.fess.7')}</li>
                    <li>{t('shippingAndReturn.fess.8')}</li>
                    <li>{t('shippingAndReturn.fess.9')}</li>
                    <li>{t('shippingAndReturn.fess.10')}</li>
                    <li>{t('shippingAndReturn.fess.11')}</li>
                  </ul>
                </Col>
              </Row>
            </Text>
            <Text weight='500' level='4' className='title'>
              {t('shippingAndReturn.returnRefund.title').toUpperCase()}
            </Text>
            <Text className='text'>
              {t('shippingAndReturn.returnRefund.text_1')}
              <br />
              <br />
              {t('shippingAndReturn.returnRefund.text_2')}
            </Text>
          </Col>
        </Row>
      </Wrapper>
    </>
  );
};
export default Shipping;
