import { memo } from 'react';
import styled from 'styled-components';
import { Text, Row, Col } from 'styled';

const Wrapper = styled(Text)`
  span {
    display: 'block';
    font-size: 20px;
    @media screen and (max-width: 1200px) {
      font-size: 16px;
    }
    @media screen and (max-width: 992px) {
      font-size: 14px;
    }
  }

  .line-through {
    text-align: center;
    color: grey;
    margin-right: 16px;
    text-decoration: line-through;
    @media screen and (max-width: 1100px) {
      margin: 0;
    }
  }
`;

const Promotion = ({ promotion, price, productDetails }) => {
  let promotionPrice = 0;
  if (promotion.type === 1) {
    promotionPrice = +price - promotion.amount;
  } else {
    let percent = (+price / 100) * promotion.amount;
    promotionPrice = price - percent;
  }
  return (
    <>
      <Text
        weight='bold'
        className={
          productDetails ? 'product-details-price line-through' : 'line-through'
        }
      >
        {price}&#1423;
      </Text>
      &nbsp;
      <Text
        weight='bold'
        className={productDetails ? 'product-details-price' : ''}
      >
        {promotionPrice}&#1423;
      </Text>
    </>
  );
};

const Price = ({ promotion, price, productDetails }) => {
  return (
    <Wrapper>
      <Row style={{ justifyContent: 'center' }}>
        <Col span={24}>
          {promotion ? (
            <Promotion
              promotion={promotion}
              price={price}
              productDetails={productDetails}
            />
          ) : (
            <Text
              weight='bold'
              className={productDetails ? 'product-details-price' : ''}
            >
              {price}&#1423;
            </Text>
          )}
        </Col>
      </Row>
    </Wrapper>
  );
};

export default memo(Price);
