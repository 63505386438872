import React from 'react';
import styled from 'styled-components';
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Card,
  Paragraph,
  Text,
  DatePicker,
} from 'styled';
import BackAroww from 'images/back-arrow.svg';
import { useRouterHistory } from 'hooks/routerHooks';

const Image = styled.img`
  display: block;
  width: 60px;
  height: 30px;
`;

const AddNewCardWrapper = styled(Row)`
  .gafri-card {
    width: 100%;
    .ant-card-body {
      .paragraph {
        font-size: 26px !important;
      }
      .text {
        font-size: 22px !important;
      }
      .ant-typography-danger {
        font-size: 12px !important;
      }
      padding: 30px;
      @media screen and (max-width: 1200px) {
        padding: 20px;
        .paragraph {
          font-size: 22px !important;
        }
        .text {
          font-size: 18px !important;
        }
      }
      @media screen and (max-width: 600px) {
        padding: 10px;
        .paragraph {
          font-size: 18px !important;
        }
        .text {
          font-size: 14px !important;
        }
      }
    }
  }
`;

const AddNewCard = () => {
  const [form] = Form.useForm();
  const history = useRouterHistory();

  return (
    <Row style={{ paddingTop: '150px' }}>
      <Col offset={2} span={22}>
        <Button
          className='no-decoration-button'
          type='link'
          onClick={() => {
            history.push('/user/cards');
          }}
        >
          <Image src={BackAroww} style={{ display: 'inline-block' }} />
        </Button>
      </Col>
      <Col
        lg={{ offset: 8, span: 8 }}
        md={{ offset: 6, span: 12 }}
        sm={{ offset: 2, span: 20 }}
      >
        <AddNewCardWrapper>
          <Card className='gafri-card'>
            <Row>
              <Col span={24} style={{ paddingLeft: '10px' }}>
                <Paragraph className='paragraph'>Account description</Paragraph>
                <Text className='text'>
                  Please fill in the following fields to proceed your payment.
                </Text>
              </Col>
              <Form form={form} onFinish={() => {}} className='profile-form'>
                <Col xs={24} className='profile-form-label'>
                  <Text level={1} type='secondary'>
                    Cardholder name*
                  </Text>
                  <Form.Item
                    name='firstName'
                    validateFirst={true}
                    validateTrigger='onSubmit'
                    rules={[
                      {
                        required: true,
                        message: 'Please input the cardholder name',
                        whitespace: true,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>{' '}
                <Col xs={24} className='profile-form-label'>
                  <Text level={1} type='secondary'>
                    Card number*
                  </Text>
                  <Form.Item
                    name='firstName'
                    validateFirst={true}
                    validateTrigger='onSubmit'
                    rules={[
                      {
                        required: true,
                        message: 'Please input the card number',
                        whitespace: true,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>{' '}
                <Col span={24}>
                  <Text level={1} type='secondary'>
                    Valid THRU*
                  </Text>
                </Col>
                <Col span={11} className='profile-form-label'>
                  <Form.Item
                    name='firstName'
                    validateFirst={true}
                    validateTrigger='onSubmit'
                    rules={[
                      {
                        required: true,
                        message: 'Please input the valid THRU',
                        whitespace: true,
                      },
                    ]}
                  >
                    <DatePicker
                      format='MM'
                      mode='month'
                      picker='month'
                      showToday={false}
                    />
                  </Form.Item>{' '}
                </Col>
                <Col span={11} className='profile-form-label'>
                  <Form.Item
                    name='firstName'
                    validateFirst={true}
                    validateTrigger='onSubmit'
                    rules={[
                      {
                        required: true,
                        message: 'Please input the valid THRU',
                        whitespace: true,
                      },
                    ]}
                  >
                    <DatePicker picker='year' />
                  </Form.Item>
                </Col>
                <Col xs={24} className='profile-form-label'>
                  <Text level={1} type='secondary'>
                    CVC2 / VV2*
                  </Text>
                  <Form.Item
                    name='firstName'
                    validateFirst={true}
                    validateTrigger='onSubmit'
                    rules={[
                      {
                        required: true,
                        message: 'Please input the CVC2 / VV2',
                        whitespace: true,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Text type='danger'>
                    To identify your card, you will be charged 10֏. After the
                    process is completed, the amount will be refunded to your
                    account.
                  </Text>
                </Col>
                <Col
                  span={24}
                  style={{
                    textAlign: 'center',
                  }}
                >
                  <Button
                    type='submit'
                    htmlType='submit'
                    className='submit-button'
                  >
                    ATTACH
                  </Button>
                </Col>
              </Form>
            </Row>
          </Card>
        </AddNewCardWrapper>
      </Col>
    </Row>
  );
};
export default AddNewCard;
