import { Row, Text, Paragraph } from 'styled';
import { Col, Modal } from 'antd';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const RestyledListWrapper = styled.ul`
  padding: 0;
  list-style-type: none;
  columns: 2;
  li {
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 10px;
  }
  @media screen and (max-width: 480px) {
    li {
      font-size: 14px;
    }
  }
`;
const ListWrapper = styled.ul`
  padding: 0 18px;
  width: 100%;
  li {
    font-weight: bold;
    margin-top: 12px;
    margin-bottom: 10px;
    font-size: 14px;
  }
  @media screen and (max-width: 480px) {
    li {
      font-size: 12px;
    }
  }
`;

const ModalWrapper = styled(Modal)`
  .ant-modal-body {
    padding: 0px;
  }
  .gafri-map {
    display: block;
    height: 80vh;
    width: 100vw;
  }
  iframe {
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: hidden;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    padding-top: 30px;
    padding-bottom: 24px;
    padding-left: 24px;
    padding-right: 24px;
  }
  .ant-modal-close-x {
    width: 32px;
    height: 32px;
    line-height: 32px;
  }
  .location {
    position: absolute !important;
    bottom: 25px;
    left: 80px;
    background-color: #fff !important;
    width: 400px;
    height: 600px;
    padding: 24px;
  }
  @media screen and (max-width: 992px) {
    margin: 0px;
    .ant-modal-body {
      height: 87vh;
      margin-top: -28px;
      margin-bottom: -24px;
    }
    iframe {
      padding-top: 0px;
      padding-bottom: 0px;
      padding-left: 0px;
      padding-right: 0px;
      border-width: 0px;
    }
    .ant-modal-close-x {
      color: white;
      width: 22px;
      height: 22px;
      line-height: 22px;
      font-size: 20px;
      margin-top: 11px;
    }
    .location {
      position: absolute !important;
      bottom: 0% !important;
      left: 0px;
      background-color: #fff !important;
      height: 200px;
      padding: 0px 0px 0px 24px;
      margin-bottom: 10px;
    }
    .info {
      font-size: 14px;
    }
  }
`;

const MapModalContext = ({ visible, setVisible }) => {
  const { t } = useTranslation();
  return (
    <ModalWrapper
      width='100%'
      closable
      title={null}
      visible={visible}
      footer={null}
      onCancel={() => setVisible(false)}
    >
      <Row justify='center'>
        <Col className='gafri-map'>
          <iframe
            title='map'
            width='650'
            height='300'
            frameborder='0'
            scrolling='no'
            marginheight='0'
            marginWidth='0'
            src='https://www.google.com/maps/d/u/0/embed?mid=1eEuhlZW2tkwNKOAOn4yszR_T7_hMomXt'
          />
        </Col>
        <Col className='location'>
          <Paragraph
            level={window.innerWidth >= 480 ? 3 : 2}
            style={{ fontWeight: 'bold', margin: 0 }}
          >
            {t('gafriShowroom')}
          </Paragraph>
          <Text className='info'>{t('address')}: </Text>
          <ListWrapper>
            <Col md={24} xs={24}>
              <li>{t('address_1')}</li>
              <li>{t('address_2')}</li>
            </Col>
          </ListWrapper>
          <Text className='info'>{t('phoneNumber')}: </Text>
          <ListWrapper>
            <li>(+374)60 50 99 90</li>
          </ListWrapper>
          <Col>
            <Text className='info'>{t('openingHours')}: </Text>
            <RestyledListWrapper>
              <li>Monday</li>
              <li>Tuesday</li>
              <li>Wednesday</li>
              <li>Thursday</li>
              <li>Friday</li>
              <li>Saturday</li>
              <li>Sunday</li>
              <li>11:00 - 19:00</li>
              <li>11:00 - 19:00</li>
              <li>11:00 - 19:00</li>
              <li>11:00 - 19:00</li>
              <li>11:00 - 19:00</li>
              <li>11:00 - 19:00</li>
              <li>11:00 - 19:00</li>
            </RestyledListWrapper>
          </Col>
        </Col>
      </Row>
    </ModalWrapper>
  );
};
export default MapModalContext;
