import React, { Fragment, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Img, Paragraph, Col } from 'styled';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { productBestSellersGet } from 'services/product';
import { useGetRequest } from 'hooks/useGetRequest';
import { useRouterHistory } from 'hooks/routerHooks';
import styled from 'styled-components';
import Price from 'components/Shop/Price';

const Wrapper = styled.div`
  margin-top: 150px;
  .title {
    font-size: 32px !important;
  }
  .item-title {
    color: #363538;
  }
  .slick-initialized .slick-slide {
    text-align: center;
    cursor: pointer;
  }
  .slick-prev {
    left: 4% !important;
    width: 60px;
    height: 60px;
    z-index: 10;
    :before {
      font-size: 60px;
      color: black;
    }
  }
  .slick-next {
    right: 6% !important;
    width: 60px;
    height: 60px;
    z-index: 1;
    :before {
      font-size: 60px;
      color: black;
    }
  }
  @media screen and (max-width: 992px) {
    .slick-next,
    .slick-prev {
      :before {
        display: none;
      }
    }
    .slick-slide {
      padding: 0 10px;
    }
  }
  @media screen and (max-width: 480px) {
    margin-top: 0px;
    padding-bottom: 70px;
    .product {
      width: auto !important;
    }
    img {
      background-color: #fff;
      width: 220px;
    }
    .title {
      font-size: 30px !important;
    }
  }
`;

const Bestsellers = () => {
  const history = useRouterHistory();

  const { data } = useGetRequest({
    request: productBestSellersGet,
    initialData: { data: [] },
  });

  const settings = {
    infinite: data.data.length >= 5 ? true : false,
    speed: 500,
    slidesToShow: 4.5,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const { t } = useTranslation();

  return (
    <Fragment>
      {data.data.length ? (
        <Wrapper>
          <Row justify='space-between'>
            <Col push={1}>
              <Paragraph className='title'>
                {t('bestsellers').toUpperCase()}
              </Paragraph>
            </Col>
          </Row>
          <Slider {...settings}>
            {data?.data?.map((item) => (
              <div
                className='product'
                key={item.id}
                onClick={() => history.push(`/shop/product-details/${item.id}`)}
              >
                <Img
                  src={item.defaultImage}
                  onMouseOver={(e) => {
                    e.currentTarget.src = item.secondaryImage;
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.src = item.defaultImage;
                  }}
                  className='img'
                  style={{
                    display: 'inline-block',
                    position: 'relative',
                  }}
                />
                <Paragraph level='3' className='item-title' $noMargin>
                  {item?.title}
                </Paragraph>
                <Price price={item?.price} promotion={item?.promotion} />
              </div>
            ))}
          </Slider>
        </Wrapper>
      ) : null}
    </Fragment>
  );
};
export default memo(Bestsellers);
