import styled from 'styled-components';

export const StoresWrapper = styled.div`
  margin: 0 20px;
  .desc-text {
    font-family: Roboto-Regular !important;
    font-weight: normal !important;
    text-align: center;
    padding: 0 180px;
    margin-top: 24px;
    margin-bottom: 36px;
    font-size: 22px !important;
    @media screen and (max-width: 1200px) {
      padding: 0 80px;
      margin-bottom: 26px;
      font-size: 18px;
    }
    @media screen and (max-width: 992px) {
      padding: 0 40px;
      margin-bottom: 26px;
      font-size: 16px;
    }
  }
  .shop-name {
    margin-bottom: 24px;
    font-size: 24px !important;
    font-weight: 300;
  }
  .info {
    color: grey;
    font-size: 20px;
  }
  .shop-image {
    height: 480px;
    width: 100%;
    object-fit: fill;
  }
  .shop-back {
    background: linear-gradient(120deg, #fafafa, #dbd6d0);
    margin: 0 60px;
    @media screen and (max-width: 480px) {
      margin: 0px;
      background: #f3f1f0;
      margin-bottom: 20px;
    }
    @media screen and (max-width: 992px) {
      margin: 0px;
    }
  }
  .ant-btn {
    min-height: 50px !important;
  }
  .info-section {
    margin-top: 55px;
    padding: 0 16px;
  }
  .web-button {
    display: block;
  }
  .mob-button {
    display: none;
  }

  @media screen and (max-width: 480px) {
    margin: 0 30px;
    margin-bottom: 100px;
    .desc-text {
      padding: 0;
      margin-top: 24px;
      margin-bottom: 32px;
      text-align: center;
      font-size: 20px !important;
    }
    .store {
      font-size: 24px !important;
    }

    .shop-image {
      height: 240px;
      width: 100%;
      object-fit: fill;
    }

    .info-section {
      margin-top: 20px !important;
    }
    .info {
      font-size: 16px !important;
    }
    .web-button {
      display: none;
    }
    .mob-button {
      display: block;
      margin-bottom: 42px;
    }
  }
`;

export const RestyledListWrapper = styled.ul`
  font-family: Roboto-Medium !important;
  font-weight: normal !important;
  padding: 0;
  list-style-type: none;
  columns: 2;

  li {
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 10px;
  }
  @media screen and (max-width: 480px) {
    li {
      font-size: 14px;
    }
  }
`;
export const ListWrapper = styled.ul`
  padding: 0;
  font-family: Roboto-Medium;
  list-style-type: none;
  li {
    font-weight: normal;
    margin-top: 12px;
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 18px;
    &:before {
      content: '·';
      font-size: 50px;
      vertical-align: middle;
    }
  }
  @media screen and (max-width: 480px) {
    li {
      font-size: 14px;
      &:before {
        content: '· ';
        font-size: 50px;
      }
    }
  }
`;
