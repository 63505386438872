import client from './client';

//PersonalInformation
export const profileApi = (data) => client().get('User/Profile', data);

export const updatProfileApi = (data) => client().put('User/Update', data);

export const updatPasswordApi = (data) =>
  client().put('User/UpdatePassword', data);

//address
export const getAllAddressApi = () => client().get('Address/GetAll');

export const createAddressApi = (data) => client().post('Address/Create', data);

export const deleteAddressApi = ({ id }) =>
  client().delete(`Address/Delete/${id}`);

export const updatAddressApi = (data) => client().put('Address/Update', data);

export const getAddressApi = ({ id }) => client().get(`Address/Details/${id}`);

export const getDefaultAddressApi = () => client().get(`Address/Default`);

export const setAsDefaultApi = ({ id }) =>
  client().put(`Address/setAsDefault/${id}`);
//Card
