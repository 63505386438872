import client from './client';

export const signInApi = (data) => client().post('/User/Login', data);

//signup
export const signUpApi = (data) => client().post('/User/Register', data);

export const verifyUserRegistrationCodeApi = (data) =>
  client().post('User/VerifyUserRegistrationCode', data);

export const createAccountApi = (data) =>
  client().post('/User/SetPassword', data);
//forgot
export const forgotApi = (data) =>
  client().post('/User/SendForgotPasswordCode', data);

export const emailVerificationApi = (data) =>
  client().post('/User/VerifyForgotPasswordCode', data);
export const newPasswordForForgotApi = (data) =>
  client().post('/User/SetPassword', data);
