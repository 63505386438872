import styled from 'styled-components';
import { Badge as badge } from 'antd';

const Badge = styled(badge)`
.ant-badge-count{
  border-radius: 0px;
  background-color: #F4EDE7;
  color: black;
  font-size: 20px;
  padding: 32px 6px 25px;
}
@media screen and (max-width: 480px) {
  .ant-badge-count{
    font-size: 16px;
    top: 30px;
    right: 35px;
    padding: 4px 6px 24px 8px !important;
  }
}
`;

/** @component */
export default Badge;