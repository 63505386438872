import React, { useState, Fragment } from 'react';
import { metalGet } from 'services/metal';
import { useGetRequest } from 'hooks/useGetRequest';
import { useRouterHistory } from 'hooks/routerHooks';
import { Col, Drawer, Divider } from 'antd';
import { Img, Button, Row } from 'styled';
import {
  MenuOutlined,
  CloseOutlined,
  LeftOutlined,
  // ShoppingOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import HeartIcon from './HeartIcon';
import ChangeLanguage from './ChangeLanguage';

import gafri from 'images/gafri.svg';
import UserMobileMenu from './UserMobileMenu';

const MobileHeaderMenu = ({ count, reFetch }) => {
  const history = useRouterHistory();

  const [visible, setVisible] = useState(false);
  const [list, setList] = useState(false);

  const { t } = useTranslation();

  const { data } = useGetRequest({
    request: metalGet,
    initialData: {},
    onSuccess: reFetch,
  });

  return (
    <Row>
      <Col lg={0} xs={24}>
        <Row
          style={{
            background: visible ? 'white' : '#F4EDE7',
            height: '80px',
          }}
        >
          <Col xs={3}>
            {!visible ? (
              <MenuOutlined onClick={() => setVisible(true)} className='icon' />
            ) : (
              <CloseOutlined
                onClick={() => setVisible(false)}
                className='icon'
                style={{ display: list ? 'none' : 'inline-block' }}
              />
            )}
            {list ? (
              <LeftOutlined onClick={() => setList(false)} className='icon' />
            ) : null}
          </Col>
          <Col xs={15} md={17} lg={0} className='center'>
            <Img
              src={gafri}
              className='logo'
              onClick={() => history.push('/')}
            />
          </Col>
          <Col xs={6} md={4} lg={0} className='icons'>
            <Row>
              <Col xs={8} md={12}>
                <ChangeLanguage />
              </Col>
              <Col xs={8} md={12}>
                {' '}
                <HeartIcon
                  history={history}
                  count={count}
                  style={{ fontSize: '20px' }}
                />
              </Col>
            </Row>

            {/* <ShoppingOutlined
                style={{ fontSize: '20px', marginLeft: '10px' }}
              /> */}
          </Col>
        </Row>
        <Drawer
          className='drawer-close-tune'
          width='100%'
          closable={false}
          placement='left'
          onClose={() => setVisible(false)}
          visible={visible}
        >
          <Row style={{ paddingBottom: '20px' }}>
            <Col span={21}>
              <Button
                type='link'
                className='no-decoration-button'
                style={{
                  fontSize: '20px',
                  paddingBottom: '10px',
                }}
                onClick={(e) => {
                  setVisible(false);
                }}
              >
                <LeftOutlined />
              </Button>
            </Col>
            <Col span={3}>
              <HeartIcon history={history} count={count} />
            </Col>
          </Row>
          {data?.data?.map((item) => (
            <Fragment key={item?.id}>
              <Row justify='space-between'>
                <Col
                  style={{ fontSize: '21px', cursor: 'pointer' }}
                  onClick={() => {
                    window.localStorage.setItem('tag', JSON.stringify([]));
                    setVisible(false);
                    history.push(
                      `/shop?metalId=${
                        item?.id
                      }&key=${item?.title?.toLowerCase()}&page=1`
                    );
                  }}
                >
                  {item?.title}
                </Col>
              </Row>
              <Divider />
            </Fragment>
          ))}
          <Row justify='space-between'>
            <Col
              style={{ fontSize: '21px', cursor: 'pointer' }}
              onClick={() => {
                window.localStorage.setItem('tag', JSON.stringify([]));
                setVisible(false);
                history.push(`/shop?key=new&page=1`);
              }}
            >
              {t('new').toUpperCase()}
            </Col>
          </Row>
          <Divider />
          <Row justify='space-between'>
            <Col
              style={{ fontSize: '21px', cursor: 'pointer' }}
              onClick={() => {
                window.localStorage.setItem('tag', JSON.stringify([]));
                setVisible(false);
                history.push(`/shop?key=sale&page=1`);
              }}
            >
              {t('sale').toUpperCase()}
            </Col>
          </Row>
          <Divider />
          <UserMobileMenu history={history} setVisible={setVisible} />
        </Drawer>
      </Col>
    </Row>
  );
};

export default MobileHeaderMenu;
