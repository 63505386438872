
import styled from 'styled-components';
import { Checkbox as checkbox } from 'antd';

const Checkbox = styled(checkbox)`
  margin-top: 12px;
  width: 100%;
  .ant-checkbox-group {
    display: grid;
  };

  .ant-checkbox-wrapper  {
    font-size: 24px;
    margin-left: 30px;
    border-color: #eecbad;
  };
  .ant-checkbox-checked::after {
    border-color: #eecbad;
  }
  .ant-checkbox:hover {
    border-color: #eecbad;
  }
.ant-checkbox-wrapper {
    width: 100%;
  }
  .ant-checkbox + span{
    margin: 0;
    display: flex;
    width: 100%;
    border-color: #eecbad;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #eecbad;
    border-color: #eecbad;
  }
`;

/** @component */
export default Checkbox;