import { Text, Button, Paragraph, Img } from 'styled';
import banner_for_home_page from 'images/banner_for_home_page.png';
import styled from 'styled-components';
import { useRouterHistory } from 'hooks/routerHooks';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  margin-top: 150px;
  position: relative;
  min-height: 446px;
  img {
    min-height: 446px;
    object-fit: cover;
  }
  .text-block {
    position: absolute;
    top: 25%;
    left: 6%;
    @media only screen and (max-width: 992px) {
      top: 10%;
    }
    @media only screen and (max-width: 480px) {
      top: 6%;
      left: 5%;
    }
  }
  .ant-btn {
    border: 1px solid white;
    color: white;
    width: 220px;
    min-height: 50px;
    padding: 0px;
    background: transparent !important;
  }
  .ant-btn:hover {
    border-bottom: 1px solid white;
  }
  .ant-btn :focus {
    border-bottom: 1px solid white;
  }
  .text {
    color: white;
    width: 325px;
    margin-top: 23px;
    font-size: 24px;
  }
  .title {
    color: white;
    font-size: 45px;
  }
  @media only screen and (max-width: 992px) {
    .title {
      font-size: 24px;
    }
  }
  @media only screen and (max-width: 480px) {
    margin-top: 0px;
    min-height: 350px;
    img {
      min-height: 350px;
    }
    .title {
      font-size: 20px;
    }
    .text {
      width: 225px;
    }
    .ant-btn {
      width: 120px;
    }
  }
`;

const SmallSection = () => {
  const history = useRouterHistory();
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Img src={banner_for_home_page} />
      <div className='text-block'>
        <Text className='title'>{t('fillYour')}</Text>
        <Paragraph className='text'>{t('youCan')} </Paragraph>
        <Button
          onClick={() => {
            window.localStorage.setItem('tag', JSON.stringify([]));
            history.push('/shop?metalId=1&key=gold&page=1');
          }}
        >
          {t('explore').toUpperCase()}
        </Button>
      </div>
    </Wrapper>
  );
};

export default SmallSection;
