import { Result } from 'antd';
import { useQueryParams, useRouterHistory } from 'hooks/routerHooks';
import { Button } from 'styled';

const GetErrorPage = () => {
  const { type } = useQueryParams();
  const history = useRouterHistory();
  switch (type) {
    case '403':
      return (
        <Result
          style={{ paddingTop: '150px' }}
          status='403'
          title='403'
          subTitle='Sorry, you are not authorized to access this page.'
          extra={
            <Button
              type='submit'
              className='submit-button'
              onClick={() => history.push('/')}
            >
              Back Home
            </Button>
          }
        />
      );
    case '404':
      return (
        <Result
          style={{ paddingTop: '150px' }}
          status='404'
          title='404'
          subTitle='Sorry, the page you visited does not exist.'
          extra={
            <Button
              type='submit'
              className='submit-button'
              onClick={() => history.push('/')}
            >
              Back Home
            </Button>
          }
        />
      );
    case '500':
      return (
        <Result
          style={{ paddingTop: '150px' }}
          status='500'
          title='500'
          subTitle='Sorry, something went wrong.'
          extra={
            <Button
              type='submit'
              className='submit-button'
              onClick={() => history.push('/')}
            >
              Back Home
            </Button>
          }
        />
      );
    case 'network-error':
      return (
        <Result
          style={{ paddingTop: '150px' }}
          status='warning'
          title='There are problems with your internet.'
          extra={
            <Button
              type='submit'
              className='submit-button'
              onClick={() => history.push('/')}
            >
              Back Home
            </Button>
          }
        />
      );

    default:
      return (
        <Result
          style={{ paddingTop: '150px' }}
          status='404'
          title='404'
          subTitle='Sorry, the page you visited does not exist.'
          extra={
            <Button
              type='submit'
              className='submit-button'
              onClick={() => history.push('/')}
            >
              Back Home
            </Button>
          }
        />
      );
  }
};

const NotFound = () => {
  return <GetErrorPage />;
};

export default NotFound;
