import { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { Divider, Col } from 'antd';
import { Img, Text, Row, Button, Paragraph } from 'styled';
import stonesTop from 'images/stones-top.jpg';
import GafriShopMoskovyan from 'images/gafri-shop-moskovyan.png';
import GafriShopSargsyan from 'images/gafri-shop-sargsyan.jpg';
import Wrapper from '../BannerWrapper/index';
import MapModalContext from '../../MapModalContext';
import { RestyledListWrapper, StoresWrapper, ListWrapper } from './style';

//language
import { useTranslation } from 'react-i18next';

const WorkTimeMoskovyan = ({ t }) => (
  <Col md={10} xs={24}>
    <Text className='info'>{t('openingHours')}: </Text>
    <RestyledListWrapper>
      <li>Monday</li>
      <li>Tuesday</li>
      <li>Wednesday</li>
      <li>Thursday</li>
      <li>Friday</li>
      <li>Saturday</li>
      <li>Sunday</li>
      <li>11:00 - 20:00</li>
      <li>11:00 - 20:00</li>
      <li>11:00 - 20:00</li>
      <li>11:00 - 20:00</li>
      <li>11:00 - 20:00</li>
      <li>11:00 - 20:00</li>
      <li>11:00 - 19:00</li>
    </RestyledListWrapper>
  </Col>
);
const WorkTimeSargsyan = ({ t }) => (
  <Col md={10} xs={24}>
    <Text className='info'>{t('openingHours')}: </Text>
    <RestyledListWrapper>
      <li>Monday</li>
      <li>Tuesday</li>
      <li>Wednesday</li>
      <li>Thursday</li>
      <li>Friday</li>
      <li>Saturday</li>
      <li>Sunday</li>
      <li>10:00 - 17:30</li>
      <li>10:00 - 17:30</li>
      <li>10:00 - 17:30</li>
      <li>10:00 - 17:30</li>
      <li>10:00 - 17:30</li>
      <li>10:00 - 17:30</li>
      <li>10:00 - 17:30</li>
    </RestyledListWrapper>
  </Col>
);

const Stores = () => {
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();
  return (
    <Fragment>
      <Wrapper>
        <Row>
          <Col md={24} className='breadcrumb'>
            <Link to='/'>
              <Text level='1' weight='bold'>
                {t('home').toUpperCase()}
              </Text>
            </Link>
            <Divider type='vertical' />
            <Text className='title'>{t('ourStores').toUpperCase()}</Text>
          </Col>
          <Col md={24}>
            <Img src={stonesTop} className='banner' />
            <Paragraph className='footer-title'>
              {t('ourLocation').toUpperCase()}
            </Paragraph>
          </Col>
        </Row>
      </Wrapper>
      <StoresWrapper>
        <Row>
          <Col md={{ span: 24, offset: 0 }} xs={{ span: 20, offset: 2 }}>
            <Paragraph className='desc-text'>{t('currently')}</Paragraph>
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 23, offset: 1 }} xs={24}>
            <Paragraph className='store'>
              {t('ourStores').toUpperCase()}
            </Paragraph>
          </Col>
        </Row>
        <Row className='shop-back'>
          <Col lg={{ span: 12, order: 1 }} xs={{ span: 24, order: 2 }}>
            <Img src={GafriShopMoskovyan} className='shop-image' />
          </Col>
          <Col xs={{ span: 24, order: 1 }} lg={{ span: 12, order: 2 }}>
            <Row className='info-section'>
              <Col xs={24}>
                <Paragraph className='shop-name'>Gafri shop</Paragraph>
              </Col>
              <Col md={12}>
                <Text type='secondary' className='info'>
                  {t('address')}:{' '}
                </Text>
                <ListWrapper>
                  <li>{t('address_1')}</li>
                </ListWrapper>
                <Text className='info'>{t('phoneNumber')}: </Text>
                <ListWrapper>
                  <li>(+374)10 56 04 46</li>
                  <li> (+374)55 85 99 99</li>
                </ListWrapper>
                <Button className='web-button' onClick={() => setVisible(true)}>
                  {t('findOnMap').toUpperCase()}
                </Button>
              </Col>
              <WorkTimeMoskovyan t={t} />
              <Button className='mob-button' onClick={() => setVisible(true)}>
                {t('findOnMap').toUpperCase()}
              </Button>
            </Row>
          </Col>
        </Row>
        <Row className='shop-back' style={{ marginTop: '20px' }}>
          <Col lg={{ span: 12, order: 1 }} xs={{ span: 24, order: 2 }}>
            <Img src={GafriShopSargsyan} className='shop-image' />
          </Col>
          <Col xs={{ span: 24, order: 1 }} lg={{ span: 12, order: 2 }}>
            <Row className='info-section'>
              <Col xs={24}>
                <Paragraph className='shop-name'>Gafri showroom</Paragraph>
              </Col>
              <Col md={12}>
                <Text type='secondary' className='info'>
                  {t('address')}:
                </Text>
                <ListWrapper>
                  <li>{t('address_2')}</li>
                </ListWrapper>
                <Text className='info'>{t('phoneNumber')}: </Text>
                <ListWrapper>
                  <li> (+374)91 50 01 10</li>
                  <li> (+374)60 50 99 90</li>
                </ListWrapper>
                <Button className='web-button' onClick={() => setVisible(true)}>
                  {t('findOnMap').toUpperCase()}
                </Button>
              </Col>
              <WorkTimeSargsyan t={t} />
              <Button className='mob-button'>
                {t('findOnMap').toUpperCase()}
              </Button>
            </Row>
          </Col>
        </Row>
        <MapModalContext visible={visible} setVisible={setVisible} />
      </StoresWrapper>
    </Fragment>
  );
};
export default Stores;
