import styled from 'styled-components';

const CheckOutWrapper = styled.div`
  margin-top: 150px;
  img {
    width: 30px !important;
  }
  .radio-button-text {
    display: inline-block;
    align-items: 'center';
  }
  input {
    font-size: 18px;
    height: 50px;
  }
  .ant-select-selector {
    height: 50px !important;
    font-size: 18px;
    align-items: center;
    input {
      height: 50px !important;
    }
  }
  .checkout-button {
    margin: 0 !important;
  }
  .check-out-form {
    background-color: #fbfbfb;
    padding: 16px;
  }
  .subtotal {
    .ant-card {
      background-color: #f4ede7;
    }
    .price {
      text-align: end;
      padding: 5px 0;
    }
  }
  img {
    width: 40px;
    margin-right: 15px;
    margin-left: 15px;
    display: inline-block;
  }
  .ant-radio-checked .ant-radio-inner {
    border-color: #000000 !important ;
  }

  .ant-radio-checked .ant-radio-inner:after {
    background-color: #000000;
  }

  .ant-radio:hover .ant-radio-inner {
    border-color: #000000;
  }
  .ant-radio:disabled .ant-radio-inner {
    border-color: #000000;
  }
  .ant-radio-inner {
    border-color: #000000;
  }
  .ant-radio-wrapper {
    .ant-typography {
      padding-top: 5px;
    }
  }
  .in-store-icon {
    width: 30px !important;
    height: 25px !important;
  }
  @media screen and (max-width: 1200px) {
    .radio-button-text {
      font-size: 16px;
    }
    .radio-input {
      margin-bottom: 20px;
    }
  }
  @media screen and (max-width: 992px) {
    .label {
      font-size: 12px;
    }
  }
`;

export default CheckOutWrapper;
