import styled from 'styled-components';

const HeaderNavWrapper = styled.div`
  position: fixed;
  max-width: 1992px;
  z-index: 100;
  background-color: #f4ede7;
  width: 100%;
  padding-bottom: 5px;
  top: 0px;
  .ant-select:not(.ant-select-customize-input) {
    .ant-select-selector {
      padding: 0px;
      text-align: end;
      padding-top: 3px;
      font-size: 18px;
      background-color: #f4ede7;
      border: none;
      @media only screen and (max-width: 992px) {
        text-align: start;
        padding-top: 2px;
      }
    }
  }
  .ant-select-focused .ant-select-selector,
  .ant-select-selector:focus,
  .ant-select-selector:active,
  .ant-select-open .ant-select-selector {
    box-shadow: none !important;
  }
  .center {
    text-align: center;
  }
  .heart {
    font-size: 20px;
  }
  .ant-drawer-close {
    position: inline !important;
  }
  .ant-typography {
    font-family: Futura;
    cursor: pointer;
    margin-left: 24px;
  }
  .icon {
    font-size: 22px;
    margin-top: 20px;
    margin-left: 23px;
  }
  .ant-drawer-body {
    padding: 0px;
  }
  .ant-row {
    align-items: center;
    @media screen and (max-width: 992px) {
      align-items: center;
      padding: 0 20px;
    }
  }
  .logo {
    display: initial;
    width: 100px;
    height: 40px;
    margin-top: 20px;
    margin-left: 16px;
  }

  .selected-menu-line {
    display: inline-block;
    margin-bottom: 25px;
  }
  .menu-item {
    font-size: 18px;
    display: inline-block;
    padding-top: 10px;
    padding-left: 30px;
    span {
      margin: 0;
    }
  }
  @media screen and (max-width: 480px) {
    .display-none {
      display: none;
    }
    .ant-row {
      align-items: center;
      padding: 0;
    }
  }
`;

export default HeaderNavWrapper;
