import { useState, useEffect, useMemo, memo } from 'react';
import { Link } from 'react-router-dom';
import { Divider, Col, Menu, Dropdown, Spin } from 'antd';
import { Row, Text, Img, Paragraph } from 'styled';
import ProductList from './ProductList';
import { useGetRequest } from 'hooks/useGetRequest';
import { productFilterGet } from 'services/product';
import { useQueryParams } from 'hooks/routerHooks';
import ShopWrapper from './styled/ShopWrapper';
import FiltersComponent from './partials/FiltersComponent';
import FilterModal from './partials/FilterModal';
import filter_icon from 'images/filter_icon.svg';
import banner from './baner';
import GoldBanner from './GoldBanner';
import TagSaction from './TagSaction';

//language
import { useTranslation } from 'react-i18next';
import Context from './Context';
const sortList = [
  { id: 1, title: 'sortPrice' },
  { id: 2, title: 'Price: High to low' },
];

const MobFilters = memo(
  ({ setVisible, setSortListItem, sortListItem, setFilter }) => {
    const { t } = useTranslation();

    return (
      <Col xs={24} style={{ background: '#F4EDE7', padding: '24px' }}>
        <Row justify='space-between' alignItems='center'>
          <Col
            onClick={() => setVisible(true)}
            style={{ display: 'flex', cursor: 'pointer' }}
          >
            <Img
              src={filter_icon}
              width={20}
              height={20}
              style={{ marginTop: '4px' }}
            />
            <Text>{t('filter')} </Text>
          </Col>
          <Col style={{ display: 'flex', cursor: 'pointer' }}>
            <Dropdown
              trigger='click'
              size='small'
              overlay={() => (
                <Menu>
                  {sortList.map((item) => (
                    <Menu.Item
                      key={item.id}
                      onClick={() => {
                        setSortListItem(() => t(item.title));
                        setFilter((filter) => {
                          return { ...filter, sortBy: item.id };
                        });
                      }}
                    >
                      {t(item.title)}
                    </Menu.Item>
                  ))}
                </Menu>
              )}
            >
              <Text level='1' style={{ cursor: 'pointer' }}>
                <span style={{ color: '#797979' }}>{t('sortBy')}:</span>
                &nbsp;{t(sortListItem)}
              </Text>
            </Dropdown>
          </Col>
        </Row>
      </Col>
    );
  }
);

const Shop = ({ reFetch }) => {
  const { t } = useTranslation();
  const tagsLocalStorage = window.localStorage.getItem('tag') || [];

  const sortList = [
    { id: 1, title: 'sortPrice' },
    { id: 2, title: 'Price: High to low' },
  ];

  const { metalId, key, categoryId, collectionId, page } = useQueryParams();
  const [visible, setVisible] = useState(false);
  const [tag, setTag] = useState(JSON.parse(tagsLocalStorage) || []);
  const [sortListItem, setSortListItem] = useState(sortList[0].title);

  const { data } = useGetRequest({
    request: productFilterGet,
    initialData: {},
  });

  const initialData = useMemo(() => {
    return {
      withPromotions: key === 'sale' ? true : false,
      whatsNew: key === 'new' ? true : false,
      sortBy: 2,
      paging: {
        page: 1,
        count: 18,
      },
      metalIds: metalId ? [+metalId] : [],
      categoryIds: categoryId ? [+categoryId] : [],
      collectionIds: collectionId ? [+collectionId] : [],
      genders: [],
    };
  }, [key, metalId, categoryId, collectionId]);

  const [filter, setFilter] = useState(initialData);

  useEffect(() => {
    if (data?.data?.metals && JSON.parse(tagsLocalStorage).length === 0) {
      setTag(() => {
        let tag = [];
        if (metalId) {
          const selected = data?.data?.metals?.find(
            ({ id }) => +id === +metalId
          );
          tag = [{ category: 'metalIds', name: selected?.title, ...selected }];
        }
        if (categoryId) {
          const selected = data?.data?.categories?.find(
            ({ id }) => id === +categoryId
          );
          tag = [
            ...tag,
            { category: 'categoryIds', name: selected?.title, ...selected },
          ].filter((v, i, a) => a.findIndex((t) => t.name === v.name) === i);
        }

        if (collectionId) {
          const selected = data?.data?.collections?.find(
            ({ id }) => id === +collectionId
          );
          tag = [
            ...tag,
            {
              category: 'collectionIds',
              name: selected?.title,
              ...selected,
            },
          ].filter((v, i, a) => a.findIndex((t) => t.name === v.name) === i);
        }
        return tag;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key, data, metalId, categoryId, collectionId]);

  useEffect(() => {
    window.localStorage.setItem('tag', JSON.stringify(tag));
    if (tag.length !== 0) {
      const newFilterData = {
        metalIds: [],
        categoryIds: [],
        collectionIds: [],
      };
      setFilter((f) => {
        tag.forEach((e) => {
          newFilterData[e.category] = [
            ...new Set([...newFilterData[e.category], e.id]),
          ];
        });

        return {
          ...f,
          ...newFilterData,
          paging: { page: +page, count: 18 },
          withPromotions: key === 'sale' ? true : false,
          whatsNew: key === 'new' ? true : false,
        };
      });
      return;
    }

    if (!key) {
      setFilter(initialData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tag]);

  const a = banner(t).find((item) => item.key === key);

  return (
    <Spin spinning={!data?.data}>
      <ShopWrapper>
        <Context.Provider value={{ setTag, filter, setFilter, tag }}>
          <Row>
            <Col md={24} className='breadcrumb'>
              <Link to='/'>
                <Text level='1' weight='bold'>
                  {t('home').toUpperCase()}
                </Text>
              </Link>
              <Divider type='vertical' />
              <Text className='page-name'>GAFRI JEWELLERY</Text>
            </Col>
            <Col md={24}>
              <Row>
                <Col className='title-div'>
                  <Paragraph className='title-product'>{a?.title}</Paragraph>
                  <Text className='text'>{a?.desc}</Text>
                </Col>
                <Col
                  md={{ span: 5, order: 1 }}
                  xs={{ span: 24, order: 2 }}
                  style={{ background: '#F4EDE7' }}
                ></Col>
                <Col
                  md={{ span: 19, order: 2 }}
                  xs={{ span: 24, order: 1 }}
                  className='img-hover-zoom--slowmo'
                >
                  <Img
                    src={a?.img.props.src}
                    className={a?.img.props.className}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row
            justify='space-between'
            style={{ marginTop: '50px' }}
            className='filter-sort'
          >
            <Col>
              <Text level='4'>{t('filter').toUpperCase()}</Text>
            </Col>
            <Col style={{ maxWidth: '800px' }}>
              <TagSaction />
            </Col>
            <Col>
              <Dropdown
                trigger='click'
                size='small'
                overlay={() => (
                  <Menu>
                    {sortList.map((element) => (
                      <Menu.Item
                        key={element.id}
                        onClick={() => {
                          setSortListItem(element.title);
                          setFilter((filter) => {
                            return { ...filter, sortBy: element.id };
                          });
                        }}
                      >
                        {t(element.title)}
                      </Menu.Item>
                    ))}
                  </Menu>
                )}
              >
                <Text level='2' style={{ cursor: 'pointer' }}>
                  <span style={{ color: '#797979' }}>{t('sortBy')}:</span>
                  &nbsp;{t(sortListItem)}
                </Text>
              </Dropdown>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={0}>
              <MobFilters
                setVisible={setVisible}
                setSortListItem={setSortListItem}
                setFilter={setFilter}
                sortListItem={sortListItem}
              />
            </Col>
            <FilterModal
              visible={visible}
              setFilter={setFilter}
              setVisible={setVisible}
              data={data}
            />
            <Col xs={0} sm={0} md={7} lg={5}>
              <FiltersComponent data={data} setFilter={setFilter} />
            </Col>
            <Col md={{ span: 16, offset: 1 }} lg={{ span: 18, offset: 1 }}>
              <ProductList
                setFilter={setFilter}
                reFetch={reFetch}
                filter={filter}
                tag={tag}
              />
            </Col>
          </Row>
          {key === 'gold' ? <GoldBanner /> : null}
        </Context.Provider>
      </ShopWrapper>
    </Spin>
  );
};

export default memo(Shop);
