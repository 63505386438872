import React from 'react';
import { Form, Input, Button, Row, Col, Text, Span } from 'styled';
import { useRouterHistory } from 'hooks/routerHooks';
import { emailVerificationApi } from 'services/users';
import { useRequest } from 'hooks/useRequest';
import { emailVerification } from 'helper/email';
import { useTranslation } from 'react-i18next';

const EmailVerification = () => {
  const history = useRouterHistory();
  const [form] = Form.useForm();
  const email = history.location.state.email;

  const handleSubmit = (form, makeRequest) => {
    form.validateFields().then((values) => {
      makeRequest(values);
    });
  };

  const onSuccess = () => {
    history.push('/forgot/verification/newPassword', { email });
  };

  const { makeRequest } = useRequest({
    request: emailVerificationApi,
    initialData: {},
    onSuccess,
  });

  const { t } = useTranslation();

  return (
    <Row style={{ height: '100vh', marginTop: '150px' }}>
      <Form
        form={form}
        onFinish={() => handleSubmit(form, makeRequest)}
        className='login-form  centered'
      >
        <Col span={24} style={{ textAlign: 'center' }}>
          <Span level={4} className='form-title'>
            {t('forgotPassword')}
          </Span>
        </Col>
        <Col>
          <Text level={2} className='please-text'>
            {t('pleaseEnterTheVerification', {
              email: emailVerification(email),
            })}
          </Text>
        </Col>
        <Form.Item
          name='code'
          validateFirst={true}
          validateTrigger='onSubmit'
          rules={[
            {
              required: true,
              whitespace: true,
            },
          ]}
        >
          <Input placeholder={t('enterTheCode')} />
        </Form.Item>
        <Form.Item>
          <Button
            type='submit'
            htmlType='submit'
            className='submit-button'
            block
          >
            {t('save')}
          </Button>
        </Form.Item>
        <Row style={{ textAlign: 'center' }}>
          <Col span={24}>
            <Span className='have-account-text'>{t('haveAccount')}</Span>
            <Span style={{ marginLeft: '10px' }}>
              <Button
                type='link'
                htmlType='submit'
                className='no-decoration-button have-account-button'
                onClick={() => {
                  history.push('/signup');
                }}
              >
                {t('signUp').toUpperCase()}
              </Button>
            </Span>
          </Col>
        </Row>
      </Form>
    </Row>
  );
};

export default EmailVerification;
