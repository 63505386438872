// import { useEffect, useState } from 'react';
import useGetRequest from 'hooks/useGetRequest';
import styled from 'styled-components';
import { getAllAddressApi } from 'services/profile';
import { Button, Row, Col, Text, Card } from 'styled';
import Diamond from 'images/diamond.svg';
import { useRouterHistory } from 'hooks/routerHooks';

const Image = styled.img`
  display: block;
  width: 80px;
`;

const CardItem = ({ data, reFetch }) => {
  // const { makeRequest } = useRequest({
  //   request: deleteAddressApi,
  //   initialData: {},
  //   onSuccess: () => {
  //     reFetch();
  //   },
  // });

  return (
    <>
      {data?.map((e) => (
        <Col xs={24} md={12} lg={8} className='card-item' key={e.id}>
          <Card bordered={false}>
            <Row>
              <Col span={24}>{e.title}</Col>
              <Col span={24}>
                <Text type='secondary'>{e.country}</Text>
                {',  '}
                <Text type='secondary'>{e.city}</Text>
              </Col>
              <Col span={24}>
                <Text type='secondary'>{e.fullAddress}</Text>
              </Col>
              <Col sm={1} offset={19}>
                <Button type='link' className='link-button'></Button>
              </Col>
            </Row>
          </Card>
        </Col>
      ))}
    </>
  );
};

const Cards = () => {
  const history = useRouterHistory();
  // const [isEditCard, setIsEditCard] = useState(false);
  const { data: response, reFetch } = useGetRequest({
    request: getAllAddressApi,
    initialData: { data: [] },
  });

  // useEffect(() => {
  //   !isEditCard && reFetch();
  // }, [isEditCard, reFetch]);

  return (
    <Card title='Cards' className='gafri-card'>
      <Row>
        <CardItem data={response?.data} reFetch={reFetch} />
        <Col span={8} className='card-item'>
          <Card>
            <Row
              style={{
                justifyContent: 'centre',
                textAlign: 'center',
                margin: '4px 0',
              }}
            >
              <Col
                span={24}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Image src={Diamond} />
              </Col>
              <Col span={24}>
                <Button
                  type='link'
                  className='link-button'
                  onClick={() => {
                    history.push('/user/cards/addnewcard');
                  }}
                >
                  Add new card
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Card>
  );
};

export default Cards;
