import { Select } from 'antd';

const { Option } = Select;

const ChangeLanguage = () => {
  return (
    <Select
      showArrow={false}
      defaultValue={window.localStorage.getItem('i18nextLng') || 'en'}
      style={{ width: 50, padding: 0 }}
      onChange={(value) => {
        window.localStorage.setItem('tag', JSON.stringify([]));
        window.localStorage.setItem('i18nextLng', value);
        window.location.reload();
      }}
    >
      <Option value='en'>EN</Option>
      <Option value='hy'>ՀՅ</Option>
      <Option value='ru'>РУ</Option>
    </Select>
  );
};

export default ChangeLanguage;
