import React, { useEffect } from 'react';
import { Form, Input, Button, Row, Col, Text, Span } from 'styled';
import { useRouterHistory } from 'hooks/routerHooks';
import { signUpApi } from 'services/users';
import { useRequest } from 'hooks/useRequest';
import { useTranslation } from 'react-i18next';

const handleSubmit = (form, makeRequest) => {
  form.validateFields().then((values) => {
    makeRequest(values);
  });
};

const Signup = () => {
  const [form] = Form.useForm();
  const history = useRouterHistory();

  const { response, makeRequest } = useRequest({
    request: signUpApi,
    initialData: {},
  });
  useEffect(() => {
    const email = form.getFieldValue('email');
    if (response?.data && email) {
      history.push('/signup/verification', { email, id: response?.data });
    }
  }, [response, form, history]);

  const { t } = useTranslation();

  return (
    <Row style={{ height: '100vh', marginTop: '150px' }}>
      <Form
        form={form}
        onFinish={() => {
          handleSubmit(form, makeRequest);
        }}
        className='login-form centered'
      >
        <Col span={24} style={{ textAlign: 'center' }}>
          <Span level={4} className='form-title'>
            {t('signUp')}
          </Span>
        </Col>
        <Col>
          <Text level={2} className='please-text'>
            {t('pleaseEnterYourPhoneNumber')}
          </Text>
        </Col>
        <Form.Item
          name='firstName'
          validateFirst={true}
          validateTrigger='onSubmit'
          rules={[
            {
              required: true,
              whitespace: true,
            },
          ]}
        >
          <Input placeholder={t('enterFirstName')} />
        </Form.Item>
        <Form.Item
          name='lastName'
          validateFirst={true}
          validateTrigger='onSubmit'
          rules={[
            {
              required: true,
              whitespace: true,
            },
          ]}
        >
          <Input placeholder={t('enterLastName')} />
        </Form.Item>
        <Form.Item
          name='email'
          validateFirst={true}
          validateTrigger='onSubmit'
          rules={[
            {
              required: true,
              message: 'Please input the Email',
              whitespace: true,
            },
            {
              type: 'email',
              message: 'Please input a valid Email',
            },
          ]}
        >
          <Input placeholder={t('enterEmailAddress')} />
        </Form.Item>
        <Form.Item>
          <Button
            type='submit'
            className='submit-button'
            htmlType='submit'
            block
          >
            {t('recieveCode').toUpperCase()}
          </Button>
        </Form.Item>
        <Row style={{ textAlign: 'center' }}>
          <Col span={24}>
            <Span className='have-account-text'>{t('haveAccount')}</Span>
            <Span style={{ marginLeft: '10px' }}>
              <Button
                type='link'
                htmlType='submit'
                className='no-decoration-button have-account-button'
                onClick={() => {
                  history.push('/signin');
                }}
              >
                {t('signIn')}
              </Button>
            </Span>
          </Col>
        </Row>
      </Form>
    </Row>
  );
};

export default Signup;
