import client from './client';

export const productBestSellersGet = () =>
  client().get('/Product/GetBestsellers');

export const productPromotionGet = () =>
  client().get('/Product/GetWithPromotion');

export const productFilterGet = () => client().get('/Product/GetFilter');

export const productListGet = (data) => client().post('/Product/GetList', data);

export const createOrderApi = (data) => client().post('/Order/Create ', data);
