import styled from 'styled-components';
import { Col as col } from 'antd';

const Col = styled(col)`
  &.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  @media screen and (max-width: 992px) {
    &.mobile-color {
      background-color: #f4ede7;
    }
  }
`;

export default Col;
